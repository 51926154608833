import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import TinderChat from "./TinderChat";
import { stickers } from "../stickers";
import Lottie from "react-lottie-player";
import pulse from "../../assets/snowman.json";

const RANDOM_HELLOS = [
  "Вашей компании сотрудник не нужен?",
  "Это мэтч, коллега!",
  "Нет времени объяснять, давайте делать коллабу!",
  "А что говорить?... Ну, привет 👋🏻",
  "Коллега, какой же вы крутой! ",
  "Раз нас свела конференция — это точно судьба 🤟🏻",
  "Мне нужен новый коллега в команду. Возможно, прям такой, как ты.",
  "Конфа отлёт, коллега!",
  "Знакомое красивое лицо, коллега 😉",
  "Привет, коллега! Как здорово, что наши интересы совпали.",
  "Хэй, коллега! Подпишись на меня с разбега",
];

export default function TinderInbox({ userInfo, tinderChats }) {
  const [height, setHeight] = useState(100);
  const [chat, setChat] = useState(null);
  const [hello, setHello] = useState(RANDOM_HELLOS[0]);
  const [filter, setFilter] = useState("all");

  useEffect(() => {
    const currentChat = tinderChats.filter((c) => c.id === chat)[0];
    if (chat && !currentChat.messages.length) {
      const randomHello =
        RANDOM_HELLOS[Math.floor(Math.random() * RANDOM_HELLOS.length)];
      setHello(randomHello);
    } else {
      setHello("");
    }
  }, [chat, tinderChats]);

  useEffect(() => {
    setHeight(document.getElementById("player").clientHeight);
  }, []);

  if (!userInfo) {
    return null;
  }

  if (!userInfo.needTinder) {
    return (
      <div
        style={{ minHeight: "24rem", maxHeight: `${height}px` }}
        className="container mx-auto px-6 pb-12 md:pb-20 relative"
      >
        <div className="relative w-full flex flex-col items-center mt-6">
          <Lottie
            loop
            animationData={pulse}
            play
            className="w-3/4 md:w-1/2 -mt-10"
          />

          <h3 className="text-xl text-center font-medium mb-4">
            Нетворкинг здесь!
          </h3>
          <p className="text-sm text-center mx-6">
            Заполни анкету в профиле и получи доступ к тысячам контактов КОЛЛЕГ.
          </p>

          <NavLink
            to="/profile"
            className="bg-indigo-600 uppercase tracking-wider font-medium text-white rounded-lg p-3 px-5 text-center mt-6"
          >
            Заполнить профиль
          </NavLink>
        </div>
      </div>
    );
  }

  if (!tinderChats.length) {
    return (
      <div
        style={{ minHeight: "24rem", maxHeight: `${height}px` }}
        className="container mx-auto px-6 pb-12 md:pb-20 relative"
      >
        <h2 className="uppercase text-xs text-blue-500 text-center font-medium w-full my-4 tracking-wider">
          Бизнес-тиндер
        </h2>

        <div className="absolute w-full p-4 flex flex-col items-center -mx-6 top-1/2 -mt-28">
          <h3 className="text-xl text-center font-medium mb-4">
            Пока у вас нет мэтчей
          </h3>
          <p className="text-sm text-center mx-6">
            Переписываться можно только с теми, с кем совпадет лайк профиля.
            Возможно, стоит посвайпать анкеты других участников?
          </p>

          <NavLink
            to="/tinder"
            className="bg-indigo-600 uppercase tracking-wider font-medium text-white rounded-lg p-3 px-5 text-center mt-6"
          >
            Смотреть анкеты
          </NavLink>
        </div>
      </div>
    );
  }

  if (chat) {
    const currentChat = tinderChats.filter((c) => c.id === chat)[0];
    return (
      <TinderChat
        chat={currentChat}
        userInfo={userInfo}
        messagesData={currentChat.messages || []}
        onClose={() => setChat(null)}
        randomHello={hello}
      />
    );
  }

  const favourites = JSON.parse(localStorage.getItem("favourites")) || [];

  const newCount = tinderChats.filter(
    (c) => !c.readed && c.lastMessageFrom !== userInfo.uid
  ).length;

  let filteredChats = tinderChats;
  if (filter === "new")
    filteredChats = tinderChats.filter(
      (c) => !c.readed && c.lastMessageFrom !== userInfo.uid
    );
  if (filter === "starred")
    filteredChats = tinderChats.filter((c) => favourites.includes(c.id));

  return (
    <div
      style={{ minHeight: "340px", maxHeight: `${height}px` }}
      className="flex flex-col w-full p-4 pt-12 overflow-auto relative"
    >
      <div className="h-10 absolute flex w-full top-0 left-0 bg-white z-10 border-b justify-around items-center">
        <button
          onClick={() => setFilter("all")}
          className={`${
            filter === "all" ? "text-blue-500" : ""
          } text-xs uppercase font-medium ml-4 mr-2 flex items-center`}
        >
          Все
          <span className="text-xs text-black rounded-full px-2 h-4 flex items-center justify-center bg-gray-200 ml-2">
            {tinderChats.length}
          </span>
        </button>
        <button
          onClick={() => setFilter("new")}
          className={`${
            filter === "new" ? "text-blue-500" : ""
          } text-xs uppercase font-medium flex items-center`}
        >
          Новое
          {!!newCount ? (
            <span className="text-white rounded-full px-2 h-4 flex items-center justify-center bg-indigo-600 ml-2">
              {newCount}
            </span>
          ) : (
            <span className="text-xs rounded-full px-2 h-4 flex items-center justify-center bg-gray-200 ml-2">
              0
            </span>
          )}
        </button>
        <button
          onClick={() => setFilter("starred")}
          className={`${
            filter === "starred" ? "text-blue-500" : ""
          } text-xs uppercase font-medium ml-2 mr-4 flex items-center`}
        >
          Избранное
          <span className="text-xs text-black rounded-full px-2 h-4 flex items-center justify-center bg-gray-200 ml-2">
            {favourites.length}
          </span>
        </button>
      </div>
      {filteredChats
        .sort(
          (a, b) =>
            a.readed - b.readed ||
            b.lastMessageAt.seconds - a.lastMessageAt.seconds
        )
        .map((chat) => {
          const who =
            chat.first.uid === userInfo.uid ? chat.second : chat.first;
          return (
            <button
              onClick={() => setChat(chat.id)}
              key={chat.id}
              className="w-full bg-gray-100 p-3 mb-2 rounded-lg flex items-center relative justify-start text-left"
            >
              {!chat.readed && chat.lastMessageFrom !== userInfo.uid && (
                <div className="w-2 h-2 bg-indigo-600 right-2 top-2 rounded-full absolute">
                  <div className="w-2 h-2 bg-indigo-600 rounded-full absolute animate-ping"></div>
                </div>
              )}
              <div
                className="w-8 h-8 rounded-full bg-cover flex-shrink-0"
                style={{ backgroundImage: `url(${who.photo})` }}
              ></div>
              <div
                style={{ width: "calc(100% - 3rem)" }}
                className="ml-2 flex flex-col"
              >
                <p className="text-xs font-medium">{who.name}</p>
                <p
                  className={`text-xs truncate ${
                    !chat.messages.length || stickers.includes(chat.lastMessage)
                      ? "italic text-indigo-500"
                      : ""
                  }`}
                >
                  {chat.lastFromMe === userInfo.uid && <b>Вы: </b>}
                  {!chat.messages.length
                    ? "У вас мэтч! Начните общение"
                    : `${
                        stickers.includes(chat.lastMessage)
                          ? "Стикер"
                          : chat.lastMessage
                      }`}
                </p>
              </div>
            </button>
          );
        })}
    </div>
  );
}
