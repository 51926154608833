const stickers = [
  ":mfire:",
  ":mcross:",
  ":mcry:",
  ":mlove:",
  ":mrock:",
  ":mhmm:",
  ":mwow:",
  ":mhello:",
  ":mthumbsup:",
  ":mstay:",

  ":cute:",
  ":fire:",
  ":power:",
  ":cry:",
  ":eyeslove:",
  ":spiderman:",
  ":hmm:",
  ":wow:",
  ":glasses:",
  ":meh:",
  ":star:",
  ":laugh:",
  ":devil:",
  ":love:",
  ":shit:",
  ":blacklove:",
];
const plainStickers = stickers.map((s) => s.replace(/[^\w]|_/g, ""));
const premiumStickers = [
  ":mfire:",
  ":mcross:",
  ":mcry:",
  ":mlove:",
  ":mrock:",
  ":mhmm:",
  ":mwow:",
  ":mhello:",
  ":mthumbsup:",
  ":mstay:",
];

const getStickerByRole = (role) => {
  return plainStickers.slice(10, 26);
  switch (role) {
    case 3:
      return plainStickers;
    case 2:
      return plainStickers;
    default:
      return plainStickers.slice(10, 26);
  }
};

export { stickers, getStickerByRole, plainStickers, premiumStickers };
