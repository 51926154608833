import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Navbar from "../Navbar/Navbar";
import Profile from "../Profile/Profile";
import Control from "../Control/Control";
import Live from "../Live/Live";
import { Redirect } from "react-router-dom";
import { useEffect, useState } from "react";
import firebase from "firebase";

export default function Pitcha({ userInfo, redirect, needReload, room }) {
  const [tinderChats, setTinderChats] = useState([]);

  useEffect(() => {
    let subscriber;
    if (userInfo && userInfo.uid) {
      subscriber = firebase
        .firestore()
        .collection("tinder-chats")
        .where("users", "array-contains", userInfo.uid)
        .where("room", "==", room)
        .onSnapshot((snapshot) => {
          if (snapshot) {
            const newTinderChats = [];
            snapshot.forEach((doc) => {
              const data = doc.data();
              newTinderChats.push({
                ...data,
                id: doc.id,
                messages: data.messages ? data.messages.reverse() : [],
              });
            });
            setTinderChats(newTinderChats);
          }
        });
    }

    return () => {
      if (typeof subscriber === "function") {
        subscriber();
      }
    };
  }, [room, userInfo, userInfo.uid]);

  if (!userInfo.phone || !userInfo.ticket || !userInfo.name) {
    return (
      <Router>
        <Profile userInfo={userInfo} onChange={needReload} />
      </Router>
    );
  }

  return (
    <Router>
      {redirect && <Redirect to={redirect} />}
      <Switch>
        <Route path="/obs1">
          <Live userInfo={userInfo} room={room} subroom={"room1"} obs />
        </Route>
        <Route path="/obs2">
          <Live userInfo={userInfo} room={room} subroom={"room2"} obs />
        </Route>
        <Route path="/live">
          <Navbar tinderChats={tinderChats} userInfo={userInfo} room={room} />
          <Live userInfo={userInfo} room={room} subroom={"room1"} />
        </Route>
        <Route path="/tinder">
          <Navbar tinderChats={tinderChats} userInfo={userInfo} room={room} />
          <Live userInfo={userInfo} room={room} subroom={"tinder"} />
        </Route>
        <Route path="/chat">
          <Navbar tinderChats={tinderChats} userInfo={userInfo} room={room} />
          <Live
            tinderChats={tinderChats}
            userInfo={userInfo}
            room={room}
            subroom={"inbox"}
          />
        </Route>
        <Route path="/program">
          <Navbar tinderChats={tinderChats} userInfo={userInfo} room={room} />
          <Live userInfo={userInfo} room={room} subroom={"program"} />
        </Route>
        <Route path="/profile">
          <Profile userInfo={userInfo} onChange={needReload} />
        </Route>
        <Route path="/admin">
          <Control room={room} userInfo={userInfo} />
        </Route>
        <Route path="/">
          <Navbar tinderChats={tinderChats} userInfo={userInfo} room={room} />
          <Live userInfo={userInfo} room={room} subroom={"room1"} />
        </Route>
      </Switch>
    </Router>
  );
}
