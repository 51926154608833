import Compress from "react-image-file-resizer";
import React, { useState } from "react";
import { ArrowLeft, Check, Edit2, LogOut } from "react-feather";
import { NavLink } from "react-router-dom";
import firebase from "firebase";
import { nanoid } from "nanoid";

export default function Profile({ userInfo, onChange }) {
  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const [facebook, setFacebook] = useState(userInfo.facebook || "");
  const [instagram, setInstagram] = useState(userInfo.instagram || "");

  const [needTinder, setNeedTinder] = useState(userInfo.needTinder || false);
  const [role, setRole] = useState(userInfo.role || "basic");
  const [about, setAbout] = useState(userInfo.about || "");
  const [username, setUsername] = useState(userInfo.name || "");
  const [ticket, setTicket] = useState(userInfo.ticket || "");
  const [phone, setPhone] = useState(userInfo.phone || "");
  const [image, setImage] = useState(
    userInfo.photo || `/images/avatars/1_${getRandomInt(1, 65)}.jpg`
  );

  const [imageFile, setImageFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [interests, setInterests] = useState(userInfo.interests || []);
  const [exp, setExp] = useState(userInfo.exp || []);
  const [power, setPower] = useState(userInfo.power || []);

  const onImageChange = (e) => {
    const file = e.target.files[0];

    // Compress file on client side
    Compress.imageFileResizer(
      file,
      120,
      120,
      "PNG",
      90,
      0,
      (uri) => {
        setImageFile(uri);
      },
      "blob"
    );

    // Update visual file
    let reader = new FileReader();
    reader.onload = (event) => {
      setImage(event.target.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const submitForm = () => {
    setLoading(true);
    if (imageFile) {
      const storageRef = firebase
        .storage()
        .ref(`avatars/${userInfo.uid}/ava.png`);
      const uploadTask = storageRef.put(imageFile);
      uploadTask.on("state_changed", null, console.error, () => {
        storageRef.getDownloadURL().then((url) => {
          setImage(url);
          saveUser(url);
        });
      });
    } else {
      saveUser();
    }
  };

  const saveUser = (url = null) => {
    setError(null);
    const db = firebase.firestore();

    let photo = url || image;

    let loginControlId = nanoid(10);

    db.collection("users")
      .doc(userInfo.uid)
      .set(
        {
          room: "PITCHA-DEMO",
          updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
          whiteId: ticket.toLowerCase(),
          name: username,
          loginControlId,
          role,
          about,
          ticket,
          photo,
          phone,
          needTinder,
          instagram: instagram === "@" ? "" : instagram,
          facebook,
          interests,
          power,
          exp,
        },
        { merge: true }
      )
      .then(() => {
        firebase
          .database()
          .ref(`/loginControl/PITCHA-DEMO/${ticket.toLowerCase()}`)
          .set(loginControlId);

        firebase.analytics().logEvent("profile", {
          tinder: needTinder,
        });

        if (needTinder) {
          createTinderUser(photo, (status) => {
            if (status) {
              console.log("saved tinder user");
            } else {
              console.error("error saved tinder user");
            }
            setLoading(false);
            onChange();
          });
        } else {
          setLoading(false);
          onChange();
        }
      })
      .catch((error) => {
        setLoading(false);
        setError("Ошибка при регистрации пользователя");
        console.error(error);
      });
  };

  const createTinderUser = (photo, onFinish) => {
    const db = firebase.firestore();
    db.collection("tinder-users")
      .doc(userInfo.uid)
      .set(
        {
          uid: userInfo.uid,
          room: "PITCHA-DEMO",
          updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
          whiteId: ticket.toLowerCase(),
          name: username,
          about,
          role,
          ticket,
          photo,
          phone,
          needTinder,
          instagram: instagram === "@" ? "" : instagram,
          facebook,
          interests,
          power,
          exp,
        },
        { merge: true }
      )
      .then(() => {
        firebase.analytics().logEvent("save_tinder", {
          instagram: instagram.length > 2,
          facebook: facebook.length > 2,
          about: about.length > 10,
        });
        onFinish(true);
      })
      .catch((error) => {
        onFinish(false);
        console.error("save tinder", error);
      });
  };

  const isPhoneValid = phone.length > 10;
  const formIsValid = username.length && isPhoneValid && !loading;

  const tapTag = (key, value) => {
    switch (key) {
      case "interests":
        if (interests.includes(value)) {
          setInterests(interests.filter((w) => w !== value));
        } else {
          setInterests([...interests, value]);
        }
        break;
      case "exp":
        if (exp.includes(value)) {
          setExp(exp.filter((w) => w !== value));
        } else {
          setExp([...exp, value]);
        }
        break;
      case "power":
        if (power.includes(value)) {
          setPower(power.filter((w) => w !== value));
        } else {
          setPower([...power, value]);
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className="container mx-auto px-6 max-w-md pt-20">
      <div className="relative">
        <div className="form flex flex-col gap-4 bg-white rounded-md p-6 relative shadow-xl">
          {userInfo.phone && (
            <NavLink
              to="/live"
              className="text-primary absolute btn -mt-8 left-2 top-0"
            >
              <ArrowLeft size={18} />
            </NavLink>
          )}
          <button
            className="text-primary absolute btn -mt-8 right-2 top-0"
            onClick={() => firebase.auth().signOut()}
          >
            <LogOut size={18} />
          </button>
          <label
            className="photo bg-black rounded-full w-20 h-20 -mt-16 mx-auto border-2 border-white bg-cover relative cursor-pointer"
            style={{
              backgroundImage: `url(${image})`,
            }}
          >
            <input
              type="file"
              onChange={onImageChange}
              className="hidden"
              accept="image/*"
            />
            <div className="absolute w-5 h-5 rounded-full ring-2 ring-white bg-indigo-600 flex items-center justify-center right-0 bottom-0 pointer-events-nones">
              <Edit2 className="w-3 h-3 text-black" />
            </div>
          </label>

          <p className="text-center text-xs mb-2 text-gray-400">
            Кликните на аватарку,
            <br />
            чтобы загрузить свое фото
          </p>

          <div className="relative w-full">
            <input
              value={username}
              name="name"
              onChange={(e) => setUsername(e.target.value)}
              type="text"
              className="bg-gray-100 p-3 pt-6 rounded-md w-full"
            />
            <label className="left-3 top-2 pointer-events-none absolute text-xs text-gray-600">
              Введите имя
            </label>
          </div>

          <div className="relative w-full">
            <input
              type="text"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value.replace(/\D/g, ""))}
              className="bg-gray-100 p-3 pt-6 rounded-md w-full"
            />
            <label className="left-3 top-2 pointer-events-none absolute text-xs text-gray-600">
              Введите телефон
            </label>
          </div>

          <div className="relative w-full">
            <input
              value={ticket}
              name="ticket"
              onChange={(e) =>
                setTicket(
                  e.target.value
                    .toUpperCase()
                    .replace(/[^A-Z0-9]+/g, "")
                    .slice(0, 10)
                )
              }
              type="text"
              className="bg-gray-100 p-3 pt-6 rounded-md w-full"
            />
            <label className="left-3 top-2 pointer-events-none absolute text-xs text-gray-600">
              Введите номер билета
            </label>
          </div>

          <div className="relative w-full">
            <input
              value={instagram}
              name="instagram"
              onChange={(e) =>
                setInstagram(
                  "@" +
                    e.target.value
                      .replace("https://www.", "")
                      .replace("https://", "")
                      .replace("instagram.com", "")
                      .replace(/[^a-zA-Z0-9._]/g, "")
                      .toLowerCase()
                )
              }
              type="text"
              className="bg-gray-100 p-3 pt-6 rounded-md w-full"
            />
            <label className="left-3 top-2 pointer-events-none absolute text-xs text-gray-600">
              Ваш аккаунт в Instagram
            </label>
          </div>

          <div className="relative w-full">
            <input
              value={facebook}
              name="facebook"
              onChange={(e) =>
                setFacebook(
                  e.target.value
                    .replace("https://www.", "")
                    .replace("https://", "")
                    .toLowerCase()
                )
              }
              type="text"
              className="bg-gray-100 p-3 pt-6 rounded-md w-full"
            />
            <label className="left-3 top-2 pointer-events-none absolute text-xs text-gray-600">
              Ссылка на ваш Facebook
            </label>
          </div>

          <div className="relative w-full">
            <button
              className="flex items-center"
              onClick={() => setNeedTinder(!needTinder)}
            >
              <div className="w-4 h-4 bg-gray-100 mr-2 flex-shrink-0 rounded-md p-0.5 flex items-center justify-center">
                {needTinder && <Check className="w-full text-primary" />}
              </div>
              <p className="text-xs">Хочу участвовать в Бизнес-тиндере</p>
            </button>
          </div>

          {needTinder && (
            <>
              <div className="flex flex-col mb-2 mt-2">
                <label className="ml-1 text-lg font-semibold mb-1 text-gray-600">
                  Расскажите немного о себе
                </label>
                <textarea
                  name="about"
                  placeholder="Хобби, увлечения, домашние животные?"
                  className="w-full h-20 px-3 py-2 text-base border rounded-lg"
                  value={about}
                  onChange={(e) => setAbout(e.target.value.slice(0, 140))}
                />
                <p className="text-xs mt-1 text-gray-400">{about.length}/140</p>
              </div>
              <div className="flex flex-col mt-3 mb-4">
                <label className="ml-1 text-lg font-semibold mb-1 text-gray-600">
                  Чем тут интересуетесь?
                </label>
                <div className="flex flex-wrap mt-2">
                  {[
                    "Хочу новую работу",
                    "Ищу коллегу в команду",
                    "Нужно перетереть идеи",
                    "Го делать спецпроекты",
                    "Давайте коллабиться",
                    "Копаю опыт других коллег",
                    "Нужны бизнес-партнеры",
                  ].map((e) => {
                    const btnClasses = [
                      "rounded-full px-3 py-1 bg-gray-100 mr-2 mb-2 text-xs",
                    ];
                    if (interests.includes(e))
                      btnClasses.push("bg-primary text-white");
                    return (
                      <button
                        key={e}
                        onClick={() => tapTag("interests", e)}
                        className={btnClasses.join(" ")}
                      >
                        {e}
                      </button>
                    );
                  })}
                </div>
              </div>

              <div className="flex flex-col mb-4">
                <label className="ml-1 text-lg font-semibold mb-1 text-gray-600">
                  А много опыта в запасе?
                </label>
                <div className="flex flex-wrap mt-2">
                  {[
                    "Видел в этой жизни всё",
                    "Запускал 100500 проектов",
                    "Есть маленькое портфолио",
                    "Ещё падаван, но быстро учусь",
                    "Опыт-попыт",
                  ].map((e) => {
                    const btnClasses = [
                      "rounded-full px-3 py-1 bg-gray-100 mr-2 mb-2 text-xs",
                    ];
                    if (exp.includes(e))
                      btnClasses.push("bg-primary text-white");
                    return (
                      <button
                        key={e}
                        onClick={() => tapTag("exp", e)}
                        className={btnClasses.join(" ")}
                      >
                        {e}
                      </button>
                    );
                  })}
                </div>
              </div>

              <div className="flex flex-col mb-2">
                <label className="ml-1 text-lg font-semibold mb-1 text-gray-600">
                  Твоя суперсила, коллега?
                </label>
                <div className="flex flex-wrap mt-2">
                  {[
                    "генерирую идеи со скоростью Флэша",
                    "пишу бомбические тексты",
                    "собираю фирменные стили",
                    "гуру спецпроектов и коллаб",
                    "чёрный пояс по коммуникациям",
                    "умею в пиар, за который не стыдно",
                    "делаю сайты по UX, UI и фэн-шую",
                    "шарю за деньги",
                  ].map((e) => {
                    const btnClasses = [
                      "rounded-full px-3 py-1 bg-gray-100 mr-2 mb-2 text-xs",
                    ];
                    if (power.includes(e))
                      btnClasses.push("bg-primary text-white");
                    return (
                      <button
                        key={e}
                        onClick={() => tapTag("power", e)}
                        className={btnClasses.join(" ")}
                      >
                        {e}
                      </button>
                    );
                  })}
                </div>
              </div>
            </>
          )}

          {error && (
            <p className="relative w-full p-4 text-center text-sm bg-red-500 rounded-md text-white">
              {error}
            </p>
          )}

          <button
            onClick={submitForm}
            disabled={!formIsValid}
            className={
              !formIsValid
                ? "text-alternate bg-primary uppercase tracking-wider font-bold text-white w-full rounded-md p-3 opacity-50 pointer-events-none"
                : "text-alternate bg-primary uppercase tracking-wider font-bold text-white w-full rounded-md p-3"
            }
          >
            {userInfo.phone ? "Сохранить" : "Войти"}
          </button>
          <p className="text-xs text-center text-gray-400 w-full">
            Нажимая кнопку «{userInfo.phone ? "Сохранить" : "Войти"}» Вы
            соглашаетесь с{` `}
            <a
              href="https://pitcha.ru"
              target="_blank"
              rel="noreferrer"
              className="text-indigo-600"
            >
              политикой обработки ПД
            </a>
          </p>
        </div>
        <a
          className="text-sm mt-4 text-black font-medium flex justify-center mb-10"
          href="//t.me/kiwibudka"
          target="_blank"
          rel="noreferrer"
        >
          Служба поддержки
        </a>
      </div>
    </div>
  );
}
