import React, { useEffect, useState } from "react";
import logo from "../../assets/pitcha-demo/logo.svg";

export default function Timetable({ data, obs }) {
  const [now, setNow] = useState([]);
  const [hideCurrent, setHideCurrent] = useState(false);

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(now)) {
      setHideCurrent(true);
      setTimeout(() => {
        setNow(data);
        setHideCurrent(false);
      }, 500);
    }
  }, [data, now]);

  const currentPlan = now ? now.find((t) => t.active) : {};
  const currentIndex = now ? now.indexOf(currentPlan) : -1;
  return (
    <>
      <div
        className={`${hideCurrent ? "hide-timetable" : "show-timetable"} ${
          currentPlan && currentPlan.type ? currentPlan.type : ""
        } w-full flex flex-col items-center h-48 overflow-hidden relative -mt-8 pt-8`}
      >
        {(!currentPlan || (currentPlan && currentPlan.type === "blank")) && (
          <div className="w-full h-full flex items-center justify-center relative">
            <img
              src={logo}
              className="w-24 md:w-32 logo mt-8 md:mt-0"
              alt="logo"
            />
          </div>
        )}
        {currentPlan && currentPlan.type === "simple" && (
          <div className="w-full h-full flex flex-col items-center justify-center relative">
            <img
              src={logo}
              className="w-24 md:w-32 logo mt-8 md:mt-0"
              alt="logo"
            />
            <p className="logo-title text-alternate text-indigo-600 font-semibold text-sm mt-4">
              {currentPlan.title}
            </p>
          </div>
        )}
        {currentPlan && currentPlan.type === "speaker" && (
          <div className="w-full h-full relative flex flex-col mt-4">
            <div className="flex w-full">
              <div
                className="photo bg-black rounded-full w-10 h-10 bg-cover border-2 border-indigo-600 flex-shrink-0"
                style={{
                  backgroundImage: `url(${currentPlan.image})`,
                }}
              />
              <div className="flex flex-col w-full ml-2 mr-16 justify-center">
                <p className="soon text-xs uppercase text-indigo-600 font-medium mb-0.5">
                  СЕЙЧАС
                </p>
                <p className="name text text-white font-medium leading-none">
                  {currentPlan.title}
                </p>
              </div>
            </div>
            <p className="title text-white text-xs mt-3 text-alternate md:text-base">
              {currentPlan.text}
            </p>
            <div className="speakers flex w-full mt-auto mb-3 border-t border-blue-600 pt-2">
              {now.map((t, index) => {
                if (t.type !== "speaker") {
                  return null;
                }
                return (
                  <div
                    key={index}
                    className={`relative speaker-item ml-1 -mr-2 w-6 h-6 bg-cover flex-shrink-0 rounded-full ${
                      currentIndex === index
                        ? "ring-2 ring-indigo-600 z-10"
                        : "border-gray-200 border"
                    }`}
                    style={{
                      backgroundImage: `url(${t.image})`,
                    }}
                  >
                    {currentIndex > index && (
                      <div className="absolute w-full h-full bg-gray-900 opacity-70 rounded-full transform scale-105"></div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
