import React, { useState, useEffect } from "react";
import firebase from "firebase";
import Cloud from "./Cloud";

export default function WordsCloud({ userInfo, data, pollsData, obs, room }) {
  const [myAnswer, setMyAnswer] = useState("");
  const [sended, setSended] = useState(false);
  const [loading, setLoading] = useState(false);
  const [answers, setAnswers] = useState({});

  const db = firebase.firestore();

  useEffect(() => {
    setSended(false);
    setMyAnswer("");
    setLoading(false);
    setAnswers({});
  }, [data.id]);

  useEffect(() => {
    setAnswers(pollsData);
  }, [pollsData]);

  const sendAnswer = () => {
    setLoading(true);
    db.collection("words-clouds")
      .doc(`${userInfo.uid}-${data.id}`)
      .set({
        room,
        question: parseInt(data.id),
        answer: myAnswer.toLowerCase().trim(),
        uid: userInfo.uid,
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
      })
      .then((docRef) => {
        setSended(true);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error adding answer: ", error);
        setSended(true);
        setLoading(false);
      });
    firebase.analytics().logEvent("words-cloud-answer");
  };

  const words = Object.keys(answers)
    .filter((c) => c !== "clear")
    .map((word) => {
      return {
        text: word.toUpperCase(),
        value: answers[word],
      };
    });

  if (obs) {
    return (
      <div className="w-full h-screen flex flex-col items-center justify-center p-6 text-black">
        <h1 className="font-bold text-5xl w-full font-bold mb-5 border-b border-gray-800 pb-5">
          {data.text}
        </h1>
        <div className="flex w-full h-full bg-prosto">
          <Cloud
            obs={obs}
            data={words.length > 50 ? words.slice(0, 100) : words}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="h-full flex flex-col items-center justify-center p-6">
      <div className="flex w-full h-full bg-gray-100 mb-4">
        <Cloud
          id={data.id}
          data={words.length > 50 ? words.slice(0, 100) : words}
        />
      </div>

      <h1 className="text-prosto text-xl font-bold text-center w-full">
        {data.text}
      </h1>
      <div className="flex flex-col gap-2 mt-4 w-full">
        <input
          type="text"
          placeholder="Введите свой ответ"
          value={myAnswer}
          className="border p-3 text-sm w-full text-center rounded-lg"
          onChange={(e) =>
            setMyAnswer(
              e.target.value.replace(/[^\wа-яА-Я-\s]|_/g, "").slice(0, 20)
            )
          }
        />
        {data.step === "start" && (
          <button
            onClick={sendAnswer}
            disabled={!myAnswer.length || loading || sended}
            className={`${
              !myAnswer.length || loading || sended
                ? "pointer-events-none opacity-50"
                : ""
            } text-white w-full text-center p-3 text-sm uppercase tracking-wider font-bold rounded-lg bg-indigo-600`}
          >
            {loading && "Отправляем ответ..."}
            {sended && "Ответ принят"}
            {!loading && !sended && "Подтвердить ответ"}
          </button>
        )}
      </div>
    </div>
  );
}
