import React, { useState, useEffect, useRef } from "react";
import { MessageCircle, Smile, ArrowLeft, Star } from "react-feather";
import UserCard from "../UserCard/UserCard";
import firebase from "firebase";
import { getStickerByRole, stickers } from "../stickers";
import "../Live/Widgets/Chat.scss";

const isTouchDevice =
  "ontouchstart" in window ||
  navigator.maxTouchPoints > 0 ||
  navigator.msMaxTouchPoints > 0;

export default function TinderChat({
  userInfo,
  messagesData,
  onClose,
  randomHello,
  chat,
}) {
  const [height, setHeight] = useState(100);
  const [message, setMessage] = useState("");
  const [stickersShowed, showStickers] = useState(false);
  const [openedUser, setOpenedUser] = useState(null);
  const [fav, clickFav] = useState(0);

  const scroll = useRef(null);
  const input = useRef(null);

  useEffect(() => {
    // set readed
    if (
      chat.lastMessageFrom !== userInfo.uid &&
      chat.lastMessageFrom !== "system"
    ) {
      firebase.firestore().collection("tinder-chats").doc(chat.id).set(
        {
          readed: true,
        },
        { merge: true }
      );
    }

    setHeight(document.getElementById("player").clientHeight);
  }, [chat.id, chat.lastMessageFrom, userInfo.uid]);

  useEffect(() => {
    scroll.current.scrollTo({
      top: scroll.current.scrollHeight,
      behavior: "smooth",
    });
  }, [chat.messages]);

  const sendMessage = (m = null) => {
    setMessage("");
    showStickers(false);
    const messageToSend = {
      m: m ? m : message.trim(),
      id: userInfo.uid,
      cAt: firebase.firestore.Timestamp.fromDate(new Date()),
      n: userInfo.name,
      p: userInfo.photo,
      l: userInfo.instagram,
    };

    firebase
      .firestore()
      .collection("tinder-chats")
      .doc(chat.id)
      .set(
        {
          readed: false,
          lastMessageAt: firebase.firestore.Timestamp.fromDate(new Date()),
          lastMessage: messageToSend.m,
          lastMessageFrom: userInfo.uid,
          messages: chat.messages.length
            ? firebase.firestore.FieldValue.arrayUnion(messageToSend)
            : [messageToSend],
        },
        { merge: true }
      );

    firebase.analytics().logEvent("tinder-chat-message", {
      sticker: m.indexOf(":") === 0,
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !!message.trim().length) {
      sendMessage();
      setMessage("");
      if (isTouchDevice) {
        input.current.blur();
      }
    }
  };

  const getMessagesRows = (messages) => {
    return messages.map((m, key) => {
      const classes = ["chat-message", "text-sm", "p-2", "mt-2", "relative"];

      if (!stickers.includes(m.m)) classes.push("bg-gray-100");

      if (m.id === userInfo.uid) {
        classes.push("mine mr-10 ml-auto rounded-lg rounded-br-none");
      } else {
        classes.push("ml-10 mr-auto rounded-lg rounded-bl-none");
      }
      return (
        <div key={`${chat.id}+${key}`} className={classes.join(" ")}>
          <div className="author text-xs font-medium text-blue-500 mb-1">
            {/* TODO: fix m.id to m.a like in a regular chat */}
            {m.l !== "" ? (
              <button
                onClick={() => setOpenedUser(m.id)}
                className="photo w-8 h-8 absolute rounded-full"
              >
                <div
                  className="absolute w-10 h-10 -left-1 -top-1 rounded-full"
                  style={{
                    background:
                      "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)",
                  }}
                ></div>
                <div
                  className="absolute w-8 h-8 left-0 top-0 rounded-full bg-cover ring-2 ring-white"
                  style={{ backgroundImage: `url(${m.p})` }}
                />
              </button>
            ) : (
              <button
                onClick={() => setOpenedUser(m.id)}
                className="photo w-8 h-8 absolute rounded-full"
              >
                <div
                  className="absolute w-8 h-8 left-0 top-0 rounded-full bg-cover ring-2 ring-white"
                  style={{ backgroundImage: `url(${m.p})` }}
                />
              </button>
            )}

            {!stickers.includes(m.m) && <p className="m-0">{m.n}</p>}
          </div>
          {stickers.includes(m.m) ? (
            <img
              className={`${m.h ? "opacity-50" : ""} w-20 h-20`}
              src={`/images/stickers_v2/${m.m.slice(1, m.m.length - 1)}.png`}
              alt={m.m}
            />
          ) : (
            <p className="text m-0">{m.m}</p>
          )}
        </div>
      );
    });
  };

  const favourites = JSON.parse(localStorage.getItem("favourites")) || [];
  const inFavourite = favourites.includes(chat.id);
  const who = chat.first.uid === userInfo.uid ? chat.second : chat.first;

  return (
    <div
      style={{ minHeight: "340px", maxHeight: `${height}px` }}
      className="flex flex-col justify-end h-full min-h-32 relative"
    >
      {openedUser && (
        <UserCard
          uid={openedUser}
          userInfo={userInfo}
          onClose={() => setOpenedUser(null)}
        />
      )}

      <div className="absolute w-full left-0 top-0 h-14 bg-white z-10 border-b flex p-2 items-center">
        <button
          className="w-8 h-8 flex items-center justify-center flex-shrink-0 -ml-2"
          onClick={onClose}
        >
          <ArrowLeft size={16} />
        </button>
        <div
          className="photo rounded-full w-8 h-8 bg-cover mr-2 flex-shrink-0"
          style={{ backgroundImage: `url(${who.photo})` }}
        ></div>
        <div
          className="flex flex-col mr-2"
          style={{ width: "calc(100% - 6.5rem)" }}
        >
          <p className="text-xs font-medium truncate">{who.name}</p>
          <button
            onClick={() => setOpenedUser(who.uid)}
            className="text-xs text-blue-500 flex items-start"
          >
            Смотреть профиль
          </button>
        </div>
        <button
          onClick={() => {
            clickFav(fav + 1);
            if (inFavourite) {
              localStorage.setItem(
                "favourites",
                JSON.stringify(favourites.filter((c) => c !== chat.id))
              );
            } else {
              localStorage.setItem(
                "favourites",
                JSON.stringify([...favourites, chat.id])
              );
              firebase.analytics().logEvent("tinder-favourite");
            }
          }}
          className={`w-8 h-8 flex items-center justify-center flex-shrink-0 ${
            inFavourite ? "text-blue-500" : "text-gray-400"
          }`}
        >
          <Star size={24} />
        </button>
      </div>
      <div
        ref={scroll}
        className="chat flex flex-col-reverse p-3 h-full overflow-auto pt-14"
      >
        {getMessagesRows(messagesData)}
        {!messagesData.length && (
          <div className="text-center px-6 flex flex-col items-center text-gray-400 mt-auto">
            <MessageCircle className="mb-3" size={32} />
            <p className="text-xs mb-4">
              У вас мэтч! Не знаете с чего начать? Попробуйте с этого:
            </p>
            <button
              onClick={() => {
                sendMessage(randomHello);
                firebase.analytics().logEvent("tinder-intro-message");
              }}
              className="text-sm text-blue-500 mb-4"
            >
              {randomHello}
            </button>
          </div>
        )}
      </div>

      <div
        className={`${
          stickersShowed ? "bottom-14 opacity-1" : "-bottom-24 opacity-0"
        } transition-all py-2 ease-in-out duration-300 absolute left-0 w-full bg-gray-100 h-20 bg-gray-100 overflow-x-auto overflow-y-hidden`}
      >
        <div className="flex gap-2 px-2">
          {getStickerByRole(userInfo.role).map((c) => {
            return (
              <button
                key={c}
                onClick={() => {
                  sendMessage(`:${c}:`);
                }}
                className="w-16 h-16 rounded-lg p-1 flex-shrink-0"
              >
                <img src={`/images/stickers_v2/${c}.png`} alt={c} />
              </button>
            );
          })}
        </div>
      </div>

      <div className="flex p-2 border-t items-center relative bg-white">
        <div
          className="photo rounded-full w-8 h-8 bg-cover mr-2 flex-shrink-0"
          style={{ backgroundImage: `url(${userInfo.photo})` }}
        ></div>
        <input
          ref={input}
          type="text"
          className="border rounded-tl-lg rounded-bl-lg outline-none w-full pr-10 px-2 h-10 text-sm"
          value={message}
          onKeyDown={handleKeyDown}
          onChange={(e) => setMessage(e.target.value)}
        />
        <button
          onClick={() => showStickers(!stickersShowed)}
          className="w-10 h-10 flex text-primary items-center justify-center absolute right-12 top-2"
        >
          <Smile size={22} />
        </button>
        <button
          disabled={!message.length}
          onClick={() => sendMessage()}
          className="flex items-center justify-center flex-shrink-0 w-10 h-10 bg-indigo-600 rounded-tr-lg rounded-br-lg text-white"
        >
          <MessageCircle size={22} />
        </button>
      </div>
    </div>
  );
}
