import React, { useState } from "react";
import { Instagram } from "react-feather";

export default function TimetablePage({ data }) {
  const [showPast, setShowPast] = useState(false);

  const currentPlan = data ? data.find((t) => t.active) : {};
  const currentIndex = data ? data.indexOf(currentPlan) : -1;

  return (
    <div className="h-full flex flex-col items-center justify-center p-4 relative">
      <h1 className="text-xl font-bold w-full text-center">Программа</h1>

      <div className="flex flex-col mt-4 w-full">
        {currentIndex > 0 && !showPast && (
          <button
            onClick={() => setShowPast(true)}
            className="mb-2 w-full text-sm rounded-lg px-3 py-2 text-blue-500 font-medium bg-gray-100"
          >
            Показать прошедшее
          </button>
        )}
        {data.map((item, index) => {
          if (!["speaker"].includes(item.type)) return null;
          if (index < currentIndex && !showPast) {
            return null;
          }
          return (
            <div
              key={index}
              className="flex flex-col bg-gray-100 p-2 rounded-lg relative mb-2"
            >
              {index !== 7 && (
                <div className="w-1 h-2 bottom-full left-6 absolute bg-gray-100"></div>
              )}
              <p className="text-right -mb-4 text-blue-500 text-xs font-semibold">
                {item.time}
              </p>
              <div className="flex w-full pr-8">
                <div className="relative flex items-center justify-center rounded-full w-10 h-10 border-2 border-blue-600 flex-shrink-0">
                  {index === currentIndex && (
                    <div className="absolute bg-primary w-full h-full rounded-full animate-ping"></div>
                  )}
                  <div
                    style={{
                      backgroundImage: `url(${item.image})`,
                    }}
                    className="absolute rounded-full bg-cover w-full h-full"
                  ></div>
                </div>
                <div className="flex flex-col w-full ml-2 justify-center">
                  <p className="name text-sm text-black font-medium">
                    {item.title}
                  </p>
                  <p className="text-xs text-blue-500">
                    {item.job || "Должность"}
                  </p>
                </div>
              </div>
              <div className="w-full border-t mt-2 pt-2 text-xs mb-2">
                {item.text}
              </div>
              {item.instagram && (
                <a
                  className="mb-2 relative bg-primary px-2 h-5 flex items-center text-xs py-1 text-black rounded-full mr-auto pl-6"
                  href={`https://www.instagram.com/${item.instagram}/`}
                >
                  <span className="ring-1 ring-yellow-400 absolute w-5 h-5 text-black rounded-full bg-yellow-400 left-0 flex items-center justify-center">
                    <Instagram size={10} />
                  </span>
                  {item.instagram}
                </a>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
