import "./App.scss";
// import Snow from "react-snow-effect";

// Import FirebaseAuth and firebase.
import React, { useEffect, useState } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase";
import "firebase/analytics";

// Components
import Pitcha from "./components/Pitcha/Pitcha";

import logo from "./assets/pitcha-demo/logo.svg";

import Timer from "./components/Live/Widgets/Timer";

// Configure Firebase.
const config = {
  apiKey: "AIzaSyDRsLING5s2zL0aWvW-TRqD9phI_KwyV6Y",
  authDomain: "pitcha-cloud.firebaseapp.com",
  projectId: "pitcha-cloud",
  storageBucket: "pitcha-cloud.appspot.com",
  messagingSenderId: "429078005130",
  databaseURL:
    "https://pitcha-cloud-default-rtdb.europe-west1.firebasedatabase.app",
  appId: "1:429078005130:web:e6db43b484ecfc7b3b3473",
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
  firebase.analytics();
}

const uiConfig = {
  signInFlow: "popup",
  signInOptions: [
    // "apple.com",
    // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    // firebase.auth.EmailAuthProvider.PROVIDER_ID,
    // "anonymous",
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
    },
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};

const reserveUiConfig = {
  signInFlow: "popup",
  signInOptions: [
    "anonymous",
    "apple.com",
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    {
      provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      defaultCountry: "RU",
      whitelistedCountries: ["RU"],
    },
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    signInSuccessWithAuthResult: () => false,
  },
};

const db = firebase.firestore();

function App() {
  const [isSignedIn, setIsSignedIn] = useState(null);
  const [userInfo, setUserInfo] = useState({});
  const [redirect, setRedirect] = useState(null);
  const [isGuest, setGuest] = useState(false);
  const [timer, setTimer] = useState(true);
  const ROOM = "PITCHA-DEMO";

  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

  useEffect(() => {
    if (window.location.hash === "#guest") {
      setTimer(false);
      setGuest(true);
    }
  }, []);

  useEffect(() => {
    if (window.location.hash === "#skip") {
      setTimer(false);
    }
  }, []);

  const createUser = (user) => {
    // 1. Find user
    db.collection("users")
      .doc(user.uid)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          // 2. Create if not exist
          db.collection("users")
            .doc(user.uid)
            .set({
              uid: user.uid,
              createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
              updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
              name: user.displayName,
              photo: user.photoURL,
              email: user.email || "",
              external_link: "",
            })
            .then((docRef) => {
              setUserInfo({
                uid: user.uid,
                name: user.displayName,
                photo: user.photoURL,
                email: user.email,
                external_link: "",
              });
              setIsSignedIn(true);
            })
            .catch((error) => {
              console.error("Error adding document: ", error);
            });
        } else {
          const userData = doc.data();
          setUserInfo({
            isAdmin: localStorage.getItem("isAdmin"),
            ...userData,
          });
          if (userData.loginControlId) {
            firebase
              .database()
              .ref(`/loginControl/${ROOM}/${userData.whiteId}`)
              .set(userData.loginControlId);
          }
          setIsSignedIn(true);
        }
      })
      .catch((error) => {
        console.error("Error getting documents", error);
      });
  };

  const loadUser = () => {
    setRedirect(null);
    const uid = firebase.auth().currentUser.uid;
    db.collection("users")
      .doc(uid)
      .get()
      .then((doc) => {
        setUserInfo({
          isAdmin: localStorage.getItem("isAdmin") === "true" ? true : false,
          ...doc.data(),
        });
        setRedirect("/live");
      })
      .catch((error) => {
        console.error("Error getting documents", error);
      });
  };

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    if (window.location.hash === "#a") localStorage.setItem("isAdmin", true);
    if (window.location.hash === "#clear") localStorage.removeItem("isAdmin");

    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        if (user) {
          createUser(user);
        } else {
          setIsSignedIn(false);
        }
      });
    return () => unregisterAuthObserver();
  }, []);

  useEffect(() => {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      let savedMail = window.localStorage.getItem("emailForSignIn");
      if (!savedMail) {
        savedMail = prompt("На какой e-mail вам пришла ссылка для входа?");
        // console.error("Not finded email in localstorage");
        // if (isDev) {
        //   // dev code
        //   window.location.href = "//localhost:3000";
        // } else {
        //   // production code
        //   window.location.href = "https://vmeste-legko.ru";
        // }
        // return;
      }
      firebase
        .auth()
        .signInWithEmailLink(savedMail, window.location.href)
        .then((result) => {
          window.localStorage.removeItem("emailForSignIn");
          console.log("AUTHED");
        })
        .catch((error) => {
          alert(error);
          console.error("Not authed", error);
        });
    }
  }, [isDev]);

  // Show loader while we get auth state
  if (isSignedIn === null) {
    return (
      <>
        <img
          src={logo}
          className="w-32 md:w-40 mb-10 fixed left-1/2 top-1/2 animate-grow"
          alt="logo"
        />
      </>
    );
  }

  // if (timer) {
  //   return (
  //     <>
  //       <Snow className="z-0 absolute pointer-events-none" />
  //       <div className="z-10 fixed w-80 h-80 left-1/2 top-1/2 -m-40 flex flex-col items-center justify-center">
  //         <img src={logo} alt="logo" className="w-32 -mb-10" />
  //         <Timer onFinish={() => setTimer(false)} />
  //       </div>
  //     </>
  //   );
  // }

  if (!isSignedIn) {
    return (
      <>
        {/* <Snow className="z-0 absolute pointer-events-none" /> */}
        <div className="fixed w-full h-full left-0 top-0 z-10 px-4 flex flex-col items-center justify-center">
          <img src={logo} alt="logo" className="w-32 mx-auto mb-12" />

          <div className="form w-full max-w-sm flex flex-col gap-4 bg-white rounded-md p-6 relative shadow-xl">
            {!isGuest ? (
              <StyledFirebaseAuth
                uiConfig={isGuest ? uiConfig : reserveUiConfig}
                firebaseAuth={firebase.auth()}
              />
            ) : (
              <>
                <h3 className="text-xl text-center font-bold">
                  Вход на платформу
                </h3>
                <button
                  onClick={() => {
                    firebase.auth().signInAnonymously();
                  }}
                  className={
                    "text-alternate font-bold bg-indigo-600 uppercase tracking-wider text-white w-full rounded-lg p-3"
                  }
                >
                  Подключиться
                </button>
                {/* <p className="text-sm text-center mb-2 w-full">
                  Внимание! В&nbsp;процессе сегодняшнего онлайн-мероприятия
                  можно будет заработать баллы и&nbsp;потратить
                  их&nbsp;на&nbsp;призы. Баллы привязаны к&nbsp;устройству,
                  с&nbsp;которого вы&nbsp;участвуете в мероприятии. При смене
                  устройства баллы не&nbsp;будут суммироваться. Поэтому
                  настоятельно рекомендуем не&nbsp;менять устройство просмотра
                  в&nbsp;течение всего мероприятия.
                </p> */}
              </>
            )}
          </div>
          <a
            className="text-sm mt-4 text-black mb-10 flex justify-center font-medium"
            href="//t.me/kiwibudka"
            target="_blank"
            rel="noreferrer"
          >
            Служба поддержки
          </a>
        </div>
      </>
    );
  }

  return (
    <>
      {/* <Snow className="z-0 absolute pointer-events-none" /> */}
      <div className="relative z-10">
        <Pitcha
          userInfo={userInfo}
          redirect={redirect}
          needReload={() => loadUser()}
          room={ROOM}
        />
      </div>
    </>
  );
}

export default App;
