/* eslint-disable */
var password = null;

function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function resizeIframe(iframe) {
  if (iframe.contentWindow)
    iframe.height = iframe.contentWindow.document.body.scrollHeight + "px";

  if (!iframe.contentWindow.document.querySelectorAll("body > *").length) {
    iframe.height = "0px";
  }
}

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function deleteCookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

//console.log("master is here");

export class EventClass {
  access(password) {
    document.getElementsByClassName(this.settings.block)[0].innerHTML = "";
    var script_self = this;
    ////console.log('DOMContentLoaded');
    var xhr = new XMLHttpRequest();
    var url =
      this.settings.serverUrl +
      "json/events/" +
      this.settings.eventSlug +
      "/enter?language=" +
      this.settings.language;
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        var json = JSON.parse(xhr.responseText);
        script_self.json = json;
        ////console.log(json);

        if (json.error) {
          //console.log(json.error);
          script_self.renderError(json.error);
          return null;
        } else {
          setCookie(
            script_self.settings.eventSlug + "_event_pass",
            password,
            30
          );
          //console.log("event-settings");
          //console.log(json.event.settings);
          if (json.event.settings.event_background_type == "color") {
            document.getElementsByClassName(
              script_self.settings.block
            )[0].style.backgroundColor =
              json.event.settings.event_background_color;
          }
          if (json.event.settings.event_background_type == "image") {
            document.getElementsByClassName(
              script_self.settings.block
            )[0].style.backgroundImage =
              "url(" +
              script_self.settings.serverUrl +
              json.event.settings.event_background_image +
              ")";
          }
        }

        // var meta = document.createElement('meta');
        // meta.name = "settings_url";
        // meta.content = script_self.settings.serverUrl + "events/" + script_self.settings.eventSlug + "/17/settings.json";
        // document.getElementsByTagName('head')[0].appendChild(meta);
        //
        // var meta = document.createElement('meta');
        // meta.name = "df_language";
        // meta.content = script_self.settings.language;
        // document.getElementsByTagName('head')[0].appendChild(meta);

        var div_rooms = document.createElement("div");
        div_rooms.className = "rooms";

        var today = Date.now();
        ////console.log('today: ' + today)

        json.event.rooms.forEach(function (arrayItem, i) {
          var starts_at = arrayItem.starts_at
            ? Date.parse(arrayItem.starts_at.replace(" ", "T"))
            : null;
          var ends_at = arrayItem.ends_at
            ? Date.parse(arrayItem.ends_at.replace(" ", "T"))
            : null;
          ////console.log('time:' + ends_at);

          var btn_room = document.createElement("button");
          btn_room.className =
            "room-btn" +
            (arrayItem.id == json.first_room.id ? " active" : "") +
            (" room-btn-" + i);
          btn_room.innerText = arrayItem.name[script_self.settings.language];
          btn_room.addEventListener("click", function (event) {
            [].forEach.call(
              document
                .getElementsByClassName(script_self.settings.block)[0]
                .getElementsByClassName("room-btn"),
              function (el, n) {
                el.className = "room-btn" + (" room-btn-" + n);
                el.style.background =
                  json.event.settings.event_tab_inactive_background_color;
                el.style.color =
                  json.event.settings.event_tab_inactive_text_color;
                el.style.borderColor =
                  json.event.settings.event_tab_inactive_border_color;
              }
            );
            btn_room.className += " active";
            btn_room.style.background =
              json.event.settings.event_tab_active_background_color;
            btn_room.style.color =
              json.event.settings.event_tab_active_text_color;
            btn_room.style.borderColor =
              json.event.settings.event_tab_active_border_color;

            document
              .getElementsByClassName(script_self.settings.block)[0]
              .getElementsByClassName("room-block")[0].innerHTML = "";
            var div_new = document.createElement("div");
            div_new.className = "row room-block";

            //new
            document
              .getElementsByClassName(script_self.settings.block)[0]
              .getElementsByClassName("room-block")[0]
              .appendChild(
                script_self.renderIframe(
                  arrayItem.id,
                  password,
                  json.active_guest_id,
                  json.event.settings.event_background_color,
                  iframe_i - 1,
                  "header"
                )
              );

            document
              .getElementsByClassName(script_self.settings.block)[0]
              .getElementsByClassName("room-block")[0]
              .appendChild(
                script_self.renderIframe(
                  arrayItem.id,
                  password,
                  json.active_guest_id,
                  json.event.settings.event_background_color,
                  iframe_i - 1,
                  "left"
                )
              );
            document
              .getElementsByClassName(script_self.settings.block)[0]
              .getElementsByClassName("room-block")[0]
              .appendChild(
                script_self.renderIframe(
                  arrayItem.id,
                  password,
                  json.active_guest_id,
                  json.event.settings.event_background_color,
                  iframe_i - 1,
                  "right"
                )
              );

            //new
            document
              .getElementsByClassName(script_self.settings.block)[0]
              .getElementsByClassName("room-block")[0]
              .appendChild(
                script_self.renderIframe(
                  arrayItem.id,
                  password,
                  json.active_guest_id,
                  json.event.settings.event_background_color,
                  iframe_i - 1,
                  "footer"
                )
              );

            document.getElementById(
              "iframe_" +
                script_self.settings.eventSlug +
                "_room" +
                arrayItem.id +
                "_player"
            ).style.height =
              document.getElementById(
                "iframe_" +
                  script_self.settings.eventSlug +
                  "_room" +
                  arrayItem.id +
                  "_player"
              ).clientWidth *
                0.5625 +
              "px";
            if (
              document.getElementById(
                "iframe_" +
                  script_self.settings.eventSlug +
                  "_room" +
                  arrayItem.id +
                  "_player"
              ).clientWidth *
                0.5625 >
              300
            ) {
              document.getElementById(
                "iframe_" +
                  script_self.settings.eventSlug +
                  "_room" +
                  arrayItem.id +
                  "_chat"
              ).style.height =
                document.getElementById(
                  "iframe_" +
                    script_self.settings.eventSlug +
                    "_room" +
                    arrayItem.id +
                    "_player"
                ).clientWidth *
                  0.5625 +
                "px";
            } else {
              document.getElementById(
                "iframe_" +
                  script_self.settings.eventSlug +
                  "_room" +
                  arrayItem.id +
                  "_chat"
              ).style.height = "500px";
            }
            //document.getElementsByClassName(this.settings.block)[0].appendChild(div_new);
          });
          if (json.event.settings.event_tab_show) {
            if (
              json.password &&
              json.password.rooms &&
              json.password.rooms.length > 0
            ) {
              if (json.password.rooms.length > 0) {
                if (json.pluck_rooms.includes(arrayItem.id)) {
                  if (
                    (!arrayItem.starts_at || starts_at < today) &&
                    (!arrayItem.ends_at || ends_at > today)
                  ) {
                    div_rooms.appendChild(btn_room);
                  }
                }
              }
            } else {
              ////console.log('---')
              ////console.log('date comparison')
              ////console.log(starts_at)
              ////console.log('<')
              ////console.log(today)
              ////console.log('=')
              ////console.log(starts_at < today)
              ////console.log('---')
              ////console.log(ends_at)
              ////console.log('>')
              ////console.log(today)
              ////console.log('=')
              ////console.log(ends_at > today)
              ////console.log('---')
              if (
                (!arrayItem.starts_at || starts_at < today) &&
                (!arrayItem.ends_at || ends_at > today)
              ) {
                div_rooms.appendChild(btn_room);
              }
            }
          }
          ////console.log(arrayItem.name);
        });

        var btn_exit = document.createElement("button");
        btn_exit.className = "exit-btn";
        btn_exit.innerText =
          script_self.settings.language == "ru" ? "Р’С‹С…РѕРґ" : "Exit";
        btn_exit.addEventListener("click", function (event) {
          deleteCookie(script_self.settings.eventSlug + "_event_pass");
          script_self.renderEnter();
        });
        div_rooms.appendChild(btn_exit);

        var div_langs = document.createElement("div");
        div_langs.className = "langs";

        var btn_lang_ru = document.createElement("button");
        btn_lang_ru.className = "lang-btn";
        btn_lang_ru.innerHTML =
          '<img src="https://online.dataforum.pro/image/ru.png">';
        btn_lang_ru.addEventListener("click", function (event) {
          script_self.settings.language = "ru";

          json.event.rooms.forEach(function (arrayItem, i) {
            if (
              document
                .getElementsByClassName(script_self.settings.block)[0]
                .getElementsByClassName("room-btn-" + i)[0]
            ) {
              document
                .getElementsByClassName(script_self.settings.block)[0]
                .getElementsByClassName("room-btn-" + i)[0].innerText =
                json.event.rooms[i].name["ru"];
            }
          });

          document
            .getElementsByClassName(script_self.settings.block)[0]
            .getElementsByClassName("room-block")[0].innerHTML = "";
          var div_row = document.createElement("div");
          div_row.className = "row room-block";

          //new
          document
            .getElementsByClassName(script_self.settings.block)[0]
            .getElementsByClassName("room-block")[0]
            .appendChild(
              script_self.renderIframe(
                script_self.current_room,
                password,
                json.active_guest_id,
                json.event.settings.event_background_color,
                iframe_i - 1,
                "header"
              )
            );

          document
            .getElementsByClassName(script_self.settings.block)[0]
            .getElementsByClassName("room-block")[0]
            .appendChild(
              script_self.renderIframe(
                script_self.current_room,
                password,
                json.active_guest_id,
                json.event.settings.event_background_color,
                iframe_i - 1,
                "left"
              )
            );
          document
            .getElementsByClassName(script_self.settings.block)[0]
            .getElementsByClassName("room-block")[0]
            .appendChild(
              script_self.renderIframe(
                script_self.current_room,
                password,
                json.active_guest_id,
                json.event.settings.event_background_color,
                iframe_i - 1,
                "right"
              )
            );

          //new
          document
            .getElementsByClassName(script_self.settings.block)[0]
            .getElementsByClassName("room-block")[0]
            .appendChild(
              script_self.renderIframe(
                script_self.current_room,
                password,
                json.active_guest_id,
                json.event.settings.event_background_color,
                iframe_i - 1,
                "footer"
              )
            );

          document.getElementById(
            "iframe_" +
              script_self.settings.eventSlug +
              "_room" +
              script_self.current_room +
              "_player"
          ).style.height =
            document.getElementById(
              "iframe_" +
                script_self.settings.eventSlug +
                "_room" +
                script_self.current_room +
                "_player"
            ).clientWidth *
              0.5625 +
            "px";
          if (
            document.getElementById(
              "iframe_" +
                script_self.settings.eventSlug +
                "_room" +
                script_self.current_room +
                "_player"
            ).clientWidth *
              0.5625 >
            300
          ) {
            document.getElementById(
              "iframe_" +
                script_self.settings.eventSlug +
                "_room" +
                script_self.current_room +
                "_chat"
            ).style.height =
              document.getElementById(
                "iframe_" +
                  script_self.settings.eventSlug +
                  "_room" +
                  script_self.current_room +
                  "_player"
              ).clientWidth *
                0.5625 +
              "px";
          } else {
            document.getElementById(
              "iframe_" +
                script_self.settings.eventSlug +
                "_room" +
                script_self.current_room +
                "_chat"
            ).style.height = "500px";
          }
        });
        div_langs.appendChild(btn_lang_ru);

        var btn_lang_en = document.createElement("button");
        btn_lang_en.className = "lang-btn";
        btn_lang_en.innerHTML =
          '<img src="https://online.dataforum.pro/image/en.png">';
        btn_lang_en.addEventListener("click", function (event) {
          script_self.settings.language = "en";

          json.event.rooms.forEach(function (arrayItem, i) {
            if (
              document
                .getElementsByClassName(script_self.settings.block)[0]
                .getElementsByClassName("room-btn-" + i)[0]
            ) {
              document
                .getElementsByClassName(script_self.settings.block)[0]
                .getElementsByClassName("room-btn-" + i)[0].innerText = json
                .event.rooms[i].name["en"]
                ? json.event.rooms[i].name["en"]
                : json.event.rooms[i].name["ru"];
            }
          });

          document
            .getElementsByClassName(script_self.settings.block)[0]
            .getElementsByClassName("room-block")[0].innerHTML = "";
          var div_row = document.createElement("div");
          div_row.className = "row room-block";

          //new
          document
            .getElementsByClassName(script_self.settings.block)[0]
            .getElementsByClassName("room-block")[0]
            .appendChild(
              script_self.renderIframe(
                script_self.current_room,
                password,
                json.active_guest_id,
                json.event.settings.event_background_color,
                iframe_i - 1,
                "header"
              )
            );

          document
            .getElementsByClassName(script_self.settings.block)[0]
            .getElementsByClassName("room-block")[0]
            .appendChild(
              script_self.renderIframe(
                script_self.current_room,
                password,
                json.active_guest_id,
                json.event.settings.event_background_color,
                iframe_i - 1,
                "left"
              )
            );
          document
            .getElementsByClassName(script_self.settings.block)[0]
            .getElementsByClassName("room-block")[0]
            .appendChild(
              script_self.renderIframe(
                script_self.current_room,
                password,
                json.active_guest_id,
                json.event.settings.event_background_color,
                iframe_i - 1,
                "right"
              )
            );
          //document.getElementsByClassName(this.settings.block)[0].appendChild(div_row);

          //new
          document
            .getElementsByClassName(script_self.settings.block)[0]
            .getElementsByClassName("room-block")[0]
            .appendChild(
              script_self.renderIframe(
                script_self.current_room,
                password,
                json.active_guest_id,
                json.event.settings.event_background_color,
                iframe_i - 1,
                "footer"
              )
            );

          document.getElementById(
            "iframe_" +
              script_self.settings.eventSlug +
              "_room" +
              script_self.current_room +
              "_player"
          ).style.height =
            document.getElementById(
              "iframe_" +
                script_self.settings.eventSlug +
                "_room" +
                script_self.current_room +
                "_player"
            ).clientWidth *
              0.5625 +
            "px";
          if (
            document.getElementById(
              "iframe_" +
                script_self.settings.eventSlug +
                "_room" +
                script_self.current_room +
                "_player"
            ).clientWidth *
              0.5625 >
            300
          ) {
            document.getElementById(
              "iframe_" +
                script_self.settings.eventSlug +
                "_room" +
                script_self.current_room +
                "_chat"
            ).style.height =
              document.getElementById(
                "iframe_" +
                  script_self.settings.eventSlug +
                  "_room" +
                  script_self.current_room +
                  "_player"
              ).clientWidth *
                0.5625 +
              "px";
          } else {
            document.getElementById(
              "iframe_" +
                script_self.settings.eventSlug +
                "_room" +
                script_self.current_room +
                "_chat"
            ).style.height = "500px";
          }
        });
        div_langs.appendChild(btn_lang_en);

        if (!script_self.settings.room_id) {
          document
            .getElementsByClassName(script_self.settings.block)[0]
            .appendChild(div_rooms);
        }
        document
          .getElementsByClassName(script_self.settings.block)[0]
          .appendChild(div_langs);

        //return null;

        var iframe_i = 0;
        var div_row = document.createElement("div");
        div_row.className = "row room-block";

        var room_id = !script_self.settings.room_id
          ? json.first_room.id
          : script_self.settings.room_id;

        //new
        div_row.appendChild(
          script_self.renderIframe(
            room_id,
            password,
            json.active_guest_id,
            json.event.settings.event_background_color,
            iframe_i - 1,
            "header"
          )
        );

        div_row.appendChild(
          script_self.renderIframe(
            room_id,
            password,
            json.active_guest_id,
            json.event.settings.event_background_color,
            iframe_i - 1,
            "left"
          )
        );
        div_row.appendChild(
          script_self.renderIframe(
            room_id,
            password,
            json.active_guest_id,
            json.event.settings.event_background_color,
            iframe_i - 1,
            "right"
          )
        );

        //new
        div_row.appendChild(
          script_self.renderIframe(
            room_id,
            password,
            json.active_guest_id,
            json.event.settings.event_background_color,
            iframe_i - 1,
            "footer"
          )
        );

        document
          .getElementsByClassName(script_self.settings.block)[0]
          .appendChild(div_row);

        document.getElementById(
          "iframe_" +
            script_self.settings.eventSlug +
            "_room" +
            room_id +
            "_player"
        ).style.height =
          document.getElementById(
            "iframe_" +
              script_self.settings.eventSlug +
              "_room" +
              room_id +
              "_player"
          ).clientWidth *
            0.5625 +
          "px";
        if (
          document.getElementById(
            "iframe_" +
              script_self.settings.eventSlug +
              "_room" +
              room_id +
              "_player"
          ).clientWidth *
            0.5625 >
          300
        ) {
          document.getElementById(
            "iframe_" +
              script_self.settings.eventSlug +
              "_room" +
              room_id +
              "_chat"
          ).style.height =
            document.getElementById(
              "iframe_" +
                script_self.settings.eventSlug +
                "_room" +
                room_id +
                "_player"
            ).clientWidth *
              0.5625 +
            "px";
        } else {
          document.getElementById(
            "iframe_" +
              script_self.settings.eventSlug +
              "_room" +
              room_id +
              "_chat"
          ).style.height = "500px";
        }
      }
    };
    var data = JSON.stringify({ pass: password, language: "en" }); // REA2020
    xhr.send(data);
    // });
  }

  renderIframe(
    room_id,
    password,
    active_guest_id,
    background_color,
    iframe_i,
    col
  ) {
    var col_selector = "";
    var col_link = "";
    if (col === "left") {
      col_selector = "_player";
      col_link =
        "player?pass=" +
        password +
        "&active_guest_id=" +
        active_guest_id +
        "&language=" +
        this.settings.language;
    } else if (col === "right") {
      col_selector = "_chat";
      col_link =
        "chat?pass=" + password + "&language=" + this.settings.language;
    } else if (col === "header") {
      col_selector = "_header";
      col_link = "header";
    } else if (col === "footer") {
      col_selector = "_footer";
      col_link = "footer";
    }

    var script_self = this;
    this.current_room = room_id;
    var div_col = document.createElement("div");
    div_col.className = "col-" + col;

    var spinner_player = document.createElement("div");
    spinner_player.className = "spinner spinner" + iframe_i;
    spinner_player.style.background = "transparent";
    var spinner_player_img = document.createElement("img");
    spinner_player_img.src = "https://online.dataforum.pro/client/loading.gif";
    spinner_player_img.width = "30";
    spinner_player_img.height = "30";
    spinner_player.appendChild(spinner_player_img);
    if (col === "header" || col === "footer") {
      spinner_player.style.display = "none";
    }

    var iframe_player = document.createElement("iframe");
    iframe_player.id =
      "iframe_" + this.settings.eventSlug + "_room" + room_id + col_selector;
    iframe_player.width = "100%";
    iframe_player.style.height = "calc(width * 1.75)";
    iframe_player.setAttribute("allowFullScreen", "");
    window.addEventListener("resize", function (event) {
      if (
        document.getElementById(
          "iframe_" +
            script_self.settings.eventSlug +
            "_room" +
            room_id +
            "_player"
        )
      ) {
        document.getElementById(
          "iframe_" +
            script_self.settings.eventSlug +
            "_room" +
            room_id +
            "_player"
        ).style.height =
          document.getElementById(
            "iframe_" +
              script_self.settings.eventSlug +
              "_room" +
              room_id +
              "_player"
          ).clientWidth *
            0.5625 +
          "px";
        if (
          document.getElementById(
            "iframe_" +
              script_self.settings.eventSlug +
              "_room" +
              room_id +
              "_player"
          ).clientWidth *
            0.5625 >
          300
        ) {
          document.getElementById(
            "iframe_" +
              script_self.settings.eventSlug +
              "_room" +
              room_id +
              "_chat"
          ).style.height =
            document.getElementById(
              "iframe_" +
                script_self.settings.eventSlug +
                "_room" +
                room_id +
                "_player"
            ).clientWidth *
              0.5625 +
            "px";
        } else {
          document.getElementById(
            "iframe_" +
              script_self.settings.eventSlug +
              "_room" +
              room_id +
              "_chat"
          ).style.height = "500px";
        }
      }
    });
    iframe_player.src =
      this.settings.serverUrl +
      "json/events/" +
      this.settings.eventSlug +
      "/" +
      room_id +
      "/" +
      col_link;

    if (col === "header" || col === "footer") {
      iframe_player.height = "0px";
    }

    div_col.appendChild(spinner_player);
    iframe_player.onload = function () {
      ////console.log(spinner_player);
      spinner_player.style.display = "none";
      if (col === "header" || col === "footer") {
        resizeIframe(iframe_player);
      }
    };
    div_col.appendChild(iframe_player);

    return div_col;
  }

  renderEnter() {
    var script_self = this;

    if (event.settings.enter_background_type == "color") {
      document.getElementsByClassName(
        script_self.settings.block
      )[0].style.backgroundColor = event.settings.enter_background_color;
    }
    if (event.settings.enter_background_type == "image") {
      document.getElementsByClassName(
        script_self.settings.block
      )[0].style.backgroundImage =
        "url(" +
        script_self.settings.serverUrl +
        event.settings.enter_background_image +
        ")";
    }
    //document.getElementsByClassName(this.settings.block)[0].style.backgroundColor = event.settings.enter_background_color;
    ////console.log('event' + event.id + ' color: ' + event.settings.enter_background_color)

    var div_enter = document.createElement("div");
    div_enter.className = "enter";
    div_enter.style.background = event.settings.enter_window_background_color;
    div_enter.style.color = event.settings.enter_window_text_color;

    var div_h2 = document.createElement("h2");
    div_h2.innerText =
      this.settings.language == "ru"
        ? "РђРІС‚РѕСЂРёР·Р°С†РёСЏ"
        : "Authorization"; //event.name[this.settings.language]
    div_enter.appendChild(div_h2);

    var div_welcome = document.createElement("p");
    div_welcome.innerHTML = event.welcome[this.settings.language];
    div_enter.appendChild(div_welcome);

    var div_enter_input = document.createElement("input");
    div_enter_input.type = "text";
    //div_enter_input.value = "qhlv";
    div_enter_input.className = "enter-input";
    div_enter_input.placeholder =
      this.settings.language == "ru"
        ? "Р’РІРµРґРёС‚Рµ РїР°СЂРѕР»СЊ"
        : "Enter password";
    div_enter_input.style.background =
      event.settings.enter_input_background_color;
    div_enter_input.style.color = event.settings.enter_input_text_color;
    div_enter.appendChild(div_enter_input);

    var div_enter_btn = document.createElement("button");
    div_enter_btn.className = "enter-btn";
    div_enter_btn.innerText =
      this.settings.language == "ru" ? "Р’РѕР№С‚Рё" : "Enter";
    div_enter_btn.style.background =
      event.settings.enter_button_background_color;
    div_enter_btn.style.color = event.settings.enter_button_text_color;
    div_enter_btn.addEventListener("click", function (event) {
      ////console.log(div_enter_input.value);
      script_self.access(div_enter_input.value);
    });
    div_enter.appendChild(div_enter_btn);

    document.getElementsByClassName(this.settings.block)[0].innerHTML = "";
    document
      .getElementsByClassName(this.settings.block)[0]
      .appendChild(div_enter);
  }

  renderError(error_text) {
    var script_self = this;
    document.getElementsByClassName(
      this.settings.block
    )[0].style.backgroundColor = event.settings.event_background_color;

    var div_error = document.createElement("div");
    div_error.className = "enter";
    div_error.style.background = event.settings.enter_window_background_color;
    div_error.style.color = event.settings.enter_window_text_color;

    var div_h2 = document.createElement("h2");
    div_h2.innerText = event.name[this.settings.language];
    div_error.appendChild(div_h2);

    var div_error_text = document.createElement("p");
    div_error_text.innerText = error_text;
    div_error.appendChild(div_error_text);

    var div_error_btn = document.createElement("button");
    div_error_btn.className = "enter-btn";
    div_error_btn.innerText =
      this.settings.language == "ru" ? "РќР°Р·Р°Рґ" : "Return";
    div_error_btn.style.background =
      event.settings.enter_button_background_color;
    div_error_btn.style.color = event.settings.enter_button_text_color;
    div_error_btn.addEventListener("click", function (event) {
      script_self.renderEnter();
    });
    div_error.appendChild(div_error_btn);

    document.getElementsByClassName(this.settings.block)[0].innerHTML = "";
    document
      .getElementsByClassName(this.settings.block)[0]
      .appendChild(div_error);
  }
  constructor(settings) {
    this.event = null;
    this.current_room = null;
    this.json = null;

    if (settings) {
      this.settings = settings;
      var script_self = this;
      var xhr2 = new XMLHttpRequest();
      var url =
        settings.serverUrl + "json/events/" + this.settings.eventSlug + "/";
      xhr2.open("POST", url, true);
      xhr2.setRequestHeader("Content-Type", "application/json");
      xhr2.onreadystatechange = function () {
        if (xhr2.readyState === 4 && xhr2.status === 200) {
          event = JSON.parse(xhr2.responseText);
          ////console.log('event loaded');
          ////console.log(event);
          if (getCookie(script_self.settings.eventSlug + "_event_pass")) {
            password = getCookie(
              script_self.settings.eventSlug + "_event_pass"
            );
          }
          if (getParameterByName("df_pass")) {
            ////console.log('pass parameter')
            password = getParameterByName("df_pass");
          }

          if (password) {
            //password = getCookie(script_self.settings.eventSlug + '_event_pass');
            script_self.access(password);
          } else {
            if (event.access_type == "protected") {
              script_self.renderEnter();
            } else {
              script_self.access("");
            }
          }
        }
      };
      xhr2.send();
    }

    if (!script_self.settings.language) {
      script_self.settings.language = "ru";
    }
  }
  init(settings) {
    this.settings = settings;
    var script_self = this;
    var xhr2 = new XMLHttpRequest();
    var url =
      this.settings.serverUrl + "json/events/" + this.settings.eventSlug + "/";
    xhr2.open("POST", url, true);
    xhr2.setRequestHeader("Content-Type", "application/json");
    xhr2.onreadystatechange = function () {
      if (xhr2.readyState === 4 && xhr2.status === 200) {
        event = JSON.parse(xhr2.responseText);
        ////console.log('event loaded');
        ////console.log(event);
        if (
          password ||
          getCookie(script_self.settings.eventSlug + "_event_pass")
        ) {
          password = getCookie(script_self.settings.eventSlug + "_event_pass");
          this.access(password);
        } else {
          if (event.access_type == "protected") {
            this.renderEnter();
          } else {
            this.access("");
          }
        }
      }
    };
    xhr2.send();
  }
}
