import React, { useState, useEffect } from "react";
import firebase from "firebase";

export default function QuizOpen({ userInfo, data, room }) {
  const [myAnswer, setMyAnswer] = useState(null);
  const [sended, setSended] = useState(false);
  const [loading, setLoading] = useState(false);
  const [remainTime, setRemainTime] = useState(null);

  const db = firebase.firestore();

  useEffect(() => {
    setSended(false);
    setMyAnswer(null);
    setLoading(false);
    setRemainTime(null);
  }, [data.id]);

  useEffect(() => {
    let timer;
    if (data.step === "start") {
      if (remainTime === null) setRemainTime(30);
      timer = setTimeout(() => {
        if (remainTime > 0) {
          setRemainTime(remainTime - 1);
        }
      }, 1000);
    } else {
      setRemainTime(null);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [remainTime, data.step, data.id]);

  const sendAnswer = () => {
    setLoading(true);
    db.collection("quizes")
      .doc(`${userInfo.uid}-${data.id}`)
      .set({
        room,
        question: parseInt(data.id),
        answer: myAnswer.trim().toLowerCase(),
        uid: userInfo.uid,
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
      })
      .then((docRef) => {
        setSended(true);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error adding answer: ", error);
        setSended(true);
        setLoading(false);
      });
  };

  return (
    <div className="h-full flex flex-col items-center justify-center p-6 relative">
      <div
        style={{ transform: `scaleX(${1 - remainTime / 30})` }}
        className="absolute top-0 w-full origin-left h-1 bg-indigo-600 ease-out transition duration-700"
      ></div>
      <div className="relative text-sm rounded-full font-bold w-10 h-10 border border-indigo-400 text-indigo-600 flex items-center justify-center mb-4">
        {parseInt(remainTime || 0)}
        {data.step === "start" && (
          <span className="absolute animate-ping h-full rounded-full w-full border border-indigo-400"></span>
        )}
      </div>
      <h1 className="text-prosto text-2xl font-bold w-full text-center">
        {data.text}
      </h1>
      {data.step === "correct" && (
        <p className="text-sm text-green-500 mt-2 text-center">
          Правильный ответ: {data.opencorrect.split(",")[0].trim()}
        </p>
      )}
      <div className="flex flex-col gap-2 mt-4 w-full">
        <input
          type="text"
          placeholder="Введите свой ответ"
          value={myAnswer}
          className="border p-3 text-sm w-full text-center rounded-lg"
          onChange={(e) =>
            setMyAnswer(
              e.target.value.replace(/[^\wа-яА-Я\s]|_/g, "").slice(0, 40)
            )
          }
        />
        {data.step === "start" && (
          <button
            onClick={sendAnswer}
            disabled={myAnswer === null || loading || sended}
            className={`${
              myAnswer === null || loading || sended
                ? "pointer-events-none opacity-50"
                : ""
            } text-white w-full text-center p-3 text-sm uppercase tracking-wider font-bold rounded-lg bg-indigo-600`}
          >
            {loading && "Отправляем ответ..."}
            {sended && "Ответ принят"}
            {!loading && !sended && "Подтвердить ответ"}
          </button>
        )}
      </div>
    </div>
  );
}
