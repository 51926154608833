import React, { useEffect, useState } from "react";
import Lottie from "react-lottie-player";
import pulse from "../../../assets/snowman.json";

export default function Timer({ onFinish }) {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const now = new Date().getTime();
    const countDownDate = 1663372800000; // 20.02 11:00 msk
    if (countDownDate - now < 0 || window.location.hash === "#skip") onFinish();

    // Update the count down every 1 second
    const interval = setInterval(() => {
      // Get today's date and time
      const now = new Date().getTime();

      // Find the distance between now and the count down date
      const distance = countDownDate - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));

      setHours(
        Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) +
          days * 24
      );
      setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
      setSeconds(Math.floor((distance % (1000 * 60)) / 1000));

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(interval);
        onFinish();
      }
    }, 1000);

    return (_) => clearTimeout(interval);
  }, [onFinish]);

  return (
    <div
      style={{ minHeight: 300 }}
      className="mt-10 pb-20 relative w-full h-full flex items-center flex-col justify-center items-center"
    >
      <Lottie
        loop
        animationData={pulse}
        play
        className="w-3/4 md:w-1/2 -mt-10"
      />

      <h1 className="text-md font-medium text-center mt-2 mb-1 show-from-top">
        Все откроется через
      </h1>
      <div className="flex text-xl font-medium mt-2">
        <div className="rounded-lg bg-white text-decorate text-indigo-600 ml-2 w-16 h-16 border border-cyan-600 flex items-center justify-center show-from-top animation-delay-300">
          {hours}
        </div>
        <div className="rounded-lg bg-white text-decorate text-indigo-600 ml-2 w-16 h-16 border border-cyan-600 flex items-center justify-center show-from-top animation-delay-600">
          {minutes}
        </div>
        <div className="rounded-lg bg-white text-decorate text-indigo-600 ml-2 w-16 h-16 border border-cyan-600 flex items-center justify-center show-from-top animation-delay-900">
          {seconds}
        </div>
      </div>
      <div className="flex text-xs opacity-50 mt-2 mb-4">
        <div className="ml-2 w-16 flex items-center justify-center show-from-top animation-delay-600">
          часы
        </div>
        <div className="ml-2 w-16 flex items-center justify-center show-from-top animation-delay-900">
          мин
        </div>
        <div className="ml-2 w-16 flex items-center justify-center show-from-top animation-delay-1200">
          сек
        </div>
      </div>
    </div>
  );
}
