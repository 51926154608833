import React, { useState, useEffect } from "react";
import firebase from "firebase";
import { Play, RefreshCw, Square } from "react-feather";

export default function Optimizer({ roomInfo, now }) {
  const [chatData, setChatData] = useState([]);
  const [questionsData, setQuestionsData] = useState([]);
  const [questionsLikesData, setQuestionsLikesData] = useState([]);
  const [pollsData, setPollsData] = useState({});

  const [lastChatData, setLastChatData] = useState([]);
  const [lastQuestionsData, setLastQuestionsData] = useState([]);
  const [lastQuestionsLikesData, setLastQuestionsLikesData] = useState([]);
  const [lastPollsData, setLastPollsData] = useState({});

  const [lastPollId, setLastPollId] = useState(null);
  const [lastQuestionId, setLastQuestionId] = useState(null);

  const [syncTimer, setSyncTimer] = useState(1000);
  const [customTimer, setCustomTimer] = useState("");
  const [timerState, setTimerState] = useState("stop");
  const [prevSyncCompleted, setPrevSyncCompleted] = useState(true);

  useEffect(() => {
    if (now && now.data && now.data.questions) {
      setLastQuestionsData(now.data.questions);
    }
    if (now && now.data && now.data.questionsLikes) {
      setLastQuestionsLikesData(now.data.questionsLikes);
    }
    if (now && now.data && now.data.polls) {
      setLastPollsData(now.data.polls);
    }

    const db = firebase.firestore();
    // Listen chat
    const subscriber = db
      .collection("chat")
      .orderBy("createdAt", "desc")
      .limit(50)
      .onSnapshot((querySnapshot) => {
        const tmpMessages = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          tmpMessages.push({
            id: doc.id,
            cAt: data.createdAt.seconds,
            a: data.uid,
            l: data.link || "",
            r: data.r || 1,
            m: data.message,
            p: data.photo,
            n: data.name,
            h: data.hide || false,
          });
        });
        setChatData(tmpMessages);
      });

    // Listen polls
    let pollsSubscriber;
    if (
      now &&
      now.state &&
      ((now.state.room1 && now.state.room1.type === "poll") ||
        (now.state.room2 && now.state.room2.type === "poll"))
    ) {
      const pollId =
        now.state.room1.type === "poll"
          ? now.state.room1.id
          : now.state.room2.id;
      if (pollId !== lastPollId) {
        setLastPollId(pollId);
        setPollsData({});
        setLastPollsData({ clear: true });
      }

      pollsSubscriber = db
        .collection("polls")
        .where("question", "==", pollId)
        .onSnapshot((querySnapshot) => {
          const tmpAnswers = {};
          now.state.room1.answers.forEach((text, index) => {
            tmpAnswers[index] = 0;
          });
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            tmpAnswers[data.answer]++;
          });
          setPollsData(tmpAnswers);
        });
    } else {
      if (
        now &&
        now.state &&
        ((now.state.room1 && now.state.room1.type === "words-cloud") ||
          (now.state.room2 && now.state.room2.type === "words-cloud"))
      ) {
      } else {
        setPollsData({});
        setLastPollsData({ clear: true });
      }
    }
    // Words cloud
    if (
      now &&
      now.state &&
      ((now.state.room1 && now.state.room1.type === "words-cloud") ||
        (now.state.room2 && now.state.room2.type === "words-cloud"))
    ) {
      const cloudId =
        now.state.room1.type === "words-cloud"
          ? now.state.room1.id
          : now.state.room2.id;

      if (cloudId !== lastPollId) {
        setLastPollId(cloudId);
        setPollsData({});
        setLastPollsData({ clear: true });
      }

      pollsSubscriber = db
        .collection("words-clouds")
        .where("question", "==", cloudId)
        .onSnapshot((querySnapshot) => {
          const tmpAnswers = {};
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            const key = data.answer.split(".").join(" ");
            if (!tmpAnswers[key]) {
              tmpAnswers[key] = 0;
            }
            tmpAnswers[key]++;
          });
          setPollsData(tmpAnswers);
        });
    } else {
      if (
        now &&
        now.state &&
        ((now.state.room1 && now.state.room1.type === "poll") ||
          (now.state.room2 && now.state.room2.type === "poll"))
      ) {
      } else {
        setPollsData({});
        setLastPollsData({ clear: true });
      }
    }

    // Listen questions
    let questionSubscriber, likesSubscriber;
    if (
      now &&
      now.state &&
      ((now.state.room1 && now.state.room1.type === "speaker-question") ||
        (now.state.room2 && now.state.room2.type === "speaker-question"))
    ) {
      const questionId =
        now.state.room1.type === "speaker-question"
          ? now.state.room1.id
          : now.state.room2.id;

      if (questionId !== lastQuestionId) {
        setLastQuestionId(questionId);
        setQuestionsData([]);
        setLastQuestionsData([0]);
        setQuestionsLikesData([]);
        setLastQuestionsLikesData([0]);
      }

      questionSubscriber = db
        .collection("questions")
        .where("gqid", "==", questionId)
        .onSnapshot((querySnapshot) => {
          const tmpQuestions = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            tmpQuestions.push({
              id: doc.id,
              a: data.uid,
              cAt: data.createdAt.seconds,
              r: data.role || 1,
              m: data.message,
              p: data.photo,
              l: data.external_link || "#",
              n: data.name,
              h: data.hide || false,
            });
          });
          tmpQuestions.sort((a, b) => (a.cAt < b.cAt ? -1 : 1));
          setQuestionsData(tmpQuestions);
        });

      likesSubscriber = db
        .collection("questions-likes")
        .where("gqid", "==", questionId)
        .onSnapshot((querySnapshot) => {
          const tmpLikes = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            tmpLikes.push({
              id: doc.id,
              uid: data.uid,
              qid: data.qid,
            });
          });
          setQuestionsLikesData(tmpLikes);
        });
    } else {
      setQuestionsData([]);
      setLastQuestionsData([0]);
      setQuestionsLikesData([]);
      setLastQuestionsLikesData([0]);
    }

    // Unsubscrbe from chat
    return () => {
      if (questionSubscriber && typeof questionSubscriber === "function")
        questionSubscriber();
      if (likesSubscriber && typeof likesSubscriber === "function")
        likesSubscriber();
      if (pollsSubscriber && typeof pollsSubscriber === "function")
        pollsSubscriber();
      subscriber();
    };
  }, [lastPollId, lastQuestionId, now]);

  const sync = () => {
    if (timerState === "stop") {
      console.log("stopped");
      return;
    }
    if (!prevSyncCompleted) {
      console.log("prev sync not completed");
      return;
    }
    setPrevSyncCompleted(false);

    const db = firebase.database();

    if (
      JSON.stringify(lastChatData) === JSON.stringify(chatData) &&
      JSON.stringify(lastQuestionsData) === JSON.stringify(questionsData) &&
      JSON.stringify(lastQuestionsLikesData) ===
        JSON.stringify(questionsLikesData) &&
      JSON.stringify(lastPollsData) === JSON.stringify(pollsData)
    ) {
      console.log("Not need to sync");
      setPrevSyncCompleted(true);
    } else {
      if (
        // Only chat
        JSON.stringify(lastChatData) !== JSON.stringify(chatData) &&
        JSON.stringify(lastQuestionsData) === JSON.stringify(questionsData) &&
        JSON.stringify(lastQuestionsLikesData) ===
          JSON.stringify(questionsLikesData) &&
        JSON.stringify(lastPollsData) === JSON.stringify(pollsData)
      ) {
        db.ref(`/rooms/${roomInfo.id}/data/chat`)
          .set(chatData)
          .then((docRef) => {
            setLastChatData(chatData);
            console.log("Chat synced at", new Date().toLocaleTimeString());
            setPrevSyncCompleted(true);
          })
          .catch((error) => {
            console.error("Error set state data: ", error);
          });
      } else if (
        // Only questions
        JSON.stringify(lastChatData) === JSON.stringify(chatData) &&
        JSON.stringify(lastQuestionsData) !== JSON.stringify(questionsData) &&
        JSON.stringify(lastQuestionsLikesData) ===
          JSON.stringify(questionsLikesData) &&
        JSON.stringify(lastPollsData) === JSON.stringify(pollsData)
      ) {
        db.ref(`/rooms/${roomInfo.id}/data/questions`)
          .set(questionsData)
          .then((docRef) => {
            setLastQuestionsData(questionsData);
            console.log("Questions synced at", new Date().toLocaleTimeString());
            setPrevSyncCompleted(true);
          })
          .catch((error) => {
            console.error("Error set state data: ", error);
          });
      } else if (
        // Only likes
        JSON.stringify(lastChatData) === JSON.stringify(chatData) &&
        JSON.stringify(lastQuestionsData) === JSON.stringify(questionsData) &&
        JSON.stringify(lastQuestionsLikesData) !==
          JSON.stringify(questionsLikesData) &&
        JSON.stringify(lastPollsData) === JSON.stringify(pollsData)
      ) {
        db.ref(`/rooms/${roomInfo.id}/data/questionsLikes`)
          .set(questionsLikesData)
          .then((docRef) => {
            setLastQuestionsLikesData(questionsLikesData);
            console.log("Likes synced at", new Date().toLocaleTimeString());
            setPrevSyncCompleted(true);
          })
          .catch((error) => {
            console.error("Error set state data: ", error);
          });
      } else if (
        // Only polls
        JSON.stringify(lastChatData) === JSON.stringify(chatData) &&
        JSON.stringify(lastQuestionsData) === JSON.stringify(questionsData) &&
        JSON.stringify(lastQuestionsLikesData) ===
          JSON.stringify(questionsLikesData) &&
        JSON.stringify(lastPollsData) !== JSON.stringify(pollsData)
      ) {
        db.ref(`/rooms/${roomInfo.id}/data/polls`)
          .set(pollsData)
          .then((docRef) => {
            setLastPollsData(pollsData);
            console.log("Polls synced at", new Date().toLocaleTimeString());
            setPrevSyncCompleted(true);
          })
          .catch((error) => {
            console.error("Error set state data: ", error);
          });
      } else {
        db.ref(`/rooms/${roomInfo.id}/data`)
          .set({
            chat: chatData,
            questions: questionsData,
            questionsLikes: questionsLikesData,
            polls: pollsData,
          })
          .then((docRef) => {
            setLastChatData(chatData);
            setLastQuestionsData(questionsData);
            setLastQuestionsLikesData(questionsLikesData);
            setLastPollsData(pollsData);
            console.log("Fullsynced at", new Date().toLocaleTimeString());
            setPrevSyncCompleted(true);
          })
          .catch((error) => {
            console.error("Error set state data: ", error);
          });
      }
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      sync();
    }, syncTimer);
    return () => {
      clearInterval(timer);
    };
  });

  return (
    <div className="grid grid-cols-12 gap-4 optimizer">
      <div className="col-span-12 bg-white p-3 bg-white rounded-lg">
        <div className="flex items-center">
          <p className="mr-2 text-nowrap text-sm font-medium">Sync every</p>
          <div className="flex gap-2 mr-2">
            {[500, 1000, 2000, 3000, 4000, 5000, "custom"].map((b) => {
              return (
                <button
                  key={b}
                  onClick={() => setSyncTimer(b === "custom" ? customTimer : b)}
                  disabled={b === "custom" && customTimer.length === 0}
                  className={`border p-2 text-sm rounded-md ${
                    b === syncTimer ? "text-indigo-600 border-indigo-600" : ""
                  }`}
                >
                  {b > 0 ? (b / 1000).toFixed(1) : b}
                </button>
              );
            })}
          </div>
          <input
            type="number"
            placeholder="Custom time"
            className="border p-2 text-sm rounded-md"
            onChange={(e) => setCustomTimer(e.target.value)}
          />
          <button
            className={`bg-indigo-600 text-white p-2 text-sm px-3 rounded-md border border-indigo-600 text-nowrap ml-2`}
            onClick={sync}
          >
            <RefreshCw size={20} />
          </button>
          <button
            className={`${
              timerState === "start"
                ? "bg-indigo-600 text-white"
                : "text-indigo-600 "
            }   p-2 text-sm px-3 rounded-md border border-indigo-600 text-nowrap ml-2`}
            onClick={() => {
              setTimerState("start");
            }}
          >
            <Play size={20} />
          </button>
          <button
            className={`${
              timerState === "stop"
                ? "bg-indigo-600 text-white"
                : "text-indigo-600"
            }   p-2 text-sm px-3 rounded-md border border-indigo-600 text-nowrap ml-2`}
            onClick={() => {
              setTimerState("stop");
            }}
          >
            <Square size={20} />
          </button>
        </div>
      </div>

      <div className="col-span-4 rounded-lg bg-white p-3 h-screen overflow-auto">
        <div className="h-1/2 overflow-auto">
          <div className="text-sm mb-3 font-medium">Chat</div>

          <pre className="p-3 bg-gray-800 mb-0 rounded text-white text-xs">
            {JSON.stringify(chatData, null, " ")}
          </pre>
        </div>
        <div className="h-1/2 overflow-auto">
          <div className="text-sm mb-3 font-medium">Polls</div>

          <pre className="p-3 bg-gray-800 mb-0 rounded text-white text-xs">
            {JSON.stringify(pollsData, null, " ")}
          </pre>
        </div>
      </div>
      <div className="col-span-4 rounded-lg bg-white p-3 h-screen overflow-auto">
        <div className="h-1/2 overflow-auto">
          <div className="text-sm mb-3 font-medium">Questions</div>
          <pre className="p-3 bg-gray-800 mb-0 rounded text-white text-xs">
            {JSON.stringify(questionsData, null, " ")}
          </pre>
        </div>
        <div className="h-1/2 overflow-auto">
          <div className="text-sm mb-3 font-medium">Questions Likes</div>
          <pre className="p-3 bg-gray-800 mb-0 rounded text-white text-xs">
            {JSON.stringify(questionsLikesData, null, " ")}
          </pre>
        </div>
      </div>
      <div className="col-span-4 rounded-lg bg-white p-3 h-screen overflow-auto">
        <div className="text-sm mb-3 font-medium">State</div>
        <pre className="p-3 bg-gray-800 mb-0 rounded text-white text-xs">
          {JSON.stringify(now, null, " ")}
        </pre>
      </div>
    </div>
  );
}
