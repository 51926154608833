import React, { useState, useEffect } from "react";
import firebase from "firebase";

import star from "../../../assets/star.svg";
import starFill from "../../../assets/star-fill.svg";

export default function Rate({ userInfo, data, pollsData, obs }) {
  const [myAnswer, setMyAnswer] = useState(null);
  const [sended, setSended] = useState(false);
  const [loading, setLoading] = useState(false);
  const [answers, setAnswers] = useState({});

  const db = firebase.firestore();
  const rates = [1, 2, 3, 4, 5];

  useEffect(() => {
    setSended(false);
    setMyAnswer(null);
    setLoading(false);
    setAnswers({});
  }, [data.id]);

  useEffect(() => {
    setAnswers(pollsData);
  }, [pollsData]);

  const sendAnswer = () => {
    setLoading(true);
    db.collection("polls")
      .doc(`${userInfo.uid}-${data.id}`)
      .set({
        question: parseInt(data.id),
        answer: parseInt(myAnswer),
        uid: userInfo.uid,
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
      })
      .then((docRef) => {
        setSended(true);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error adding answer: ", error);
        setSended(true);
        setLoading(false);
      });
  };

  const totalAnswers = Object.keys(answers).reduce((counter, item) => {
    return counter + answers[item];
  }, 0);

  let rate = 0;
  Object.keys(answers).forEach((index) => {
    if (index !== 0) {
      rate = rate + index * answers[index];
    }
  });
  rate = totalAnswers > 0 ? rate / totalAnswers : 0;

  if (obs) {
    return (
      <div className="h-full flex flex-col items-center justify-center p-6">
        <h1 className="font-bold text-5xl w-full font-bold mb-10 border-b border-gray-800 pb-5 relative">
          {data.text}
        </h1>
        <div className="flex w-full">
          <div
            style={{ fontSize: 64 }}
            className="w-1/4 flex font-bold flex-shrink-0 items-center justify-center text-white"
          >
            {rate.toFixed(2)}
          </div>
          <div className="flex flex-col w-full relative">
            {data.answers.map((text, index) => {
              return (
                <div
                  key={`${text}_${index}`}
                  className="flex gap-4 w-full items-center mb-8"
                >
                  <div className="w-64 p-2 text-5xl font-bold flex-shrink-0 relative">
                    <div className="absolute left-0 top-0 w-full h-full bg-blue-900"></div>
                    <div
                      style={{
                        transform: `scaleX(${
                          totalAnswers && answers[index]
                            ? 0.05 +
                              (95 * answers[index]) / (100 * totalAnswers)
                            : 0.05
                        })`,
                      }}
                      className="absolute duration-700 transition transform-gpu origin-left ease-out bg-indigo-600 w-full left-0 top-0 h-full"
                    />
                    <span className="relative">
                      {totalAnswers && answers[index]
                        ? ((100 * answers[index]) / totalAnswers).toFixed(2)
                        : 0}
                      %
                    </span>
                  </div>
                  <p className="text-3xl font-medium w-full">{text}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{ minHeight: 300 }}
      className="h-full flex flex-col items-center justify-center p-6"
    >
      <div className="flex border-b w-full h-full justify-center items-center mb-4 pb-2">
        <p
          style={{ fontSize: 50 }}
          className="tracking-widest text-decorate text-indigo-600 font-bold"
        >
          {(rate || 0).toFixed(2)}
        </p>
      </div>

      <h1 className="text-prosto text-md text-center w-full font-bold">
        {data.text}
      </h1>
      <div className="flex-col flex mt-4 w-full">
        <div className="flex justify-center mb-6">
          {rates.map((r) => {
            return (
              <button
                key={r}
                className="mx-2"
                onClick={() => setMyAnswer(r)}
                disabled={sended || loading || data.step === "stop"}
              >
                <img
                  src={myAnswer >= r ? starFill : star}
                  className="w-12 h-12"
                  alt="star"
                />
              </button>
            );
          })}
        </div>
        {data.step === "start" && (
          <button
            onClick={sendAnswer}
            disabled={myAnswer === null || loading || sended}
            className={`${
              myAnswer === null || loading || sended
                ? "pointer-events-none opacity-50"
                : ""
            } text-white w-full text-center p-3 text-sm uppercase tracking-wider font-bold rounded-lg bg-indigo-600`}
          >
            {loading && "Отправляем ответ..."}
            {sended && "Ответ принят"}
            {!loading && !sended && "Подтвердить ответ"}
          </button>
        )}
      </div>
    </div>
  );
}
