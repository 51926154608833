import React, { useEffect, useState } from "react";
import {
  DownloadCloud,
  List,
  Loader,
  Plus,
  Users,
  Star,
  BarChart2,
  ShoppingCart,
  DollarSign,
} from "react-feather";
import firebase from "firebase";
import notie from "notie";

import Event from "./PlanComponents/Event";

export default function Plan({ roomInfo, now, onChange }) {
  const [plan, setPlan] = useState(roomInfo.plan || []);
  const [answersCounter, setAnswersCounter] = useState(0);
  const [usersCounter, setUsersCounter] = useState(0);
  const [correctAnswersCounter, setCorrectAnswersCounter] = useState(0);
  const [resultsIsLoading, setResultsIsLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [uploads, setUploads] = useState([]);

  const [openQuizWinnersCount, setOpenQuizWinnersCount] = useState(0);
  const [openQuizNotWinners, setOpenQuizNotWinners] = useState([]);

  const addEvent = () => {
    const maximumId = Math.max.apply(
      Math,
      plan.map((item) => item.id)
    );
    const newPlan = [
      ...plan,
      {
        id: maximumId + 1,
        type: "blank",
        inEdit: true,
        answers: [],
        correct: [],
      },
    ].map((item, index) => {
      item.sort = index;
      return item;
    });

    setPlan(newPlan);
  };

  const updateEvent = (data) => {
    const newPlan = plan.filter((i) => i.id !== data.id);
    newPlan.push(data);
    newPlan.sort((a, b) => (a.sort > b.sort ? 1 : -1));
    setPlan(newPlan);
  };

  const deleteEvent = (id) => {
    const newPlan = plan.filter((i) => i.id !== id);
    setPlan(newPlan);
  };

  const savePlan = () => {
    const db = firebase.firestore();
    const savedPlan = plan.map((p) => {
      p.inEdit = false;
      return {
        ...p,
        inEdit: false,
        correct: p.correct
          ? p.answers.map((a, index) => !!p.correct[index])
          : null,
      };
    });

    db.collection("rooms")
      .doc(roomInfo.id)
      .set(
        {
          plan: savedPlan,
        },
        { merge: true }
      )
      .then((docRef) => {
        notie.alert({
          type: "success",
          text: "Succesfully saved",
          position: "bottom",
        });
        onChange();
      })
      .catch((error) => {
        console.error("Error updating room: ", error);
      });
  };

  const moveEvent = (direction, eventId) => {
    const newPlan = plan.map((i, index) => {
      if (direction === "up") {
        if (i.id === eventId) {
          if (index > 0) {
            i.sort -= 1;
            return i;
          }
        } else {
          if (index < plan.length - 1 && plan[index + 1].id === eventId) {
            i.sort += 1;
            return i;
          }
        }
      }
      if (direction === "down") {
        if (i.id === eventId) {
          if (index < plan.length - 1) {
            i.sort += 1;
            return i;
          }
        } else {
          if (index > 0 && plan[index - 1].id === eventId) {
            i.sort -= 1;
            return i;
          }
        }
      }
      return i;
    });

    newPlan.sort((a, b) => (a.sort > b.sort ? 1 : -1));
    setPlan(newPlan);
  };

  const countUsers = () => {
    const db = firebase.firestore();
    db.collection("results")
      .where("room", "==", roomInfo.id)
      .get()
      .then((querySnapshot) => {
        setUsersCounter(querySnapshot.size);
        notie.alert({
          type: "success",
          text: `Load users`,
          position: "bottom",
        });
      });
  };

  const loadResults = () => {
    const db = firebase.firestore();
    setResultsIsLoading(true);
    setResults([]);

    const NUMBER_OF_WINNERS = 10;

    db.collection("results")
      .where("room", "==", roomInfo.id)
      .get()
      .then((querySnapshot) => {
        console.log(`Load results for ${querySnapshot.size} users`);
        const users = [];
        // Get all results and sort
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          let total = 0;
          Object.keys(data).forEach((key) => {
            if (key !== "room") {
              total += data[key];
            }
          });
          users.push({
            uid: doc.id,
            correct: total,
          });
        });
        users.sort((a, b) => (a.correct > b.correct ? -1 : 1));

        // Get first 10 winners
        const winnersIds = users.slice(0, NUMBER_OF_WINNERS).map((u) => u.uid);
        if (!winnersIds.length) {
          setResultsIsLoading(false);
          notie.alert({ type: "error", text: "Empty winners list" });
          return;
        }
        db.collection("users")
          .where("uid", "in", winnersIds)
          .get()
          .then((querySnapshot) => {
            console.log(`Load ${querySnapshot.size} winners`);
            const winners = [];

            querySnapshot.forEach((doc) => {
              const data = doc.data();
              winners.push({
                uid: doc.id,
                photo: data.photo,
                name: data.name,
                external_link: data.external_link,
                email: data.email,
                phone: data.phone,
              });
            });

            const newResults = users.map((u, index) => {
              if (index >= NUMBER_OF_WINNERS) {
                return u;
              }
              const prevPosition = results.findIndex((r) => r.uid === u.uid);
              return {
                ...u,
                ...winners.find((w) => w.uid === u.uid),
                change: prevPosition > -1 ? prevPosition - index : 0,
              };
            });

            console.log("Results", newResults);

            notie.alert({
              type: "success",
              text: `Load results`,
              position: "bottom",
            });

            setResultsIsLoading(false);
            setResults(newResults);
          });
      });
  };

  useEffect(() => {
    const db = firebase.firestore();
    const mappingDb = {
      poll: "polls",
      "words-cloud": "words-clouds",
      quiz: "quizes",
      upload: "uploads",
    };

    let subscriber = () => {};
    if (
      now &&
      now.room1 &&
      ["poll", "words-cloud", "quiz", "upload"].includes(now.room1.type)
    ) {
      // Listen questions
      subscriber = db
        .collection(mappingDb[now.room1.type])
        .where("question", "==", now.room1.id)
        .where("room", "==", roomInfo.id)
        .onSnapshot((querySnapshot) => {
          setAnswersCounter(querySnapshot.size);
        });

      // Unsubscrbe from answers
    }
    return () => {
      if (subscriber) subscriber();
    };
  }, [now]);

  const setSquidRope = (filteredEvent, step, room) => {
    const db = firebase.database();
    const store = firebase.firestore();
    const photos = [];
    store
      .collection("users")
      .where("room", "==", roomInfo.id)
      .limit(20)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          photos.push(data.photo);
        });
        db.ref(`/rooms/${roomInfo.id}/state/${room}`)
          .set({
            step,
            ...filteredEvent,
            photos,
          })
          .then((docRef) => {
            notie.alert({
              type: "success",
              text: "Event is set",
              position: "bottom",
            });
            onChange();
          })
          .catch((error) => {
            console.error("Error set event: ", error);
          });
      });
  };

  const increasePoints = (correctUsers, id, points) => {
    console.log("add points for event ", id);
    const db = firebase.firestore();
    // Only 500 in batch
    const chunk = 500;
    for (let i = 0, j = correctUsers.length; i < j; i += chunk) {
      const temporary = correctUsers.slice(i, i + chunk);
      const batch = db.batch();
      temporary.forEach((uid) => {
        const userRef = db.collection("results").doc(uid);
        batch.set(
          userRef,
          { [id]: points, room: roomInfo.id },
          { merge: true }
        );
      });
      batch.commit().then(() => {
        console.log(
          `Update from ${i} to ${i + chunk} users complete (+${points} points)`
        );
      });
    }
  };

  const setPoints = (step, event, room) => {
    const db = firebase.firestore();

    switch (event.type) {
      case "quiz":
        db.collection("quizes")
          .where("question", "==", event.id)
          .where("answer", "==", event.correct.indexOf(true))
          .where("room", "==", roomInfo.id)
          .get()
          .then((querySnapshot) => {
            const correctUsers = [];
            querySnapshot.forEach((doc) => {
              const data = doc.data();
              correctUsers.push(data.uid);
            });
            console.log(`Have ${correctUsers.length} winners`);
            setCorrectAnswersCounter(correctUsers.length);
            notie.alert({
              type: "success",
              text: `Have ${correctUsers.length} winners`,
              position: "bottom",
            });

            increasePoints(correctUsers, event.id, 10);
          });
        break;

      case "quiz-open":
        db.collection("quizes")
          .where("question", "==", event.id)
          .where("room", "==", roomInfo.id)
          .get()
          .then((querySnapshot) => {
            const answers = [];
            querySnapshot.forEach((doc) => {
              const data = doc.data();
              answers.push({
                uid: data.uid,
                answer: data.answer,
                question: data.question,
              });
            });

            const correct = event.opencorrect
              .split(",")
              .map((s) => s.toLowerCase().trim());
            const winners = [];
            const notWinners = [];

            answers.forEach((a) => {
              if (correct.includes(a.answer.toLowerCase())) {
                winners.push(a.uid);
              } else {
                notWinners.push(a);
              }
            });

            setOpenQuizWinnersCount(winners.length);
            setOpenQuizNotWinners(notWinners);

            increasePoints(winners, event.id, 10);
          });
        break;

      case "poll":
      case "rate":
        db.collection("polls")
          .where("question", "==", event.id)
          .get()
          .then((querySnapshot) => {
            const correctUsers = [];
            querySnapshot.forEach((doc) => {
              const data = doc.data();
              correctUsers.push(data.uid);
            });
            console.log(`Have ${correctUsers.length} winners`);
            setCorrectAnswersCounter(correctUsers.length);
            notie.alert({
              type: "success",
              text: `Have ${correctUsers.length} winners`,
              position: "bottom",
            });

            increasePoints(correctUsers, event.id, 10);
          });
        break;

      case "words-cloud":
        db.collection("words-clouds")
          .where("question", "==", event.id)
          .get()
          .then((querySnapshot) => {
            const correctUsers = [];
            querySnapshot.forEach((doc) => {
              const data = doc.data();
              correctUsers.push(data.uid);
            });
            console.log(correctUsers);
            console.log(`Have ${correctUsers.length} winners`);
            setCorrectAnswersCounter(correctUsers.length);
            notie.alert({
              type: "success",
              text: `Have ${correctUsers.length} winners`,
              position: "bottom",
            });

            increasePoints(correctUsers, event.id, 10);
          });
        break;

      case "squid-rope":
        db.collection("speed")
          .where("question", "==", event.id)
          .get()
          .then((querySnapshot) => {
            let blueTeam = 0,
              redTeam = 0;
            const blueUsers = [];
            const redUsers = [];
            querySnapshot.forEach((doc) => {
              const data = doc.data();
              if (data.team === "blue") {
                blueUsers.push(data.uid);
                blueTeam += data.count;
              }
              if (data.team === "red") {
                redUsers.push(data.uid);
                redTeam += data.count;
              }
            });

            const winnersUsers = blueTeam > redTeam ? blueUsers : redUsers;
            const losersUsers = blueTeam > redTeam ? redUsers : blueUsers;
            setCorrectAnswersCounter(winnersUsers.length);
            notie.alert({
              type: "success",
              text: `Have ${winnersUsers.length} winners and ${losersUsers.length} losers`,
              position: "bottom",
            });

            increasePoints(winnersUsers, event.id, 30);
            // increasePoints(losersUsers, event.id, 2);
          });

        break;

      case "squid-run":
        db.collection("speed")
          .where("question", "==", event.id)
          .get()
          .then((querySnapshot) => {
            const winners = [];
            querySnapshot.forEach((doc) => {
              const data = doc.data();
              if (data.live && data.count === 200) {
                winners.push(data.uid);
              }
            });

            setCorrectAnswersCounter(winners.length);
            notie.alert({
              type: "success",
              text: `Have ${winners.length} winners`,
              position: "bottom",
            });

            increasePoints(winners, event.id, 30);
          });

        break;

      case "drill":
      case "race":
        db.collection("speed")
          .where("question", "==", event.id)
          .get()
          .then((querySnapshot) => {
            const winners = [];
            querySnapshot.forEach((doc) => {
              const data = doc.data();
              if (data.winAt) {
                winners.push({ uid: data.uid, at: data.winAt.seconds });
              }
            });

            winners
              .sort((a, b) => a.at - b.at)
              .forEach((c, index) => {
                if (index < 10) {
                  const sum = 100 - index * 10;
                  console.log(c);
                  increasePoints([c.uid], event.id, sum);
                }
              });

            notie.alert({
              type: "success",
              text: `Set for 10 winners`,
              position: "bottom",
            });
          });

        break;

      default:
        break;
    }
  };

  const getUploads = (event, room) => {
    const db = firebase.firestore();
    db.collection("users")
      .where("room", "==", roomInfo.id)
      .get()
      .then((querySnapshot) => {
        const users = [];
        querySnapshot.forEach((u) => {
          users.push(u.data());
        });
        db.collection("uploads")
          .where("question", "==", event.id)
          .where("room", "==", roomInfo.id)
          .get()
          .then((snapshot) => {
            const newUploads = [];
            snapshot.forEach((d) => {
              const data = d.data();
              newUploads.push({
                ...data,
                user: users.find((u) => u.uid === data.uid),
                createdAt: data.createdAt.seconds,
              });
            });
            newUploads.sort((a, b) => a.createdAt - b.createdAt);
            setUploads(newUploads);
            notie.alert({
              type: "success",
              text: `Have ${newUploads.length} uploads`,
              position: "bottom",
            });
          });
      });
  };

  const setEvent = (step, event, room = "room1") => {
    setUploads([]);
    setCorrectAnswersCounter(0);
    setOpenQuizNotWinners([]);
    setOpenQuizWinnersCount(0);

    if (["points", "get-uploads"].includes(step)) {
      switch (step) {
        case "points":
          setPoints(step, event, room);
          break;
        case "get-uploads":
          getUploads(event, room);
          break;

        default:
          break;
      }
    } else {
      setAnswersCounter(0);
      const allowedKeys = ["id", "text", "subtext", "type", "answers"];
      if (step === "correct") {
        allowedKeys.push("correct");
        allowedKeys.push("opencorrect");
      }

      const filteredEvent = Object.keys(event)
        .filter((key) => allowedKeys.includes(key))
        .reduce((obj, key) => {
          obj[key] = event[key];
          return obj;
        }, {});

      if (filteredEvent.type === "rate") {
        filteredEvent.answers = {
          1: 1,
          2: 2,
          3: 3,
          4: 4,
          5: 5,
        };
      }

      const db = firebase.database();

      if (filteredEvent.type === "squid-rope") {
        setSquidRope(filteredEvent, step, room);
        return;
      }

      db.ref(`/rooms/${roomInfo.id}/state/${room}`)
        .set({
          step,
          ...filteredEvent,
        })
        .then((docRef) => {
          notie.alert({
            type: "success",
            text: "Event is set",
            position: "bottom",
          });
          onChange();
        })
        .catch((error) => {
          console.error("Error set event: ", error);
        });

      const isPoll = filteredEvent.type === "poll" && step === "start";
      const isWordsCloud =
        filteredEvent.type === "words-cloud" && step === "start";
      const isRate = filteredEvent.type === "rate" && step === "start";

      if (isPoll || isWordsCloud || isRate) {
        console.log("clear polls and words clouds");
        db.ref(`/rooms/${roomInfo.id}/data/polls/`)
          .set({
            clear: true,
          })
          .then(() => {
            console.log("clear success");
          })
          .catch((error) => {
            console.error("Error clear polls event: ", error);
          });
      }
    }
  };

  const setParty = () => {
    const db = firebase.database();
    db.ref(`/rooms/${roomInfo.id}/state/party`)
      .set(!now.party)
      .then((docRef) => {
        notie.alert({
          type: "success",
          text: "Party is set",
          position: "bottom",
        });
        onChange();
      })
      .catch((error) => {
        console.error("Error set event: ", error);
      });
  };

  const openPoll = () => {
    const db = firebase.database();
    db.ref(`/rooms/${roomInfo.id}/state/pollOpen`)
      .set(!now.pollOpen)
      .then((docRef) => {
        notie.alert({
          type: "success",
          text: "Poll open is set",
          position: "bottom",
        });
        onChange();
      })
      .catch((error) => {
        console.error("Error set event: ", error);
      });
  };

  const openShop = () => {
    const db = firebase.database();
    db.ref(`/rooms/${roomInfo.id}/state/shopOpen`)
      .set(!now.shopOpen)
      .then((docRef) => {
        notie.alert({
          type: "success",
          text: "Shop open is set",
          position: "bottom",
        });
        onChange();
      })
      .catch((error) => {
        console.error("Error set event: ", error);
      });
  };

  const showResults = (showFrom = 11, room = "room1") => {
    const db = firebase.database();
    db.ref(`/rooms/${roomInfo.id}/state/${room}`)
      .set({
        id: "results",
        type: "results",
        step: "results",
        showFrom,
        results,
      })
      .then((docRef) => {
        notie.alert({
          type: "success",
          text: "Results is set",
          position: "bottom",
        });
        onChange();
      })
      .catch((error) => {
        console.error("Error set event: ", error);
      });
  };

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 md:col-span-8 mb-5 bg-white rounded-xl p-4">
        <div className="mb-3">
          <div className="sticky top-0 bg-indigo-600 p-3 -mx-4 flex justify-between sticky-buttons items-center relative z-10">
            <h5 className="text-white">{roomInfo.id}</h5>
            <div className="flex flex-col">
              <div className="flex gap-3">
                <button
                  onClick={countUsers}
                  className="border text-white rounded-lg px-2 py-1 text-sm flex gap-2 items-center"
                >
                  <Users size={16} /> {usersCounter}
                </button>
                <button
                  onClick={setParty}
                  className={`${
                    now && now.party ? "bg-white text-indigo-600" : ""
                  } border text-white rounded-lg px-2 py-1 text-sm flex gap-2 items-center`}
                >
                  {now && now.party ? (
                    <Star className="animate-bounce" size={16} />
                  ) : (
                    <Star size={16} />
                  )}
                  Party
                </button>
                <button
                  onClick={openPoll}
                  className={`${
                    now && now.pollOpen ? "bg-white text-indigo-600" : ""
                  } border text-white rounded-lg px-2 py-1 text-sm flex gap-2 items-center`}
                >
                  {now && now.pollOpen ? (
                    <BarChart2 size={16} />
                  ) : (
                    <BarChart2 size={16} />
                  )}
                  Poll
                </button>
                <button
                  onClick={openShop}
                  className={`${
                    now && now.shopOpen ? "bg-white text-indigo-600" : ""
                  } border text-white rounded-lg px-2 py-1 text-sm flex gap-2 items-center`}
                >
                  {now && now.shopOpen ? (
                    <ShoppingCart size={16} />
                  ) : (
                    <ShoppingCart size={16} />
                  )}
                  Shop
                </button>
                <button
                  onClick={() => setEvent("start", { type: "chat" })}
                  className="border text-white rounded-lg px-2 py-1 text-sm"
                >
                  Chat
                </button>
                <button
                  onClick={() => setEvent("start", { type: "blank" })}
                  className="border text-white rounded-lg px-2 py-1 text-sm"
                >
                  Blank
                </button>
              </div>
              <div className="flex mt-2 gap-3">
                <button
                  onClick={loadResults}
                  className="border text-white rounded-lg px-2 py-1 text-sm flex gap-2 items-center"
                >
                  {resultsIsLoading ? (
                    <Loader className="animate-spin" size={16} />
                  ) : (
                    <DownloadCloud size={16} />
                  )}
                  Get results
                </button>
                <button
                  onClick={() => showResults()}
                  disabled={!results.length}
                  className={`${
                    !results.length ? "opacity-50 pointer-events-none" : ""
                  } border text-white rounded-lg px-2 py-1 text-sm flex gap-2 items-center`}
                >
                  <List size={16} /> Results
                </button>
                {[10, 9, 8, 7, 6, 5, 4, 3, 2, 1].map((i) => {
                  return (
                    <button
                      key={i}
                      onClick={() => showResults(i)}
                      disabled={!results.length}
                      className={`${
                        !results.length ? "opacity-50 pointer-events-none" : ""
                      } border text-white rounded-lg px-2 py-1 text-sm flex items-center`}
                    >
                      {i}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          {plan.map((i) => (
            <Event
              key={i.id}
              data={i}
              now={now.room1}
              now2={now.room2}
              stats={{
                answers: answersCounter,
                correctAnswers: correctAnswersCounter,
                people: usersCounter,
              }}
              onChange={(data) => updateEvent(data)}
              onDelete={() => deleteEvent(i.id)}
              onMove={(dir) => moveEvent(dir, i.id)}
              onSet={(step, room = "room1") => setEvent(step, i, room)}
            />
          ))}
          <button
            className="px-3 py-2 border border-indigo-400 text-indigo-600 mt-3 flex rounded-lg text-sm items-center"
            onClick={addEvent}
          >
            Add item
            <Plus size={19} className="ml-2" />
          </button>
        </div>
        <button
          className="px-3 py-2 bg-indigo-600 text-white mt-3 flex rounded-lg text-sm items-center"
          onClick={savePlan}
        >
          Save plan
        </button>
      </div>
      <div className="col-span-12 md:col-span-4">
        <div className="sticky top-0">
          <div
            id="player"
            className="ratio-16x9 bg-black rounded-lg overflow-hidden mb-3"
          >
            {now && now.stream && (
              <iframe
                title="video player"
                src={now.stream}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            )}
          </div>
          {(!!openQuizNotWinners.length || openQuizWinnersCount) && (
            <div className="bg-white text-black p-2 rounded-xl text-xs overflow-auto flex flex-col gap-2 mb-2 divide-y">
              <p className="text-green-500 text-center font-bold">
                Correct: {openQuizNotWinners.length}
              </p>
              {openQuizNotWinners.map((r, index) => {
                return (
                  <div key={r.uid} className="flex py-2 items-center">
                    <p className="mr-2 flex-shrink-0">#{index + 1}</p>
                    <p className="w-full">{r.answer}</p>
                    <button
                      onClick={() => increasePoints([r.uid], r.question, 10)}
                      className="w-6 h-6 rounded-full bg-indigo-600 flex-shrink-0 text-white ml-auto flex items-center justify-center"
                    >
                      <DollarSign size={16} />
                    </button>
                  </div>
                );
              })}
            </div>
          )}
          {!!uploads.length && (
            <div className="bg-white text-black p-2 rounded-xl text-xs overflow-auto flex flex-col gap-2 mb-2 divide-y">
              {uploads.map((r, index) => {
                console.log(r);
                return (
                  <div key={r.uid} className="flex py-2">
                    <div className="flex flex-col mr-2">
                      <div className="flex">
                        <p className="mr-2">#{index + 1}</p>
                        <p className="mr-auto">
                          {r.user
                            ? `${r.user.name} — ${r.user.team} (${r.user.email}, ${r.user.phone})`
                            : r.uid}
                        </p>
                      </div>
                      <a
                        href={r.url}
                        target="_blank"
                        rel="noreferrer"
                        className="text-sm text-yellow-500"
                      >
                        {r.fileName}
                      </a>
                    </div>
                    <button
                      onClick={() => increasePoints([r.uid], r.question, 10)}
                      className="w-6 h-6 rounded-full bg-indigo-600 flex-shrink-0 text-white ml-auto flex items-center justify-center"
                    >
                      <DollarSign size={16} />
                    </button>
                  </div>
                );
              })}
            </div>
          )}
          {!!results.length && (
            <pre className="bg-black text-white p-2 rounded-xl text-xs overflow-auto flex flex-col gap-2 mb-2">
              {results.map((r, index) => {
                return (
                  <div key={index} className="flex">
                    <p className="mr-2">#{index + 1}</p>
                    <p className="mr-auto">{r.name}</p>
                    <p className="text-green-400">{r.correct}</p>
                  </div>
                );
              })}
            </pre>
          )}
          <pre className="bg-black text-white p-2 rounded-xl text-xs overflow-auto">
            <small>{JSON.stringify(now ? now.room1 : {}, null, " ")}</small>
          </pre>
          <pre className="bg-black text-white p-2 rounded-xl text-xs overflow-auto mt-2">
            <small>{JSON.stringify(now ? now.room2 : {}, null, " ")}</small>
          </pre>
          <pre className="bg-black text-white p-2 rounded-xl text-xs overflow-auto mt-2">
            <small>{JSON.stringify(now ? now.stream : {}, null, " ")}</small>
          </pre>
        </div>
      </div>
    </div>
  );
}
