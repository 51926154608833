import React from "react";
import { ArrowDown, ArrowUp } from "react-feather";

export default function Results({ data, userInfo, obs }) {
  const countForm = (number, titles) => {
    number = Math.abs(number);
    if (Number.isInteger(number)) {
      const cases = [2, 0, 1, 1, 1, 2];
      return titles[
        number % 100 > 4 && number % 100 < 20
          ? 2
          : cases[number % 10 < 5 ? number % 10 : 5]
      ];
    }
    return titles[1];
  };

  const myPosition = data.results.findIndex((u) => u.uid === userInfo.uid);
  let myChange = 0;
  if (myPosition > -1) {
    myChange =
      data.results[myPosition].change > 0
        ? "+" + data.results[myPosition].change
        : data.results[myPosition].change;
  }

  if (obs) {
    return (
      <div className="w-full flex flex-col items-center gap-4 justify-center p-6 text-black">
        <h1 className="font-bold text-5xl w-full font-bold mb-10 border-b pb-5">
          Таблица победителей
        </h1>
        {data.results.slice(0, 10).map((u, index) => {
          return (
            <div key={u.uid} className="flex gap-6 w-full items-center">
              <p className="font-bold text-3xl text-indigo-700 w-6 text-center">
                {index + 1}
              </p>
              <div
                style={{
                  backgroundImage: `url(${u.photo})`,
                  animationDelay: `.${index}s`,
                }}
                className={`${
                  index < 6 ? "animate-bounce" : ""
                } bg-cover w-16 h-16 rounded-full bg-black`}
              />
              <p className="text-4xl font-medium">{u.name}</p>
              {u.change !== 0 && (
                <p
                  className={`${
                    u.change > 0 ? "text-green-500" : "text-red-500"
                  } text-3xl flex items-center`}
                >
                  {u.change > 0 ? (
                    <ArrowUp size={32} />
                  ) : (
                    <ArrowDown size={32} />
                  )}
                  {Math.abs(u.change)}
                </p>
              )}
              <p className="text-3xl ml-auto text-indigo-700 font-bold">
                {u.correct}
              </p>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className="h-full flex flex-col items-center justify-center p-6">
      <h1 className="text-prosto text-xl font-bold">Таблица победителей</h1>
      {myPosition > -1 ? (
        <>
          {myPosition + 1 < data.showFrom ? null : (
            <div className="text-sm border-blue-400 text-blue-500 text-center w-full border rounded-lg p-3 mt-4">
              Вы на {myPosition + 1} месте и у вас{" "}
              {data.results[myPosition].correct}{" "}
              {countForm(data.results[myPosition].correct, [
                "балл",
                "балла",
                "баллов",
              ])}
              .{" "}
              {myChange !== 0 &&
                myChange !== undefined &&
                `${myChange} ${countForm(parseInt(myChange), [
                  "позиция",
                  "позиции",
                  "позиций",
                ])} в рейтинге.`}
            </div>
          )}
        </>
      ) : (
        <div className="text-sm border-indigo-400 text-blue-500 text-center w-full border p-3 mt-4">
          Вы на {data.results.length + 1} месте. Ответьте верно на любой вопрос,
          чтобы попасть в рейтинг.
        </div>
      )}
      <div className="flex flex-col w-full divide-y mt-4">
        {data.results.slice(0, 10).map((u, index) => {
          // Hidden positions
          if (index + 1 < data.showFrom) {
            return (
              <div key={u.uid} className="flex gap-2 py-3 items-center">
                <p className="font-bold text-shadow text-decorate text-lg text-prosto text-blue-500 w-6 text-center">
                  {index + 1}
                </p>
                <div className="bg-cover w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center">
                  <span className="text-sm font-bold animate-pulse">?</span>
                </div>
                <p className="text-sm font-bold text-gray-200 bg-gray-200">
                  {u.name.replace(/./g, "#")}
                </p>
                {u.change !== 0 && (
                  <p
                    className={`${
                      u.change > 0 ? "text-green-500" : "text-red-500"
                    } text-xs flex items-center`}
                  >
                    {u.change > 0 ? (
                      <ArrowUp size={12} />
                    ) : (
                      <ArrowDown size={12} />
                    )}
                    {Math.abs(u.change)}
                  </p>
                )}
                <p className="text-sm ml-auto text-green-400 font-medium">
                  {u.correct}
                </p>
              </div>
            );
          }
          // Showed positions
          return (
            <div key={u.uid} className="flex gap-2 py-3 items-center">
              <p
                className={`${
                  index < 6 ? "text-blue-500" : "text-gray-300"
                } font-bold text-shadow text-decorate text-lg w-6 text-center`}
              >
                {index + 1}
              </p>
              <div
                style={{
                  backgroundImage: `url(${u.photo})`,
                  animationDelay: `.${index}s`,
                }}
                className={`${
                  index < 6 ? "animate-bounce" : ""
                } bg-cover w-8 h-8 rounded-full bg-black`}
              />
              <p className="text-sm font-bold">{u.name}</p>
              {u.change !== 0 && (
                <p
                  className={`${
                    u.change > 0 ? "text-green-500" : "text-red-500"
                  } text-xs flex items-center`}
                >
                  {u.change > 0 ? (
                    <ArrowUp size={12} />
                  ) : (
                    <ArrowDown size={12} />
                  )}
                  {Math.abs(u.change)}
                </p>
              )}
              <p className="text-sm ml-auto text-green-400 font-medium">
                {u.correct}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
