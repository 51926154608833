import React from "react";
import {
  ArrowDown,
  ArrowUp,
  Edit2,
  Play,
  Square,
  Trash,
  Check,
  CheckCircle,
  DollarSign,
  BarChart2,
  DownloadCloud,
  Eye,
  EyeOff,
} from "react-feather";

export default function Event({
  data,
  onChange,
  onDelete,
  onMove,
  onSet,
  now,
  now2,
  stats,
}) {
  const types = [
    "chat",
    "blank",
    "speaker-question",
    "poll",
    "words-cloud",
    "quiz",
    "quiz-open",
    "emoji",
    "song",
    "rate",
    "squid-rope",
    "squid-run",
    "tree",
    "drill",
    "upload",
    "race",
  ];

  const getIcon = (type) => {
    switch (type) {
      case "chat":
        return "💬";
      case "blank":
        return "⭐️";
      case "speaker-question":
        return "✋";
      case "poll":
        return "📊";
      case "emoji":
        return "😍";
      case "quiz":
        return "🎁";
      case "quiz-open":
        return "✍️";
      case "words-cloud":
        return "☁️";
      case "song":
        return "🎶";
      case "rate":
        return "5️⃣";
      case "upload":
        return "📸";
      case "squid-rope":
        return "🦑";
      case "squid-run":
        return "🏃‍♂️";
      case "tree":
        return "🎄";
      case "race":
        return "🏎";
      case "drill":
        return "🛢";
      default:
        return "?";
    }
  };

  const editEvent = (key, value, options) => {
    if (["answers", "correct"].includes(key)) {
      data[key][options.index] = value;
    } else {
      data[key] = value;
    }
    onChange(data);
  };

  const deleteEvent = () => {
    // if (window.confirm("Delete item of plan?")) onDelete();
    onDelete();
  };

  if (data.inEdit) {
    return (
      <div className="border rounded-lg mt-3 p-2">
        <table className="table mb-0 align-middle">
          <tbody>
            <tr>
              <td>
                <div className="flex flex-col gap-2 text-gray-400">
                  <button
                    onClick={() => onMove("up")}
                    className="border rounded-md p-1"
                  >
                    <ArrowUp size={18} />
                  </button>
                  <button
                    onClick={() => onMove("down")}
                    className="border rounded-md p-1"
                  >
                    <ArrowDown size={18} />
                  </button>
                </div>
              </td>
              <td>
                <small className="mx-2 text-gray-400 text-xs text-nowrap">
                  #{data.id}
                </small>
              </td>
              <td style={{ minWidth: "170px" }}>
                <select
                  className="border rounded-md p-2 py-1 text-sm"
                  value={data.type}
                  onChange={(e) => editEvent("type", e.target.value)}
                >
                  {types.map((t) => (
                    <option value={t} key={t}>
                      {t}
                    </option>
                  ))}
                </select>
              </td>
              <td className="w-full">
                {[
                  "blank",
                  "speaker-question",
                  "poll",
                  "words-cloud",
                  "emoji",
                  "quiz",
                  "quiz-open",
                  "rate",
                  "upload",
                ].includes(data.type) && (
                  <input
                    type="text"
                    placeholder="Title of block"
                    className="border p-2 py-1 rounded-md text-sm w-full"
                    value={data.text || ""}
                    onChange={(e) => editEvent("text", e.target.value)}
                  />
                )}
                {["chat"].includes(data.type) && (
                  <>
                    <input
                      type="text"
                      placeholder="Title of button"
                      className="border p-2 py-1 rounded-md text-sm w-full"
                      value={data.text || ""}
                      onChange={(e) => editEvent("text", e.target.value)}
                    />
                    <input
                      type="text"
                      placeholder="URL of button"
                      className="border p-2 py-1 rounded-md text-sm w-full mt-1"
                      value={data.subtext || ""}
                      onChange={(e) => editEvent("subtext", e.target.value)}
                    />
                  </>
                )}
                {["blank", "upload"].includes(data.type) && (
                  <input
                    type="text"
                    placeholder="Subtitle of block"
                    className="border p-2 py-1 rounded-md text-sm w-full mt-1"
                    value={data.subtext || ""}
                    onChange={(e) => editEvent("subtext", e.target.value)}
                  />
                )}
                {["poll"].includes(data.type) && (
                  <div className="flex mt-2 flex-col gap-1">
                    {[1, 2, 3, 4, 5, 6, 7, 8].map((pollIndex) => {
                      return (
                        <input
                          type="text"
                          key={pollIndex}
                          placeholder={`Poll answer #${pollIndex}`}
                          className="border p-2 py-1 rounded-md text-sm w-full mt-1"
                          value={data.answers[pollIndex - 1] || ""}
                          onChange={(e) =>
                            editEvent("answers", e.target.value, {
                              index: pollIndex - 1,
                            })
                          }
                        />
                      );
                    })}
                  </div>
                )}
                {["quiz-open"].includes(data.type) && (
                  <input
                    type="text"
                    placeholder="Correct answers, split by ,"
                    className="border p-2 py-1 rounded-md text-sm w-full mt-1"
                    value={data.opencorrect || ""}
                    onChange={(e) => editEvent("opencorrect", e.target.value)}
                  />
                )}
                {["quiz"].includes(data.type) && (
                  <div className="flex mt-2 flex-col gap-1">
                    {[1, 2, 3, 4, 5, 6, 7, 8].map((pollIndex) => {
                      return (
                        <div className="flex items-center">
                          <button
                            onClick={() => {
                              editEvent(
                                "correct",
                                !(data.correct[pollIndex - 1] === true),
                                {
                                  index: pollIndex - 1,
                                }
                              );
                            }}
                            className={`${
                              data.correct[pollIndex - 1] === true
                                ? "bg-green-500 border-green-500"
                                : "border-gray-200"
                            } w-4 h-4 border rounded flex-shrink-0 flex items-center justify-center text-white mt-1 mr-2`}
                          >
                            <Check />
                          </button>
                          <input
                            type="text"
                            key={pollIndex}
                            placeholder={`Quiz answer #${pollIndex}`}
                            className="border p-2 py-1 rounded-md text-sm w-full mt-1"
                            value={data.answers[pollIndex - 1] || ""}
                            onChange={(e) =>
                              editEvent("answers", e.target.value, {
                                index: pollIndex - 1,
                              })
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </td>
              <td>
                <div className="flex gap-2 ml-2">
                  <button
                    className="border border-red-300 rounded-md p-2 text-red-600"
                    onClick={deleteEvent}
                  >
                    <Trash size={18} />
                  </button>
                  <button
                    className="border rounded-md p-2 border-indigo-400 text-indigo-600"
                    onClick={() => editEvent("inEdit", false)}
                  >
                    <Edit2 size={18} />
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  const currentAction = now && now.id === data.id ? now.step : null;
  const currentAction2 = now2 && now2.id === data.id ? now2.step : null;

  return (
    <div
      className={`border rounded-lg mt-3 p-2 ${
        now.id === data.id ? "border-indigo-400" : ""
      }`}
    >
      <table className="table mb-0 align-middle">
        <tbody>
          <tr>
            <td>
              <div className="flex flex-col gap-2 text-gray-400">
                <button
                  onClick={() => onMove("up")}
                  className="border rounded-md p-1"
                >
                  <ArrowUp size={18} />
                </button>
                <button
                  onClick={() => onMove("down")}
                  className="border rounded-md p-1"
                >
                  <ArrowDown size={18} />
                </button>
              </div>
            </td>
            <td>
              <small className="mx-2 text-gray-400 text-xs text-nowrap">
                #{data.id}
              </small>
            </td>
            <td>
              <div className="pr-2">{getIcon(data.type)}</div>
            </td>
            <td className="w-full pl-2">
              {data.text && <h5 className="mb-1 text-sm">{data.text}</h5>}
              {data.subtext && <p className="text-xs">{data.subtext}</p>}
              {data.correct && !!data.correct.length && (
                <p className="text-xs text-green-500">
                  {data.answers[data.correct.indexOf(true)]}
                </p>
              )}
              {data.opencorrect && (
                <p className="text-xs text-green-500">{data.opencorrect}</p>
              )}
            </td>
            <td>
              <div className="flex gap-4">
                <div className="flex flex-col gap-1">
                  <div className="flex gap-2">
                    <button
                      onClick={() => onSet("start")}
                      className={`border rounded-md p-2 ${
                        currentAction === "start"
                          ? "text-white bg-indigo-600 border-indigo-600"
                          : "text-indigo-600"
                      }`}
                    >
                      <Play size={18} />
                      {["speaker-question", "blank"].includes(data.type) && "1"}
                    </button>
                    {["speaker-question", "blank"].includes(data.type) && (
                      <button
                        onClick={() => onSet("start", "room2")}
                        className={`border rounded-md p-2 ${
                          currentAction2 === "start"
                            ? "text-white bg-indigo-600 border-indigo-600"
                            : "text-indigo-600"
                        }`}
                      >
                        <Play size={18} />2
                      </button>
                    )}
                    {![
                      "squid-rope",
                      "squid-run",
                      "tree",
                      "drill",
                      "race",
                    ].includes(data.type) && (
                      <button
                        onClick={() => onSet("stop")}
                        className={`border rounded-md p-2 ${
                          currentAction === "stop"
                            ? "text-white bg-indigo-600 border-indigo-600"
                            : "text-indigo-600"
                        }`}
                      >
                        <Square size={18} />
                      </button>
                    )}

                    {data.type === "tree" && (
                      <>
                        <button
                          onClick={() => onSet("speed-1")}
                          className={`border rounded-md p-2 ${
                            currentAction === "speed-1"
                              ? "text-white bg-indigo-600 border-indigo-600"
                              : "text-indigo-600"
                          }`}
                        >
                          1
                        </button>
                        <button
                          onClick={() => onSet("speed-2")}
                          className={`border rounded-md p-2 ${
                            currentAction === "speed-2"
                              ? "text-white bg-indigo-600 border-indigo-600"
                              : "text-indigo-600"
                          }`}
                        >
                          2
                        </button>
                        <button
                          onClick={() => onSet("speed-3")}
                          className={`border rounded-md p-2 ${
                            currentAction === "speed-3"
                              ? "text-white bg-indigo-600 border-indigo-600"
                              : "text-indigo-600"
                          }`}
                        >
                          3
                        </button>
                        <button
                          onClick={() => onSet("speed-4")}
                          className={`border rounded-md p-2 ${
                            currentAction === "speed-4"
                              ? "text-white bg-indigo-600 border-indigo-600"
                              : "text-indigo-600"
                          }`}
                        >
                          4
                        </button>
                        <button
                          onClick={() => onSet("speed-5")}
                          className={`border rounded-md p-2 ${
                            currentAction === "speed-5"
                              ? "text-white bg-indigo-600 border-indigo-600"
                              : "text-indigo-600"
                          }`}
                        >
                          5
                        </button>
                      </>
                    )}

                    {data.type === "squid-rope" && (
                      <>
                        <button
                          onClick={() => onSet("speed-1")}
                          className={`border rounded-md p-2 ${
                            currentAction === "speed-1"
                              ? "text-white bg-indigo-600 border-indigo-600"
                              : "text-indigo-600"
                          }`}
                        >
                          1
                        </button>
                        <button
                          onClick={() => onSet("speed-2")}
                          className={`border rounded-md p-2 ${
                            currentAction === "speed-2"
                              ? "text-white bg-indigo-600 border-indigo-600"
                              : "text-indigo-600"
                          }`}
                        >
                          2
                        </button>
                        <button
                          onClick={() => onSet("speed-3")}
                          className={`border rounded-md p-2 ${
                            currentAction === "speed-3"
                              ? "text-white bg-indigo-600 border-indigo-600"
                              : "text-indigo-600"
                          }`}
                        >
                          3
                        </button>
                        <button
                          onClick={() => onSet("squid-results")}
                          className={`border rounded-md p-2 ${
                            currentAction === "squid-results"
                              ? "text-white bg-indigo-600 border-indigo-600"
                              : "text-indigo-600"
                          }`}
                        >
                          <BarChart2 size={18} />
                        </button>
                      </>
                    )}

                    {data.type === "upload" && (
                      <button
                        onClick={() => onSet("get-uploads")}
                        className={`border rounded-md p-2 ${
                          currentAction === "correct"
                            ? "text-white bg-indigo-600 border-indigo-600"
                            : "text-indigo-600"
                        }`}
                      >
                        <DownloadCloud size={18} />
                      </button>
                    )}

                    {data.type === "squid-run" && (
                      <>
                        <button
                          onClick={() => onSet("eyes-open")}
                          className={`border rounded-md p-2 ${
                            currentAction === "eyes-open"
                              ? "text-white bg-indigo-600 border-indigo-600"
                              : "text-indigo-600"
                          }`}
                        >
                          <Eye size={18} />
                        </button>
                        <button
                          onClick={() => onSet("eyes-close")}
                          className={`border rounded-md p-2 ${
                            currentAction === "eyes-close"
                              ? "text-white bg-indigo-600 border-indigo-600"
                              : "text-indigo-600"
                          }`}
                        >
                          <EyeOff size={18} />
                        </button>

                        <button
                          onClick={() => onSet("squid-results")}
                          className={`border rounded-md p-2 ${
                            currentAction === "squid-results"
                              ? "text-white bg-indigo-600 border-indigo-600"
                              : "text-indigo-600"
                          }`}
                        >
                          <BarChart2 size={18} />
                        </button>
                      </>
                    )}

                    {["drill", "race"].includes(data.type) && (
                      <button
                        onClick={() => onSet("race-results")}
                        className={`border rounded-md p-2 ${
                          currentAction === "race-results"
                            ? "text-white bg-indigo-600 border-indigo-600"
                            : "text-indigo-600"
                        }`}
                      >
                        <BarChart2 size={18} />
                      </button>
                    )}

                    {["quiz", "quiz-open"].includes(data.type) && (
                      <button
                        onClick={() => onSet("correct")}
                        className={`border rounded-md p-2 ${
                          currentAction === "correct"
                            ? "text-white bg-indigo-600 border-indigo-600"
                            : "text-indigo-600"
                        }`}
                      >
                        <CheckCircle size={18} />
                      </button>
                    )}

                    {[
                      "rate",
                      "poll",
                      "words-cloud",
                      "quiz",
                      "quiz-open",
                      "squid-rope",
                      "squid-run",
                      "drill",
                      "race",
                    ].includes(data.type) && (
                      <button
                        onClick={() => onSet("points")}
                        className="border rounded-md p-2 text-indigo-600"
                      >
                        <DollarSign size={18} />
                      </button>
                    )}
                  </div>
                  {now.id === data.id &&
                    [
                      "rate",
                      "poll",
                      "words-cloud",
                      "quiz",
                      "quiz-open",
                      "emoji",
                      "upload",
                    ].includes(data.type) && (
                      <div className="flex gap-2 items-center">
                        <div className="w-full rounded bg-gray-200 h-1 relative overflow-hidden">
                          <div
                            style={{
                              transform: `scaleX(${
                                stats.answers / stats.people
                              })`,
                            }}
                            className="absolute w-full transition left-0 h-full transform-gpu bg-indigo-600 origin-left"
                          ></div>
                          {stats.correctAnswers && (
                            <div
                              style={{
                                transform: `scaleX(${
                                  stats.correctAnswers / stats.people
                                })`,
                              }}
                              className="absolute transition w-full left-0 h-full transform-gpu bg-green-500 origin-left"
                            ></div>
                          )}
                        </div>
                        <p className="text-sm text-indigo-600 whitespace-nowrap">
                          {stats.correctAnswers > 0 && (
                            <span className="text-green-500">
                              {stats.correctAnswers}/
                            </span>
                          )}
                          {stats.answers}/{stats.people}
                        </p>
                      </div>
                    )}
                </div>
                <button
                  className="border rounded-md p-2 text-indigo-600"
                  onClick={() => editEvent("inEdit", true)}
                >
                  <Edit2 size={18} />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
