import React, { useState, useEffect } from "react";
import firebase from "firebase";

export default function Quiz({ userInfo, data, room, obs }) {
  const [myAnswer, setMyAnswer] = useState(null);
  const [sended, setSended] = useState(false);
  const [loading, setLoading] = useState(false);
  const [remainTime, setRemainTime] = useState(null);

  const db = firebase.firestore();

  useEffect(() => {
    setSended(false);
    setMyAnswer(null);
    setLoading(false);
    setRemainTime(null);
  }, [data.id]);

  useEffect(() => {
    let timer;
    if (data.step === "start") {
      if (remainTime === null) setRemainTime(20);
      timer = setTimeout(() => {
        if (remainTime > 0) {
          setRemainTime(remainTime - 1);
        }
      }, 1000);
    } else {
      setRemainTime(null);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [remainTime, data.step, data.id]);

  const sendAnswer = () => {
    setLoading(true);
    db.collection("quizes")
      .doc(`${userInfo.uid}-${data.id}`)
      .set({
        room,
        question: parseInt(data.id),
        answer: parseInt(myAnswer),
        uid: userInfo.uid,
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
      })
      .then((docRef) => {
        setSended(true);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error adding answer: ", error);
        setSended(true);
        setLoading(false);
      });

    firebase.analytics().logEvent("quiz-answer");
  };

  return (
    <>
      {obs && (
        <div className="fixed w-full h-full left-0 top-0 bg-gray-900"></div>
      )}
      <div className="h-full flex flex-col items-center justify-center p-6 relative">
        <div
          style={{ transform: `scaleX(${1 - remainTime / 20})` }}
          className="absolute top-0 w-full origin-left h-1 bg-indigo-600 ease-out transition duration-700"
        ></div>
        <div className="relative text-sm rounded-full font-bold w-10 h-10 border border-indigo-400 text-indigo-600 flex items-center justify-center mb-4">
          {parseInt(remainTime || 0)}
          {data.step === "start" && (
            <span className="absolute animate-ping h-full rounded-full w-full border border-indigo-400"></span>
          )}
        </div>
        <h1 className="text-prosto text-lg font-bold w-full text-center">
          {data.text}
        </h1>
        <div className="flex flex-col gap-2 mt-4 w-full">
          {data.answers.map((text, index) => {
            return (
              <button
                key={`${text}_${index}`}
                disabled={sended || loading || data.step === "stop"}
                onClick={() => setMyAnswer(index)}
                className={`${
                  myAnswer === index && data.step !== "correct"
                    ? "border-indigo-400 text-indigo-600"
                    : ""
                } ${
                  (sended ||
                    loading ||
                    data.step === "stop" ||
                    data.step === "correct") &&
                  myAnswer !== index
                    ? "bg-gray-200 pointer-events-none"
                    : ""
                } ${
                  data.step === "correct" &&
                  data.correct.indexOf(true) === index
                    ? "bg-green-500 border-green-500 text-white"
                    : ""
                } ${
                  data.step === "correct" &&
                  data.correct.indexOf(true) !== index &&
                  myAnswer === index
                    ? "bg-red-500 border-red-500 text-white"
                    : ""
                } p-2 text-sm border rounded-lg w-full text-center transition duration-500`}
              >
                {text}
              </button>
            );
          })}
          {data.step === "start" && (
            <button
              onClick={sendAnswer}
              disabled={myAnswer === null || loading || sended}
              className={`${
                myAnswer === null || loading || sended
                  ? "pointer-events-none opacity-50"
                  : ""
              } text-white w-full text-center p-3 text-sm uppercase tracking-wider font-bold rounded-lg bg-indigo-600`}
            >
              {loading && "Отправляем ответ..."}
              {sended && "Ответ принят"}
              {!loading && !sended && "Подтвердить ответ"}
            </button>
          )}
        </div>
      </div>
    </>
  );
}
