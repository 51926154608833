import React, { useState, useEffect } from "react";
import firebase from "firebase";

export default function Users() {
  const [users, setUsers] = useState([]);

  const getUsers = () => {
    const db = firebase.firestore();
    db.collection("users")
      .orderBy("createdAt", "desc")
      .limit(100)
      .get()
      .then((querySnapshot) => {
        const preparedUsers = [];
        querySnapshot.forEach((doc) => {
          const u = doc.data();
          const user = {
            createdAt: u.createdAt.seconds,
            email: u.email,
            external_link: u.external_link,
            name: u.name,
            photo: u.photo,
            uid: u.uid,
            updatedAt: u.updatedAt.seconds,
          };
          preparedUsers.push(user);
        });
        setUsers(preparedUsers);
      })
      .catch((error) => {
        console.log("Error getting documents", error);
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className="flex flex-col">
      {users.map((u, index) => {
        return (
          <div
            key={u.uid}
            className="w-full flex gap-2 text-sm mb-2 items-center border-b pb-2"
          >
            <div className="w-6 text-indigo-600 text-center">{index + 1}</div>
            <div
              className="w-8 h-8 flex-shrink-0 bg-cover rounded-full bg-gray-200"
              style={{ backgroundImage: `url(${u.photo})` }}
            />
            <div className="w-32 ml-2 text-xs text-gray-600">
              {new Date(u.createdAt * 1000).toLocaleString()}
            </div>
            <div className="w-32 mx-2 text-xs text-gray-600">
              {new Date(u.updatedAt * 1000).toLocaleString()}
            </div>
            <div className="w-52 truncate">{u.name}</div>
            <div>{u.email}</div>
            <div>{u.external_link}</div>
          </div>
        );
      })}
      {/* </tbody>
      </table> */}
    </div>
  );
}
