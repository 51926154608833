import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import {
  Award,
  Check,
  Facebook,
  Instagram,
  Search,
  Star,
  X,
  Zap,
} from "react-feather";
import firebase from "firebase";

const Portal = ({ children }) => {
  const modalRoot = document.getElementById("modal");
  const el = document.createElement("div");

  useEffect(() => {
    modalRoot.appendChild(el);
  }, [el, modalRoot]);

  useEffect(() => {
    return () => modalRoot.removeChild(el);
  });
  return createPortal(children, el);
};

const roles = {
  basic: "Участник",
  setters: "PITCHA Team",
  moderator: "PITCHA Team",
};

export default function UserCard({ uid, userInfo, onClose }) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    // firebase.functions().useFunctionsEmulator("http://localhost:5001");
    console.log("getting user with uid:");
    console.log(uid);

    firebase
      .functions()
      .httpsCallable("getUserProfile")({ uid })
      .then((response) => {
        setUser(response.data);
        setLoading(false);
      })
      .catch((e) => {
        console.error("Get user error", e);
        setLoading(false);
      });

    firebase.analytics().logEvent("open-profile");
  }, [uid]);

  const cardClasses = [
    "flex flex-col max-w-md mx-auto mt-10 bg-white rounded-xl p-6 items-center bg-cover relative pb-8",
  ];
  if (loading) cardClasses.push("show-from-bottom");
  const titleClasses = ["text-xl font-medium mt-2"];
  const labelClasses = [
    "px-2 rounded-md py-1 uppercase text-xs font-medium mt-1",
  ];
  if (user === null || user.role === "basic") {
    cardClasses.push("bg-gray-100");
    labelClasses.push("bg-black text-white");
  } else {
    cardClasses.push("bg-gradient-to-b from-blue-600 to-gray-900");
    titleClasses.push("text-white");
    labelClasses.push("bg-white text-gray-900");
  }

  return (
    <Portal>
      <div className="fixed z-30 left-0 top-0 w-full h-full bg-black opacity-50"></div>
      <div className="fixed z-30 left-0 top-0 w-full h-full overflow-auto">
        <button
          onClick={onClose}
          className="fixed w-full h-full left-0 top-0"
        ></button>
        <div className="container mx-auto px-6">
          <div className={cardClasses.join(" ")}>
            <button
              onClick={onClose}
              className="absolute w-8 h-8 right-2 top-2 rounded-full bg-white flex items-center justify-center"
            >
              <X size={18} />
            </button>
            {loading || !user ? (
              <>
                <div className="w-16 h-16 rounded-full bg-white shadow-lg" />
                <div
                  className={`${titleClasses.join(
                    " "
                  )} w-40 h-4 bg-white shadow-lg rounded-md mt-2`}
                />
                <div className={`${labelClasses.join(" ")} w-24 mt-2 h-4`} />
                <div className="w-full flex flex-col h-32 rounded-xl shadow-xl p-4 mt-6 bg-white shadow-lg relative">
                  <div className="w-4 h-4 border-2 border-indigo-600 animate-spin absolute left-1/2 top-1/2 -m-2" />
                </div>
              </>
            ) : (
              <>
                <div
                  className="w-16 h-16 rounded-full bg-cover border-2 border-white flex-shrink-0 relative"
                  style={{ backgroundImage: `url(${user.photo})` }}
                >
                  {user.role !== "basic" && (
                    <div className="ring-2 ring-white w-4 text-white h-4 bg-primary rounded-full flex items-center justify-center absolute right-0 bottom-0">
                      <Check size={10} />
                    </div>
                  )}
                </div>
                <h1 className={titleClasses.join(" ")}>{user.name}</h1>
                <p
                  style={{ fontSize: "0.65rem" }}
                  className={labelClasses.join(" ")}
                >
                  {roles[user.role || "basic"]}
                </p>
                {(user.needTinder || user.instagram || user.facebook) && (
                  <div className="w-full flex flex-col rounded-md p-3 mt-4 bg-white overflow-auto">
                    {!!user.about && (
                      <div className="flex mb-2">
                        <div className="w-5 h-5 rounded-full mr-2 items-center text-white flex justify-center flex-shrink-0 bg-indigo-600">
                          <Star size={14} />
                        </div>
                        <p className="text-xs w-full flex items-center">
                          {user.about}
                        </p>
                      </div>
                    )}
                    {!!user.instagram && (
                      <div className="flex items-center mb-2">
                        <a
                          href={`https://instagram.com/${user.instagram.replace(
                            "@",
                            ""
                          )}`}
                          target="_blank"
                          rel="noreferrer"
                          className="w-5 h-5 rounded-full mr-2 items-center text-white flex justify-center flex-shrink-0"
                          style={{
                            background:
                              "radial-gradient(circle at 30% 107%,#fdf497 0%,#fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%)",
                          }}
                        >
                          <Instagram size={14} />
                        </a>
                        <a
                          href={`https://instagram.com/${user.instagram.replace(
                            "@",
                            ""
                          )}`}
                          target="_blank"
                          rel="noreferrer"
                          className="text-xs uppercase font-medium text-indigo-600 flex items-center"
                        >
                          {user.instagram}
                        </a>
                      </div>
                    )}
                    {!!user.facebook && (
                      <div className="flex items-center mb-2">
                        <a
                          href={`//${user.facebook}`}
                          target="_blank"
                          rel="noreferrer"
                          className="w-5 h-5 rounded-full mr-2 items-center text-white flex justify-center flex-shrink-0 bg-primary"
                        >
                          <Facebook size={14} />
                        </a>
                        <a
                          href={`//${user.facebook}`}
                          target="_blank"
                          rel="noreferrer"
                          className="text-xs uppercase font-medium text-indigo-600 flex items-center text-ellipsis overflow-hidden"
                        >
                          {user.facebook
                            .replace("fb.com/", "")
                            .replace("facebook.com/", "")}
                        </a>
                      </div>
                    )}
                    {!!user.interests.length && (
                      <div className="flex mb-2">
                        <div className="w-5 h-5 rounded-full mr-2 items-center text-white flex justify-center flex-shrink-0 bg-indigo-600">
                          <Search size={14} />
                        </div>
                        <p className="text-xs w-full flex items-center">
                          {user.interests.join(", ")}
                        </p>
                      </div>
                    )}
                    {!!user.exp.length && (
                      <div className="flex mb-2">
                        <div className="w-5 h-5 rounded-full mr-2 items-center text-white flex justify-center flex-shrink-0 bg-indigo-600">
                          <Award size={14} />
                        </div>
                        <p className="text-xs w-full flex items-center">
                          {user.exp.join(", ")}
                        </p>
                      </div>
                    )}
                    {!!user.power.length && (
                      <div className="flex">
                        <div className="w-5 h-5 rounded-full mr-2 items-center text-white flex justify-center flex-shrink-0 bg-indigo-600">
                          <Zap size={14} />
                        </div>
                        <p className="text-xs w-full flex items-center">
                          {user.power.join(", ")}
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </Portal>
  );
}
