import React, { useState, useEffect, useRef } from "react";
import {
  XCircle,
  Heart,
  Instagram,
  Search,
  Zap,
  Award,
  Facebook,
  Check,
  HelpCircle,
  Star,
} from "react-feather";
import { NavLink } from "react-router-dom";
import firebase from "firebase";
import Lottie from "react-lottie-player";
import pulse from "../../assets/snowman.json";

const DISTANCE_TO_SWIPE = 100;
const roles = {
  basic: "Участник",
  setters: "Pitcha Team",
  moderator: "Pitcha Team",
};

export default function Tinder({ userInfo, room }) {
  const [height, setHeight] = useState(100);
  const [initPos, setInitPos] = useState([0, 0]);
  const [movingPos, setMovingPos] = useState([0, 0]);
  const [useTransition, setUseTransition] = useState(false);
  const [cards, setCards] = useState([]);
  const [loadRequest, setLoadRequest] = useState(true);
  const [swipedIds, setSwipedIds] = useState([]);

  const swipedIdsRef = useRef([]);

  useEffect(() => {
    // firebase.functions().useFunctionsEmulator("http://localhost:5001");

    firebase
      .functions()
      .httpsCallable("getTinderProfiles")({ room })
      .then((response) => {
        setLoadRequest(false);
        console.log(response);
        setCards(response.data.users.reverse());
      });
  }, [room]);

  const setReaction = (item, like) => {
    setSwipedIds([...swipedIds, item.uid]);
    swipedIdsRef.current.push(item.uid);

    firebase.analytics().logEvent("tinder-reaction", {
      like: like,
    });

    firebase
      .functions()
      .httpsCallable("tinderReaction")({
        uid: item.uid,
        like,
        room,
      })
      .then((response) => {
        if (response.data.result === "Match!") {
          firebase.analytics().logEvent("tinder-match");
        }
        console.log(response.data.result);
      });

    setTimeout(() => {
      setCards(cards.slice(0, cards.length - 1));
      setMovingPos([0, 0]);
    }, 200);
  };

  useEffect(() => {
    if (cards.length === 5 && !loadRequest) {
      setLoadRequest(true);
      console.log("load more profiles");
      firebase
        .functions()
        .httpsCallable("getTinderProfiles")({ room })
        .then((response) => {
          console.log("load", response.data);
          const oldIds = cards.map((c) => c.uid);
          const newCards = response.data.users.filter(
            (c) =>
              !oldIds.includes(c.uid) && !swipedIdsRef.current.includes(c.uid)
          );
          if (newCards.length) {
            setCards(
              [...newCards, ...cards].filter(
                (c) => !swipedIdsRef.current.includes(c.uid)
              )
            );
            setLoadRequest(false);
          } else {
            console.log("that's all folks");
            setTimeout(() => {
              setLoadRequest(false);
            }, 3000);
          }
        });
    }
  }, [cards, loadRequest, room]);

  useEffect(() => {
    setHeight(document.getElementById("player").clientHeight);
  }, []);

  const onSwipe = (item, direction = null) => {
    let isSwiped = false;
    setUseTransition(true);
    if (movingPos[0] > DISTANCE_TO_SWIPE || direction === "right") {
      isSwiped = true;
      setReaction(item, true);
      setMovingPos([300, 0]);
    }
    if (movingPos[0] < -DISTANCE_TO_SWIPE || direction === "left") {
      isSwiped = true;
      setReaction(item, false);
      setMovingPos([-300, 0]);
    }
    setTimeout(() => {
      setInitPos([0, 0]);
      if (!isSwiped) {
        setMovingPos([0, 0]);
      }
    }, 50);
  };

  if (!userInfo) {
    return null;
  }

  if (!userInfo.needTinder) {
    return (
      <div
        style={{ minHeight: "24rem", maxHeight: `${height}px` }}
        className="container mx-auto px-6 pb-12 md:pb-20 relative"
      >
        <div className="relative w-full flex flex-col items-center mt-6">
          <Lottie
            loop
            animationData={pulse}
            play
            className="w-3/4 md:w-1/2 -mt-10"
          />

          <h3 className="text-xl text-center font-medium mb-4">
            Нетворкинг здесь!
          </h3>
          <p className="text-sm text-center mx-6">
            Заполни анкету в профиле и получи доступ к тысячам контактов КОЛЛЕГ.
          </p>

          <NavLink
            to="/profile"
            className="bg-indigo-600 uppercase tracking-wider font-medium text-white rounded-lg p-3 px-5 text-center mt-6"
          >
            Заполнить профиль
          </NavLink>
        </div>
      </div>
    );
  }

  const computedRotate = movingPos[0] / 10;

  return (
    <div
      style={{ minHeight: "24rem", maxHeight: `${height}px` }}
      className="container mx-auto px-6 pb-12 md:pb-20 relative"
    >
      <h2 className="uppercase text-xs text-blue-500 text-center font-medium w-full my-4 tracking-wider">
        Бизнес-тиндер
      </h2>

      {!loadRequest && (
        <div className="absolute w-full p-4 flex flex-col items-center -mx-6 top-1/2 -mt-24">
          <h3 className="text-xl text-center font-medium mb-4">Ого!</h3>
          <p className="text-sm text-center mx-6">
            Кажется вы посмотрели всех участников бизнес-тиндера. Приходите
            попозже, подберем вам еще кого-нибудь.
          </p>
        </div>
      )}

      {loadRequest && (
        <div className="absolute w-6 h-6 border-2 animate-spin border-indigo-600 left-1/2 top-1/2 -m-3"></div>
      )}
      {cards.map((u, index) => {
        const transformStyle = {
          transform: "none",
          width: "calc(100% - 3rem)",
          minHeight: `20rem`,
          maxHeight: "26rem",
        };
        if (index === cards.length - 1) {
          transformStyle.transform = `translate(${
            movingPos[0]
          }px, ${0}px) rotate(${computedRotate}deg) scale(1)`;
        }
        if (index === cards.length - 2) {
          transformStyle.transform = `translate(0px, 30px) scale(0.9)`;
        }
        if (index < cards.length - 2) {
          transformStyle.transform = `translate(0px, 60px) scale(0.8)`;
        }

        const cardClasses = [
          "h-40 flex flex-col mx-auto bg-white rounded-lg p-4 items-center bg-cover absolute pb-6 overflow-hidden",
        ];
        const titleClasses = ["text-md font-medium mt-2"];
        const labelClasses = [
          "px-2 rounded-md py-1 uppercase text-xs font-medium mt-1",
        ];
        if (u.role === "basic") {
          cardClasses.push("bg-gray-100");
          labelClasses.push("bg-primary text-black");
        } else {
          cardClasses.push("bg-gradient-to-b from-blue-600 to-gray-900");
          titleClasses.push("text-white");
          labelClasses.push("bg-white text-gray-900");
        }
        if (index >= cards.length - 3) cardClasses.push("shadow-xl");
        if (useTransition)
          cardClasses.push("transition-transform duration-300");

        return (
          <div
            key={u.uid}
            style={transformStyle}
            onTouchStart={(e) => {
              setUseTransition(false);
              const mx = e.changedTouches[0].clientX;
              const my = e.changedTouches[0].clientY;
              setInitPos([mx, my]);
              setMovingPos([0, 0]);
            }}
            onTouchMove={(e) => {
              const mx = e.changedTouches[0].clientX - initPos[0];
              const my = e.changedTouches[0].clientY - initPos[1];
              setMovingPos([mx, my]);
            }}
            onTouchEnd={() => onSwipe(u)}
            className={cardClasses.join(" ")}
          >
            {index === cards.length - 1 && (
              <>
                {movingPos[0] >= 0 ? (
                  <div
                    style={{
                      opacity: movingPos[0] / 100,
                    }}
                    className="text-white absolute w-full h-full left-0 top-0 pointer-events-none rounded-lg flex items-center justify-center"
                  >
                    <div className="w-full h-full absolute left-0 top-0 opacity-80 bg-green-500"></div>
                    <Heart size="60" className="relative" />
                  </div>
                ) : (
                  <div
                    style={{
                      opacity: -movingPos[0] / 100,
                    }}
                    className="text-white absolute w-full h-full left-0 top-0 pointer-events-none rounded-lg flex items-center justify-center"
                  >
                    <div className="w-full h-full absolute left-0 top-0 opacity-80 bg-red-500"></div>
                    <XCircle size="60" className="relative" />
                  </div>
                )}
              </>
            )}

            <div
              className="w-16 h-16 rounded-full bg-cover border-2 border-white flex-shrink-0 relative"
              style={{ backgroundImage: `url(${u.photo})` }}
            >
              {u.role !== "basic" && (
                <div className="ring-2 ring-white w-4 text-black h-4 bg-primary rounded-full flex items-center justify-center absolute right-0 bottom-0">
                  <Check size={10} />
                </div>
              )}
            </div>
            <p className={titleClasses.join(" ")}>{u.name}</p>
            <p
              style={{ fontSize: "0.65rem" }}
              className={labelClasses.join(" ")}
            >
              {roles[u.role || "basic"]}
            </p>
            <div className="w-full flex flex-col rounded-md p-3 mt-4 bg-white overflow-auto">
              {!!u.about && (
                <div className="flex mb-2">
                  <div className="w-5 h-5 rounded-full mr-2 items-center text-white flex justify-center flex-shrink-0 bg-indigo-600">
                    <Star size={14} />
                  </div>
                  <p className="text-xs w-full flex items-center">{u.about}</p>
                </div>
              )}
              {!!u.instagram && (
                <div className="flex items-center mb-2">
                  <a
                    href={`https://instagram.com/${u.instagram.replace(
                      "@",
                      ""
                    )}`}
                    target="_blank"
                    rel="noreferrer"
                    className="w-5 h-5 rounded-full mr-2 items-center text-white flex justify-center flex-shrink-0"
                    style={{
                      background:
                        "radial-gradient(circle at 30% 107%,#fdf497 0%,#fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%)",
                    }}
                  >
                    <Instagram size={14} />
                  </a>
                  <a
                    href={`https://instagram.com/${u.instagram.replace(
                      "@",
                      ""
                    )}`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-xs uppercase font-medium text-blue-500 flex items-center"
                  >
                    {u.instagram}
                  </a>
                </div>
              )}
              {!!u.facebook && (
                <div className="flex items-center mb-2">
                  <a
                    href={`//${u.facebook}`}
                    target="_blank"
                    rel="noreferrer"
                    className="w-5 h-5 rounded-full mr-2 items-center text-white flex justify-center flex-shrink-0 bg-primary"
                  >
                    <Facebook size={14} />
                  </a>
                  <a
                    href={`//${u.facebook}`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-xs uppercase font-medium text-blue-500 flex items-center text-ellipsis overflow-hidden"
                  >
                    {u.facebook
                      .replace("fb.com/", "")
                      .replace("facebook.com/", "")}
                  </a>
                </div>
              )}
              {!!u.interests.length && (
                <div className="flex mb-2">
                  <div className="w-5 h-5 rounded-full mr-2 items-center text-white flex justify-center flex-shrink-0 bg-indigo-600">
                    <Search size={14} />
                  </div>
                  <p className="text-xs w-full flex items-center">
                    {u.interests.join(", ")}
                  </p>
                </div>
              )}
              {!!u.exp.length && (
                <div className="flex mb-2">
                  <div className="w-5 h-5 rounded-full mr-2 items-center text-white flex justify-center flex-shrink-0 bg-indigo-600">
                    <Award size={14} />
                  </div>
                  <p className="text-xs w-full flex items-center">
                    {u.exp.join(", ")}
                  </p>
                </div>
              )}
              {!!u.power.length && (
                <div className="flex">
                  <div className="w-5 h-5 rounded-full mr-2 items-center text-white flex justify-center flex-shrink-0 bg-indigo-600">
                    <Zap size={14} />
                  </div>
                  <p className="text-xs w-full flex items-center">
                    {u.power.join(", ")}
                  </p>
                </div>
              )}
            </div>
          </div>
        );
      })}

      {!!cards.length && (
        <>
          <button
            onClick={() => onSwipe(cards[cards.length - 1], "right")}
            className="text-white backdrop-filter backdrop-blur-sm w-16 h-16 absolute flex items-center justify-center bottom-4 right-4 rounded-full"
          >
            <div className="w-full h-full absolute bg-green-500 opacity-50 rounded-full"></div>
            <Heart size="24" className="relative" />
          </button>
          <button
            onClick={() => onSwipe(cards[cards.length - 1], "left")}
            className="text-white backdrop-filter backdrop-blur-sm w-16 h-16 absolute flex items-center justify-center bottom-4 left-4 rounded-full"
          >
            <div className="w-full h-full absolute bg-red-500 opacity-50 rounded-full"></div>
            <XCircle size="24" className="relative" />
          </button>
        </>
      )}
    </div>
  );
}
