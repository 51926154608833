import React, { useState, useEffect } from "react";
import firebase from "firebase";
import Chat from "./Widgets/Chat";
import Blank from "./Widgets/Blank";
import SpeakerQuestion from "./Widgets/SpeakerQuestion";
import Poll from "./Widgets/Poll";
import Quiz from "./Widgets/Quiz";
import WordsCloud from "./Widgets/WordsCloud";
import Results from "./Widgets/Results";
import VideoPlayer from "./VideoPlayer";
import Tinder from "../Tinder/Tinder";
import TinderInbox from "../TinderInbox/TinderInbox";
import Song from "./Widgets/Song";
import Confetti from "react-confetti";
import WorksPoll from "../WorksPoll/WorksPoll";
import Shop from "./Widgets/Shop";
import UploadWidget from "./Widgets/Upload";
import SquidRope from "./Widgets/SquidRope";
import SquidRun from "./Widgets/SquidRun";
import Tree from "./Widgets/Tree";
import Drill from "./Widgets/Drill";
import Race from "./Widgets/Race";
import QuizOpen from "./Widgets/QuizOpen";
import Timer from "./Widgets/Timer";
import Reaction from "./Widgets/Reaction";
import Timetable from "./Timetable";
import TimetablePage from "./Widgets/TimetablePage";
import Rate from "./Widgets/Rate";

export default function Live({ userInfo, room, subroom, obs, tinderChats }) {
  const [now, setNow] = useState(null);
  const [showTimer, setShowTimer] = useState(false);

  useEffect(() => {
    const subscriber = firebase.database().ref(`/rooms/${room}`);
    const onValueChange = subscriber.on("value", (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setNow(data);
      } else {
        alert("Empty realtime database for room " + room);
      }
    });

    // Unsubscrbe from room state
    return () => subscriber.off("value", onValueChange);
  }, [room]);

  // Login control
  useEffect(() => {
    const subscriber = firebase
      .database()
      .ref(`/loginControl/${room}/${userInfo.whiteId}`);
    const onValueChange = subscriber.on("value", (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        if (data !== userInfo.loginControlId && !userInfo.isAdmin) {
          alert("Зафиксирован вход под вашим билетом 😱");
          firebase.analytics().logEvent("kick-out");
          firebase.auth().signOut();
        }
      }
    });

    // Unsubscrbe from room state
    return () => subscriber.off("value", onValueChange);
  }, [
    room,
    userInfo.isAdmin,
    userInfo.loginControlId,
    userInfo.ticket,
    userInfo.whiteId,
  ]);

  const getWidget = (obs) => {
    if (subroom === "program") {
      return (
        <TimetablePage
          data={
            now && now.state && now.state.timetable ? now.state.timetable : []
          }
        />
      );
    }
    if (subroom === "tinder") {
      return <Tinder userInfo={userInfo} room={room} />;
    }
    if (subroom === "inbox") {
      return <TinderInbox tinderChats={tinderChats} userInfo={userInfo} />;
    }

    if (subroom === "works-poll") {
      return <WorksPoll userInfo={userInfo} data={now.state} />;
    }
    if (subroom === "shop") {
      return <Shop userInfo={userInfo} data={now.state} cart />;
    }
    if (now && now.state && now.state[subroom]) {
      switch (now.state[subroom].type) {
        case "song":
          return <Song data={now.state[subroom]} />;
        case "blank":
          return <Blank data={now.state[subroom]} obs={obs} />;
        case "chat":
          return (
            <Chat
              obs={obs}
              room={room}
              data={now.state[subroom]}
              userInfo={userInfo}
              messagesData={now.data && now.data.chat ? now.data.chat : []}
            />
          );
        case "speaker-question":
          return (
            <SpeakerQuestion
              obs={obs}
              data={now.state[subroom]}
              questionsData={
                now.data && now.data.questions ? now.data.questions : []
              }
              likesData={
                now.data && now.data.questionsLikes
                  ? now.data.questionsLikes
                  : []
              }
              userInfo={userInfo}
            />
          );
        case "poll":
          return (
            <Poll
              obs={obs}
              data={now.state[subroom]}
              userInfo={userInfo}
              pollsData={now.data && now.data.polls ? now.data.polls : {}}
              room={room}
            />
          );

        case "rate":
          return (
            <Rate
              obs={obs}
              data={now.state[subroom]}
              userInfo={userInfo}
              pollsData={now.data && now.data.polls ? now.data.polls : {}}
            />
          );
        case "words-cloud":
          return (
            <WordsCloud
              obs={obs}
              data={now.state[subroom]}
              userInfo={userInfo}
              pollsData={now.data && now.data.polls ? now.data.polls : {}}
              room={room}
            />
          );
        case "quiz":
          return (
            <Quiz
              obs={obs}
              data={now.state[subroom]}
              userInfo={userInfo}
              room={room}
            />
          );
        case "quiz-open":
          return (
            <QuizOpen
              obs={obs}
              data={now.state[subroom]}
              userInfo={userInfo}
              room={room}
            />
          );
        case "upload":
          return (
            <UploadWidget
              obs={obs}
              data={now.state[subroom]}
              userInfo={userInfo}
              room={room}
            />
          );
        case "squid-rope":
          return (
            <SquidRope
              obs={obs}
              data={now.state[subroom]}
              userInfo={userInfo}
              room={room}
            />
          );
        case "squid-run":
          return (
            <SquidRun
              obs={obs}
              data={now.state[subroom]}
              userInfo={userInfo}
              room={room}
            />
          );
        case "drill":
          return (
            <Drill
              obs={obs}
              data={now.state[subroom]}
              userInfo={userInfo}
              room={room}
            />
          );
        case "race":
          return (
            <Race
              obs={obs}
              data={now.state[subroom]}
              userInfo={userInfo}
              room={room}
            />
          );
        case "tree":
          return (
            <Tree
              obs={obs}
              data={now.state[subroom]}
              userInfo={userInfo}
              room={room}
            />
          );
        case "results":
          return (
            <Results obs={obs} data={now.state[subroom]} userInfo={userInfo} />
          );
        default:
          return <Blank />;
      }
    }
    return <Blank />;
  };

  useEffect(() => {
    if (window.location.hash === "#a") {
      setShowTimer(false);
    }
  }, []);

  if (!now || !userInfo) return <div className="delay-loader"></div>;

  return (
    <div className="container mx-auto px-6 pb-16">
      {!obs && (
        <Timetable
          data={
            now && now.state && now.state.timetable ? now.state.timetable : []
          }
        />
      )}
      <Confetti
        colors={[
          "#f72585",
          "#b5179e",
          "#7209b7",
          "#3a0ca3",
          "#3f37c9",
          "#4895ef",
          "#4cc9f0",
        ]}
        style={{
          position: "fixed",
        }}
        recycle={now && now.state && now.state.party}
        numberOfPieces={now && now.state && now.state.party ? 200 : 0}
      />
      <div className="grid grid-cols-12 gap-4 mb-6">
        {!obs && (
          <div className="col-span-12 lg:col-span-8">
            <div
              id="player"
              className="ratio-16x9 rounded-lg bg-black overflow-hidden"
            >
              {now && now.state && now.state.stream && (
                <VideoPlayer url={now.state.stream} />
              )}
            </div>
          </div>
        )}
        <div
          className={`relative col-span-12 ${
            obs || subroom === "works-poll" ? "mt-5" : "lg:col-span-4"
          } ${obs ? "bg-transparent text-white" : "bg-white"} ${
            now &&
            now.state &&
            now.state[subroom] &&
            now.state[subroom].type === "quiz"
              ? "rounded-t-none rounded-b-lg"
              : "rounded-lg"
          }`}
        >
          <div className="overflow-hidden w-full h-full">
            {showTimer ? (
              <Timer onFinish={() => setShowTimer(false)} />
            ) : (
              getWidget(!!obs)
            )}
          </div>

          {now &&
            now.state &&
            now.state[subroom] &&
            now.state[subroom].type === "chat" && <Reaction roomInfo={room} />}
        </div>
        {subroom === "shop" && (
          <div className="col-span-12 bg-white rounded-lg">
            <Shop userInfo={userInfo} data={now.state} />
          </div>
        )}
      </div>
      <div id="modal"></div>
    </div>
  );
}
