import React, { useState, useEffect } from "react";
import { Check, Upload, UploadCloud } from "react-feather";
import VideoPlayer from "../VideoPlayer";
import firebase from "firebase";

export default function UploadWidget({ userInfo, data, room }) {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isUploaded, setUploaded] = useState(null);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setFile(null);
    setFileName("");
    firebase
      .firestore()
      .collection("uploads")
      .doc(`${userInfo.uid}-${data.id}`)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setUploaded(true);
          return;
        }
        setUploaded(false);
      })
      .catch((e) => {
        console.error("Cant get upload", e);
      });
  }, [data.id, userInfo.uid]);

  const onFileChange = (e) => {
    if (!e.target.files.length) {
      setFile(null);
      setFileName("");
      return;
    }
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const formSubmit = () => {
    setLoading(true);
    const storageRef = firebase
      .storage()
      .ref(`uploads/${userInfo.uid}/${data.id}/${fileName}`);
    const uploadTask = storageRef.put(file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const newProgress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(newProgress);
      },
      console.error,
      () => {
        storageRef.getDownloadURL().then((url) => {
          firebase
            .firestore()
            .collection("uploads")
            .doc(`${userInfo.uid}-${data.id}`)
            .set({
              room,
              question: parseInt(data.id),
              url,
              fileName,
              uid: userInfo.uid,
              createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
            })
            .then(() => {
              setLoading(false);
              setUploaded(true);
            })
            .catch((error) => {
              setLoading(false);
              console.error(error);
              alert("Ошибка загрузки файла:" + error);
            });
        });
      }
    );
  };

  const isValid = !!file;

  return (
    <div className="bg-white rounded-xl p-4 flex flex-col w-full h-full items-center justify-center">
      {isUploaded ? (
        <>
          <div className="mt-6 w-12 h-12 bg-green-500 text-white rounded-full flex items-center justify-center">
            <Check size={18} />
          </div>
          <h3 className="text-xl font-medium mb-2 mt-4 text-center text-green-500 mb-8">
            Ваш файл успешно загружен
          </h3>
        </>
      ) : (
        <>
          <div className="rounded-full bg-indigo-600 w-12 h-12 mx-auto text-2xl text-white flex items-center justify-center">
            <UploadCloud size={18} />
          </div>
          <h2 className="text-xl font-bold text-center mt-3 mb-1">
            {data.text}
          </h2>
          <h3 className="text-sm font-bold text-indigo-600 mb-6 w-full text-center">
            {data.subtext}
          </h3>
          {isUploaded === false && (
            <>
              <div className="flex items-center w-full mb-4">
                <label className="w-full cursor-pointer bg-blue-500 px-4 py-3 text-white rounded-lg text-sm font-medium flex items-center justify-center">
                  <input
                    type="file"
                    onChange={onFileChange}
                    className="hidden"
                  />
                  <Upload size={14} className="mr-2 flex-shrink-0" />
                  {file && fileName ? `${fileName}` : "Выберите файл"}
                </label>
              </div>
              <button
                onClick={formSubmit}
                disabled={!isValid}
                className={`${
                  isValid && !isLoading ? "" : "pointer-events-none opacity-60"
                } w-full bg-indigo-600 items-center justify-center px-6 py-3 text-white rounded-lg text-base font-medium flex`}
              >
                {isLoading
                  ? `Загружаем... ${progress.toFixed(0)}%`
                  : "Загрузить"}
              </button>
            </>
          )}
        </>
      )}
    </div>
  );
}
