import React from "react";
import firebase from "firebase";
import notie from "notie";

import { Play } from "react-feather";

export default function Timetable({ now, roomInfo, onChange }) {
  const setEvent = (index) => {
    const newTimetable = TIMETABLE.map((t, i) => {
      return {
        ...t,
        active: index === i,
      };
    });

    const db = firebase.database();
    db.ref(`/rooms/${roomInfo.id}/state/timetable`)
      .set(newTimetable)
      .then((docRef) => {
        notie.alert({
          type: "success",
          text: "Timing is saved",
          position: "bottom",
        });
        onChange();
      })
      .catch((error) => {
        console.error("Error set timing: ", error);
      });
  };

  const currentActive = now.timetable?.findIndex((t) => t.active);

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 lg:col-span-8 mb-3 bg-white rounded-xl p-4">
        <div className="flex flex-col">
          {TIMETABLE.map((t, index) => {
            return (
              <div
                key={index}
                className="border rounded-lg mt-3 p-2 flex gap-2 items-center"
              >
                <button
                  onClick={() => setEvent(index)}
                  className={`border rounded-md p-2 ${
                    currentActive === index
                      ? "text-white bg-indigo-600 border-indigo-600"
                      : "text-indigo-600"
                  }`}
                >
                  <Play size={18} />
                </button>
                <div className="ml-2 flex flex-col">
                  <p className="font-medium text-sm">{t.title}</p>
                  {t.text && <p className="text-xs mt-1">{t.text}</p>}
                </div>
                <p className="text-indigo-600 font-medium text-xs ml-auto mr-2 flex-shrink-0 text-nowrap">
                  {t.time}
                </p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="col-span-12 lg:col-span-4 rounded-xl">
        <div
          id="player"
          className="ratio-16x9 bg-black rounded-xl overflow-hidden mb-3"
        >
          {now && now.stream && (
            <iframe
              title="video player"
              src={now.stream}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          )}
        </div>
        <div className="text-xs ml-2 text-gray-500">
          {now && now.stream ? now.stream : "Empty stream URL"}
        </div>
      </div>
    </div>
  );
}

const TIMETABLE = [
  {
    type: "blank",
    title: "Логотип (пустая заглушка)",
  },
  {
    type: "simple",
    time: "12:00",
    title: "Старт конференции",
  },
  {
    type: "simple",
    time: "12:00",
    title: "Хэй, КОЛЛЕГА",
  },
  {
    type: "simple",
    time: "12:00",
    title: "залетай с разбега",
  },
  {
    type: "simple",
    time: "12:00",
    title: "на конфу КОЛЛЕГИ",
  },
  {
    type: "simple",
    time: "12:00",
    title: "с КОЛЛЕГОЙ заходи!",
  },
  {
    type: "simple",
    time: "???",
    title: "Коллеги, маркетинг и медиа",
  },
  {
    type: "speaker",
    time: "12:10",
    title: "Ирина Храпченкова",
    job: "Digital Director телеканала Пятница!",
    text: "Трансмедийные вселенные: как работает маркетинг впечатлений",
    image: "/images/speakers/hrapchenkova.png",
    instagram: "irina_fischer",
  },

  {
    type: "speaker",
    time: "12:30",
    title: "Евгений Давыдов",
    job: "CEO SETTERS",
    text: "Стратегия на стикере",
    image: "/images/speakers/davidov.png",
    instagram: "davydovjohn",
  },

  {
    type: "speaker",
    time: "13:00",
    title: "Пэр Педерсен",
    job: "Founder and Creative By The Network",
    text: "Make collaboration, not competition: the main trend in creative industry",
    image: "/images/speakers/pedersen.png",
    instagram: "pergoodlife",
  },

  {
    type: "simple",
    time: "???",
    title: "Коллеги, команды и люди",
  },

  {
    type: "speaker",
    time: "14:00",
    title: "Денис Лапшинов",
    job: "Co-founder & creative director Slava Agency",
    text: "Талант расправил плечи",
    image: "/images/speakers/lapshinov.png",
    instagram: "denlapshinov",
  },

  {
    type: "speaker",
    time: "14:30",
    title: "Роман Маресов",
    job: "CEO Яндекс.Еда",
    text: "Зона максимального дискомфорта: как смелые решения помогают расти",
    image: "/images/speakers/maresov.png",
    instagram: "maresov",
  },

  {
    type: "speaker",
    time: "15:00",
    title: "Александра Жаркова",
    job: "со-founder SETTERS, CEO SETTERS EDUCATION",
    text: "Сила комьюнити: человеку нужен человек",
    image: "/images/speakers/zharkova.png",
    instagram: "sasha_zharkovaa",
  },

  {
    type: "simple",
    title: "Коллеги, ВСЕ ЧИНИМ!",
  },
  {
    type: "simple",
    title: "Коллеги, ПОЧИНИЛИ!",
  },

  {
    type: "simple",
    time: "???",
    title: "Коллеги, культура и тренды",
  },

  {
    type: "speaker",
    time: "15:30",
    title: "Пол Вудс",
    job: "CEO & CCO Edenspiekermann",
    text: "Don’t Be An Asshole: How To Build Great Creative Culture",
    image: "/images/speakers/woods.png",
    instagram: "paulthedesigner",
  },

  {
    type: "speaker",
    time: "16:00",
    title: "Иван Дорн",
    job: "Артист, саундпродюсер, основатель музыкального издательства Masterskaya",
    text: "Креатив в музыке: как не бояться и экспериментировать",
    image: "/images/speakers/dorn.png",
    instagram: "dorn_ivan",
  },

  {
    type: "simple",
    time: "18:00",
    title: "Окончание конференции",
  },
  {
    type: "blank",
    title: "Логотип (пустая заглушка)",
  },
];
