import React, { useState, useEffect } from "react";
import firebase from "firebase";
import back from ".././../../assets/back.jpg";

export default function Poll({ userInfo, data, pollsData, obs, room }) {
  const [myAnswer, setMyAnswer] = useState(null);
  const [sended, setSended] = useState(false);
  const [loading, setLoading] = useState(false);
  const [answers, setAnswers] = useState({});

  const barColors = [
    "red-500",
    "indigo-500",
    "yellow-500",
    "pink-500",
    "green-500",
    "blue-500",
    "purple-500",
  ];

  const db = firebase.firestore();

  useEffect(() => {
    setSended(false);
    setMyAnswer(null);
    setLoading(false);
    setAnswers({});
  }, [data.id]);

  useEffect(() => {
    setAnswers(pollsData);
  }, [pollsData]);

  const sendAnswer = () => {
    setLoading(true);
    db.collection("polls")
      .doc(`${userInfo.uid}-${data.id}`)
      .set({
        room,
        question: parseInt(data.id),
        answer: parseInt(myAnswer),
        uid: userInfo.uid,
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
      })
      .then((docRef) => {
        setSended(true);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error adding answer: ", error);
        setSended(true);
        setLoading(false);
      });

    firebase.analytics().logEvent("poll-answer");
  };

  const totalAnswers = Object.keys(answers).reduce((counter, item) => {
    return counter + answers[item];
  }, 0);

  if (obs) {
    return (
      <div className="h-full flex flex-col items-center justify-center p-6">
        <div className="bg-gray-900 fixed left-0 top-0 w-full h-full z-0 pointer-events-none"></div>
        <div style={{ left: "11.5%" }} className="pattern-line"></div>

        <h1 className="font-bold text-5xl w-full font-bold mb-10 border-b border-gray-800 pb-5 relative">
          {data.text}
        </h1>

        <div className="flex flex-col w-full relative">
          {data.answers.map((text, index) => {
            return (
              <div
                key={`${text}_${index}`}
                className="flex gap-4 w-full items-center mb-8"
              >
                <div className="w-64 p-2 text-5xl font-bold flex-shrink-0 relative">
                  <div className="absolute left-0 top-0 w-full h-full bg-blue-900"></div>
                  <div
                    style={{
                      transform: `scaleX(${
                        totalAnswers && answers[index]
                          ? 0.05 + (95 * answers[index]) / (100 * totalAnswers)
                          : 0.05
                      })`,
                    }}
                    className="absolute duration-700 transition transform-gpu origin-left ease-out bg-indigo-600 w-full left-0 top-0 h-full"
                  />
                  <span className="relative">
                    {totalAnswers && answers[index]
                      ? ((100 * answers[index]) / totalAnswers).toFixed(2)
                      : 0}
                    %
                  </span>
                </div>
                <p className="text-3xl font-medium w-full">{text}</p>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div className="h-full flex flex-col items-center justify-center p-6">
      <div className="flex border-b w-full h-full items-end mb-4 pb-2">
        {data.answers.map((text, index) => {
          return (
            <div
              key={`${text}_${index}`}
              className="flex gap-1 flex-col flex-shrink-0"
              style={{
                width: `${100 / data.answers.length}%`,
              }}
            >
              <div
                className={`w-full relative`}
                style={{
                  width: "calc(100% - 0.5rem)",
                  minHeight: "160px",
                  marginLeft: "0.25rem",
                }}
              >
                <div
                  className={`bg-${barColors[index]} transition duration-700 absolute w-full h-full bottom-0 transform-gpu origin-bottom ease-out`}
                  style={{
                    // backgroundColor: barColors[index],
                    transform: `scaleY(${
                      totalAnswers && answers[index]
                        ? 0.05 + (95 * answers[index]) / (100 * totalAnswers)
                        : 0.05
                    })`,
                  }}
                ></div>
              </div>
              <p
                className={`text-${barColors[index]} text-xs text-center font-bold`}
              >
                {totalAnswers && answers[index]
                  ? ((100 * answers[index]) / totalAnswers).toFixed(2)
                  : 0}
                %
              </p>
              <p className="text-xs text-center truncate">{text}</p>
            </div>
          );
        })}
      </div>

      <h1 className="text-prosto text-xl text-center w-full font-bold">
        {data.text}
      </h1>
      <div className="flex flex-col gap-2 mt-4 w-full">
        {data.answers.map((text, index) => {
          return (
            <button
              key={`${text}_${index}`}
              disabled={sended || loading || data.step === "stop"}
              onClick={() => setMyAnswer(index)}
              className={`${
                myAnswer === index ? "border-indigo-400 text-indigo-600" : ""
              } ${
                (sended || loading || data.step === "stop") &&
                myAnswer !== index
                  ? "bg-gray-200 pointer-events-none"
                  : ""
              } p-2 text-sm border rounded-lg w-full text-center`}
            >
              {text}
            </button>
          );
        })}
        {data.step === "start" && (
          <button
            onClick={sendAnswer}
            disabled={myAnswer === null || loading || sended}
            className={`${
              myAnswer === null || loading || sended
                ? "pointer-events-none opacity-50"
                : ""
            } text-white w-full text-center p-3 text-sm uppercase tracking-wider font-bold rounded-lg bg-indigo-600`}
          >
            {loading && "Отправляем ответ..."}
            {sended && "Ответ принят"}
            {!loading && !sended && "Подтвердить ответ"}
          </button>
        )}
      </div>
    </div>
  );
}
