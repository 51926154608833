import React, { useEffect, useState } from "react";
import logo from "../../../assets/squid/logo.svg";
import left from "../../../assets/squid/left.svg";
import right from "../../../assets/squid/right.svg";
// import rules1 from "../../../assets/squid/rules.svg";
import rules2 from "../../../assets/squid/rules2.svg";
// import rules3 from "../../../assets/squid/rules3.svg";
import line from "../../../assets/squid/rope/line.svg";
import win from "../../../assets/squid/win.svg";
import lose from "../../../assets/squid/lose.svg";

import "./Squid.scss";
import firebase from "firebase";

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default function SquidRope({ data, obs, userInfo }) {
  const [myTeam, setMyTeam] = useState(null);
  const [counters, setCounters] = useState({});
  const [showYellow, setShowYellow] = useState(false);
  const [showGreen, setShowGreen] = useState(false);
  const [showMiss, setShowMiss] = useState(false);
  const db = firebase.firestore();

  useEffect(() => {
    const db = firebase.firestore();
    const team = getRandomInt(1, 100) > 50 ? "blue" : "red";
    setMyTeam(team);
    db.collection("speed").doc(`${userInfo.uid}-${data.id}`).set({
      uid: userInfo.uid,
      team,
      question: data.id,
      count: 0,
    });
  }, [userInfo.uid]);

  useEffect(() => {
    const db = firebase.firestore();
    // Listen chat
    const subscriber = db
      .collection("speed")
      .where("question", "==", data.id)
      .onSnapshot((querySnapshot) => {
        let newCounters = {
          blue: 0,
          red: 0,
        };
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          newCounters[data.team] += data.count;
        });
        setCounters(newCounters);
      });

    // Unsubscrbe from subscribers
    return () => {
      subscriber();
    };
  }, []);

  useEffect(() => {
    if (showMiss) {
      setTimeout(() => {
        setShowMiss(false);
      }, 400);
    }
    if (showYellow) {
      setTimeout(() => {
        setShowYellow(false);
      }, 400);
    }
    if (showGreen) {
      setTimeout(() => {
        setShowGreen(false);
      }, 400);
    }
  }, [showYellow, showGreen, showMiss]);

  const setAction = (count) => {
    if (count === 0) {
      setShowMiss(true);
    }
    if (count === 1) {
      setShowYellow(true);
    }
    if (count === 3) {
      setShowGreen(true);
    }
    if (count && ["speed-1", "speed-2", "speed-3"].includes(data.step)) {
      db.collection("speed")
        .doc(`${userInfo.uid}-${data.id}`)
        .set(
          {
            count: firebase.firestore.FieldValue.increment(count),
            question: data.id,
          },
          { merge: true }
        );
    }
  };

  const blueStats = (counters.blue * 100) / (counters.blue + counters.red) || 0;
  const redStats = (counters.red * 100) / (counters.blue + counters.red) || 0;

  const isWin =
    (myTeam === "blue" && blueStats > redStats) ||
    (myTeam === "red" && redStats > blueStats);

  const photos = data.photos.filter((c) => c !== userInfo.photo);

  return (
    <div
      style={{
        minHeight: 300,
        background: `linear-gradient(0deg, #0B0F1F 0%, #092428 100%)`,
      }}
      className={`squid-rope ${data.step} ${
        blueStats > redStats ? "blue-win" : "red-win"
      } h-full flex flex-col items-center justify-center py-10 relative overflow-hidden`}
    >
      {data.step === "squid-results" && isWin && (
        <div
          style={{ backgroundColor: "#7ABC35" }}
          className="result w-full h-full absolute z-10 flex flex-col items-center justify-center"
        >
          <img src={win} alt="win show-from-top animation-delay-1200" />
          <p className="text-white text-center uppercase text-sm mt-2 tracking-wider font-medium show-from-top animation-delay-1200">
            {myTeam === "blue" ? "Звездочки победили" : "Снежинки победили"}
          </p>
          <p className="text-white text-center font-bold text-xl mt-1 tracking-wider show-from-top animation-delay-1200">
            {myTeam === "blue" ? blueStats.toFixed(2) : redStats.toFixed(2)}%
          </p>
        </div>
      )}
      {data.step === "squid-results" && !isWin && (
        <div
          style={{ backgroundColor: "#DB2377" }}
          className="result w-full h-full absolute z-10 flex flex-col items-center justify-center"
        >
          <p className="text-white text-center font-bold text-xl mt-1 tracking-wider show-from-bottom animation-delay-1200">
            {myTeam === "blue" ? blueStats.toFixed(2) : redStats.toFixed(2)}%
          </p>
          <p className="text-white text-center uppercase text-sm mt-2 tracking-wider font-medium show-from-bottom animation-delay-1200">
            {myTeam === "blue" ? "Звездочки проиграли" : "Снежинки проиграли"}
          </p>
          <img
            src={lose}
            alt="lose"
            className="mt-4 show-from-bottom animation-delay-1200"
          />
        </div>
      )}

      <img src={logo} alt="squid logo" className="logo absolute w-40 md:w-64" />

      <div className="field absolute w-full h-full">
        <img src={left} alt="left" className="absolute left-0 top-1/2 w-1/3" />
        <img
          src={right}
          alt="right"
          className="absolute right-0 top-1/2 w-1/3"
        />
        <div className="players absolute w-full h-12 bottom-1/2 -mb-4">
          <img src={line} alt="line" className="w-full absolute bottom-0" />
          <div className="h-full w-1/3 absolute -left-8">
            <div className="person w-1/2 absolute left-0">
              <div
                className="photo"
                style={{ backgroundImage: `url(${photos[7]}` }}
              ></div>
            </div>
            <div className="person w-1/2 absolute left-1/4">
              <div
                className="photo"
                style={{ backgroundImage: `url(${photos[4]}` }}
              ></div>
            </div>
            <div className="person w-1/2 absolute left-1/2">
              <div
                className="photo"
                style={{ backgroundImage: `url(${photos[3]}` }}
              ></div>
            </div>
            <div className="person w-1/2 absolute left-3/4">
              <div
                className="photo"
                style={{
                  backgroundImage: `url(${
                    myTeam === "blue" ? userInfo.photo : photos[0]
                  }`,
                }}
              ></div>
            </div>
            <div className="results absolute w-full left-8 bottom-full mb-12 h-10">
              <p
                className={`${
                  blueStats > redStats ? "bigger" : ""
                } text-sm font-bold uppercase tracking-wider text-white w-full text-center percent`}
              >
                {blueStats.toFixed(2)}%
              </p>
              <p className="text-xs text-blue-400 font-bold uppercase tracking-wider text-white w-full text-center">
                Звездочки{myTeam === "blue" ? " (вы)" : ""}
              </p>
            </div>
          </div>
          <div className="h-full w-1/3 absolute -right-8">
            <div className="person person-reverse w-1/2 absolute right-0">
              <div
                className="photo"
                style={{ backgroundImage: `url(${photos[6]}` }}
              ></div>
            </div>
            <div className="person person-reverse w-1/2 absolute right-1/4">
              <div
                className="photo"
                style={{ backgroundImage: `url(${photos[5]}` }}
              ></div>
            </div>
            <div className="person person-reverse w-1/2 absolute right-1/2">
              <div
                className="photo"
                style={{ backgroundImage: `url(${photos[2]}` }}
              ></div>
            </div>
            <div className="person person-reverse w-1/2 absolute right-3/4">
              <div
                className="photo"
                style={{
                  backgroundImage: `url(${
                    myTeam === "red" ? userInfo.photo : photos[1]
                  }`,
                }}
              ></div>
            </div>
            <div className="results absolute w-full right-8 bottom-full mb-12 h-10">
              <p
                className={`${
                  redStats > blueStats ? "bigger" : ""
                } text-sm font-bold uppercase tracking-wider text-white w-full text-center percent`}
              >
                {redStats.toFixed(2)}%
              </p>
              <p className="text-xs text-green-400 font-bold uppercase tracking-wider text-white w-full text-center">
                Снежинки{myTeam === "red" ? " (вы)" : ""}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="rules left-6 right-6 top-6 absolute">
        {/* <img src={rules1} alt="rules 1" className="absolute rule w-full" /> */}
        <img src={rules2} alt="rules 2" className="absolute rule w-full" />
        {/* <img src={rules3} alt="rules 2" className="absolute rule w-full" /> */}
      </div>

      <div className="actions absolute bottom-0 left-0 right-0 h-1/3">
        {showMiss && (
          <p className="text-hint absolute w-full text-center text-red-500 text-sm uppercase font-bold tracking-wider">
            Мимо...
          </p>
        )}
        {showYellow && (
          <p className="text-hint absolute w-full text-center text-yellow-300 text-sm uppercase font-bold tracking-wider">
            Отлично!
          </p>
        )}
        {showGreen && (
          <p className="text-hint absolute w-full text-center text-green-400 text-sm uppercase font-bold tracking-wider">
            Идеально!
          </p>
        )}
        <div className="absolute w-1/2 left-1/4 h-6 border-white border-2 bottom-24">
          <div className="absolute h-full left-1/3 w-1/3 bg-yellow-300"></div>
          <div className="absolute h-full left-1/2 -mx-2 w-4 bg-green-500"></div>
          <div className="track w-2 h-8 -mt-4 top-1/2 absolute bg-white"></div>
        </div>
        <div className="pulse-btn w-16 h-16 rounded-full border-2 border-white absolute left-1/2 -mx-8 bottom-4"></div>
        <button
          onMouseDown={() => setAction(3)}
          onTouchStart={() => setAction(3)}
          className="w-12 h-12 rounded-full btn-green absolute left-1/2 -mx-6 bottom-6"
        ></button>
        <button
          onMouseDown={() => setAction(1)}
          onTouchStart={() => setAction(1)}
          className="w-12 h-12 rounded-full btn-yellow absolute left-1/2 -mx-6 bottom-6"
        ></button>
        <button
          onMouseDown={() => setAction(0)}
          onTouchStart={() => setAction(0)}
          className="w-12 h-12 rounded-full btn-squid absolute left-1/2 -mx-6 bottom-6"
        ></button>
      </div>
    </div>
  );
}
