import React from "react";
import { Youtube, Users, List, MessageCircle } from "react-feather";
import { NavLink } from "react-router-dom";
import Balance from "./Balance";

export default function Navbar({ userInfo, room, tinderChats }) {
  const hasUnreaded = !!tinderChats.find(
    (c) => !c.readed && c.lastMessageFrom !== userInfo.uid
  );

  return (
    <div className="container mx-auto px-6 -mb-1 relative md:mt-4">
      <div className="flex -mb-20 mt-6 md:mt-12 text-indigo-600 items-center">
        <div className="w-1 h-1 mr-auto"></div>
        <Balance userInfo={userInfo} />
        <NavLink
          to="/profile"
          className="photo bg-black rounded-full w-8 h-8 md:w-10 md:h-10 bg-cover border-2 border-white z-10"
          style={{
            backgroundImage: `url(${userInfo.photo})`,
          }}
        />
      </div>

      <div className="fixed bottom-0 left-0 w-full h-16 z-20 border-t-px border-black md:h-20 flex justify-center">
        <div className="absolute w-full h-full backdrop-filter backdrop-blur-md"></div>
        <div className="absolute w-full h-full bg-blue-900 opacity-70"></div>
        <div className="relative flex justify-around w-full px-4 pt-2 md:pt-4 max-w-lg">
          <NavLink
            to="/live"
            activeClassName="text-primary"
            className="flex flex-col w-16 h-full items-center text-white"
          >
            <Youtube size={24} />
            <p className="text-xs mt-1 font-medium">LIVE</p>
          </NavLink>
          <NavLink
            to="/program"
            activeClassName="text-primary"
            className="flex flex-col w-16 h-full items-center text-white"
          >
            <List size={24} />
            <p className="text-xs mt-1 font-medium">Программа</p>
          </NavLink>
          <NavLink
            to="/tinder"
            activeClassName="text-primary"
            className="flex flex-col w-16 h-full items-center text-white"
          >
            <Users size={24} style={{ marginLeft: -2 }} />
            <p className="text-xs mt-1 font-medium">Тиндер</p>
          </NavLink>
          <NavLink
            to="/chat"
            activeClassName="text-primary"
            className="flex flex-col w-16 h-full items-center text-white relative"
          >
            <MessageCircle size={24} style={{ marginLeft: -2 }} />
            {hasUnreaded && (
              <div
                style={{ margin: "3px 0 0 4px" }}
                className="w-2 h-2 rounded-full left-1/2 top-0 bg-indigo-600 absolute"
              >
                <div className="w-2 h-2 rounded-full absolute left-1/2 top-1/2 -m-1 animate-ping bg-indigo-600" />
              </div>
            )}
            <p className="text-xs mt-1 font-medium">Переписки</p>
          </NavLink>
        </div>
      </div>
    </div>
  );
}
