import React from "react";
import doodle from "../../../assets/kollegi/biglogo.svg";
import Timer from "./Timer";

import Lottie from "react-lottie-player";
import rocket from "../../../assets/pitcha-demo/rocket.json";

export default function Blank({ data, obs }) {
  if (!data || (data && !data.text && !data.subtext)) {
    return <Timer onFinish={() => {}} />;
    // return (
    //   <div
    //     style={{ minHeight: 300 }}
    //     className="h-full flex flex-col items-center justify-center py-10 px-10"
    //   >
    //     <img src={obs ? doodle : doodle} alt="logo" className="w-auto" />
    //   </div>
    // );
  }

  return (
    <div
      style={{ minHeight: 300 }}
      className="h-full flex flex-col items-center justify-center py-10"
    >
      <Lottie
        loop
        animationData={rocket}
        play
        className="w-1/2 md:w-1/2 -mt-10"
      />
      <h1 className="text-2xl font-bold mx-4 text-center">{data.text}</h1>
      <p className="text-sm mt-2 mx-4 text-center">{data.subtext}</p>
    </div>
  );
}
