import React, { memo } from "react";
import ReactWordcloud from "react-wordcloud";

function Cloud({ data, obs, id }) {
  return (
    <ReactWordcloud
      maxWords={50}
      words={data}
      options={{
        enableTooltip: false,
        fontSizes: obs ? [30, 80] : [10, 40],
        colors: obs ? ["#000000"] : ["#000000"],
        rotations: 2,
        padding: 1,
        fontWeight: "bold",
        fontFamily:
          "system-ui,-apple-system,'Segoe UI',Roboto,Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji'",
        rotationAngles: [0, -90],
      }}
    />
  );
}

export default memo(Cloud, (prevProps, nextProps) => {
  const oldCount = prevProps.data.reduce(
    (counter, word) => counter + word.value,
    0
  );
  const nextCount = nextProps.data.reduce(
    (counter, word) => counter + word.value,
    0
  );
  const oldWords = Object.keys(prevProps.data).length;
  const nextWords = Object.keys(nextProps.data).length;
  return oldCount === nextCount && oldWords === nextWords;
});
