import { Star } from "react-feather";
import React, { useEffect, useState } from "react";
import firebase from "firebase";

export default function Balance({ userInfo }) {
  const [result, setResult] = useState(null);

  useEffect(() => {
    const db = firebase.firestore();
    const balanceSubscriber = db
      .collection("results")
      .doc(userInfo.uid)
      .onSnapshot((doc) => {
        if (!doc.exists) {
          db.collection("results").doc(userInfo.uid).set({
            room: "PITCHA-DEMO",
            reg: 0,
          });
          return;
        }
        const data = doc.data();
        if (data.room !== "PITCHA-DEMO") {
          db.collection("results").doc(userInfo.uid).set({
            room: "PITCHA-DEMO",
            reg: 0,
          });
        }
        setResult(data);
      });

    return () => {
      balanceSubscriber();
    };
  }, [userInfo.uid]);

  if (result === null) return null;

  let balance = 0;
  Object.keys(result).forEach((key) => {
    if (key !== "room") {
      balance += parseInt(result[key]);
    }
  });

  return (
    <div className="text-blue-600 flex pl-2 md:pl-3 rounded-full -mr-8 items-center justify-center h-8 md:h-10 bg-white pr-9 md:pr-10">
      <Star className="hidden md:flex" size={16} />
      <div className="ml-1 text-sm md:text-base font-bold">{balance}</div>
    </div>
  );
}
