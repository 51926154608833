import React, { useState, useEffect } from "react";
import { Trash } from "react-feather";
import firebase from "firebase";

export default function Whitelist({ userInfo }) {
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(true);
  const [whitelist, setWhitelist] = useState([]);
  const [whitelistCounter, setWhitelistCounter] = useState(0);
  const db = firebase.firestore();

  const getWhitelist = (db, setWhitelist, setLoading) => {
    db.collection("whitelist")
      .orderBy("createdAt", "desc")
      .limit(50)
      .get()
      .then((querySnapshot) => {
        const preparedList = [];
        querySnapshot.forEach((doc) => {
          const u = doc.data();
          const whitelist = {
            id: doc.id,
            createdAt: u.createdAt.seconds,
            email: u.email,
            category: u.category,
            by: u.by,
          };
          preparedList.push(whitelist);
        });
        setWhitelist(preparedList);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error getting documents", error);
      });
  };

  const countWhitelist = () => {
    db.collection("whitelist")
      .get()
      .then((querySnapshot) => {
        setWhitelistCounter(querySnapshot.size);
      })
      .catch((error) => {
        console.log("Error getting documents", error);
      });
  };

  useEffect(() => {
    const db = firebase.firestore();
    getWhitelist(db, setWhitelist, setLoading);
  }, []);

  const addWhitelist = () => {
    setLoading(true);
    db.collection("whitelist")
      .doc(phone.trim().replace("@", "").replace(/\./g, ""))
      .set({
        email: phone.trim(),
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        category: "online",
        by: userInfo.uid,
      })
      .then((docRef) => {
        setPhone("");
        getWhitelist(db, setWhitelist, setLoading);
      })
      .catch((error) => {
        console.error("Error adding whitelist: ", error);
      });
  };

  const deleteWhitelist = (id) => {
    setLoading(true);
    db.collection("whitelist")
      .doc(id)
      .delete()
      .then(() => {
        console.log("Document successfully deleted!");
        getWhitelist(db, setWhitelist, setLoading);
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });
  };

  // Generation of reserve numbers
  // useEffect(() => {
  //   const reserveEmails = [
  //     "cheib9ki@mitroshina.org",
  //     "aingoos9@mitroshina.org",
  //     "ee2nechu@mitroshina.org",
  //     "wius8dai@mitroshina.org",
  //     "soh3fosh@mitroshina.org",
  //     "yaxeic6o@mitroshina.org",
  //     "ga8inair@mitroshina.org",
  //     "eisheh7y@mitroshina.org",
  //     "aiw1teng@mitroshina.org",
  //     "ahyai8ph@mitroshina.org",
  //     "ieghi0th@mitroshina.org",
  //     "too4jovi@mitroshina.org",
  //     "ukahbe1o@mitroshina.org",
  //     "oqu6quoe@mitroshina.org",
  //     "tah8toov@mitroshina.org",
  //     "ohrook1k@mitroshina.org",
  //     "goh4yawu@mitroshina.org",
  //     "reiqui7v@mitroshina.org",
  //     "ruecho1a@mitroshina.org",
  //     "xei4hith@mitroshina.org",
  //     "ohvai5th@mitroshina.org",
  //     "ahh3chut@mitroshina.org",
  //     "ais7oogh@mitroshina.org",
  //     "eefu8ebo@mitroshina.org",
  //     "quooth4a@mitroshina.org",
  //     "yii6aeng@mitroshina.org",
  //     "ohpoo7ey@mitroshina.org",
  //     "peiv6oov@mitroshina.org",
  //     "oeph7keg@mitroshina.org",
  //     "oochein5@mitroshina.org",
  //     "oosh7tah@mitroshina.org",
  //     "eethai1n@mitroshina.org",
  //     "yah5oobo@mitroshina.org",
  //     "ohh5gaiy@mitroshina.org",
  //     "laik2ote@mitroshina.org",
  //     "theida7s@mitroshina.org",
  //     "oovuc2jo@mitroshina.org",
  //     "oj8ifeef@mitroshina.org",
  //     "noob3aem@mitroshina.org",
  //     "siad2ane@mitroshina.org",
  //     "daecho3t@mitroshina.org",
  //     "booghi7a@mitroshina.org",
  //     "aeyeeth5@mitroshina.org",
  //     "wee6ohch@mitroshina.org",
  //     "eecole2i@mitroshina.org",
  //     "aiwea1ti@mitroshina.org",
  //     "guve9eif@mitroshina.org",
  //     "paecho6f@mitroshina.org",
  //     "quaith6u@mitroshina.org",
  //     "pee5taeb@mitroshina.org",
  //     "phairo2n@mitroshina.org",
  //     "pe3ahshi@mitroshina.org",
  //     "haiqu0ko@mitroshina.org",
  //     "ooth4kip@mitroshina.org",
  //     "keen0uir@mitroshina.org",
  //     "ama6anoh@mitroshina.org",
  //     "ohkoh2uk@mitroshina.org",
  //     "deiph2om@mitroshina.org",
  //     "aiqu3aem@mitroshina.org",
  //     "eivov9ei@mitroshina.org",
  //     "phouhai6@mitroshina.org",
  //     "aequu3ie@mitroshina.org",
  //     "loochao4@mitroshina.org",
  //     "aeb1dael@mitroshina.org",
  //     "weehuje7@mitroshina.org",
  //     "di0oogei@mitroshina.org",
  //     "pohshue3@mitroshina.org",
  //     "ohth3ohd@mitroshina.org",
  //     "ahb3gohx@mitroshina.org",
  //     "fethee1c@mitroshina.org",
  //     "eiwequ0u@mitroshina.org",
  //     "aeson7ju@mitroshina.org",
  //     "eethag9d@mitroshina.org",
  //     "ahpah2ae@mitroshina.org",
  //     "ohyoose7@mitroshina.org",
  //     "es5aepai@mitroshina.org",
  //     "sho9pohg@mitroshina.org",
  //     "seo5bash@mitroshina.org",
  //     "mae7quei@mitroshina.org",
  //     "dai5ayoo@mitroshina.org",
  //     "aej1hahs@mitroshina.org",
  //     "et2wadah@mitroshina.org",
  //     "iereph6e@mitroshina.org",
  //     "eec4eech@mitroshina.org",
  //     "seewo4ch@mitroshina.org",
  //     "oof3neer@mitroshina.org",
  //     "ahshoo8g@mitroshina.org",
  //     "pheipho1@mitroshina.org",
  //     "vo8ietoa@mitroshina.org",
  //     "eish3ish@mitroshina.org",
  //     "di6nahbo@mitroshina.org",
  //     "hah1guof@mitroshina.org",
  //     "johy6aox@mitroshina.org",
  //     "ohdahph0@mitroshina.org",
  //     "eb0eedus@mitroshina.org",
  //     "siegho1a@mitroshina.org",
  //     "iu2jooqu@mitroshina.org",
  //     "eiceepo6@mitroshina.org",
  //     "nae9ohgo@mitroshina.org",
  //     "ik2eexom@mitroshina.org",
  //   ];

  //   const addWhitelist = (number, role) => {
  //     db.collection("whitelist")
  //       .doc(number.replace("@", "").replace(/\./g, ""))
  //       .set({
  //         email: number,
  //         category: role,
  //         createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
  //         by: userInfo.uid,
  //       })
  //       .then((docRef) => {
  //         console.log("was added", number);
  //         // getWhitelist(db, setWhitelist, setLoading);
  //       })
  //       .catch((error) => {
  //         console.error("Error adding whitelist: ", error);
  //       });
  //   };

  //   reserveEmails.forEach((a, index) => {
  //     if (index > 69) {
  //       if (index > 89) {
  //         addWhitelist(a, "superhero");
  //       } else {
  //         addWhitelist(a, "izbrannyj");
  //       }
  //     } else {
  //       addWhitelist(a, "online");
  //     }
  //   });
  // }, [userInfo.uid]);

  return (
    <div className="flex flex-col mb-5">
      <div className="card-body">
        <h5 className="text-xl font-bold flex items-center">
          Whitelist
          <button
            onClick={countWhitelist}
            className="text-xs ml-2 rounded border border-indigo-400 text-indigo-600 p-1 px-2"
          >
            {whitelistCounter > 0 ? whitelistCounter : "Click to count"}
          </button>
        </h5>
        <div className="flex gap-2 mt-3">
          <input
            type="text"
            className="text-sm border rounded-md p-2"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <button
            className="px-3 py-2 bg-indigo-600 text-white flex rounded-lg text-sm items-center"
            disabled={!phone.length || loading}
            onClick={addWhitelist}
          >
            {loading ? "Loading..." : "Add"}
          </button>
        </div>
      </div>
      <div className="flex flex-col gap-2 border-t mt-4">
        {whitelist.map((w) => {
          return (
            <div
              key={w.id}
              className="flex gap-2 border-b pb-2 mt-2 items-center"
            >
              <div className="w-full flex flex-col gap-1">
                <div className="flex gap-2 items-center">
                  {w.by === "webhook" && (
                    <div className="w-2 h-2 rounded-full bg-indigo-600"></div>
                  )}
                  <div className="text-sm font-medium">{w.email}</div>
                </div>
                <div className="text-xs">
                  {new Date(w.createdAt * 1000).toLocaleString()} — 
                  {w.category}
                </div>
              </div>
              <button
                className="w-7 h-7 border rounded-md border-red-400 text-red-600 flex items-center justify-center flex-shrink-0"
                onClick={() => deleteWhitelist(w.id)}
              >
                <Trash size={14} />
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
}
