import React, { useEffect, useState } from "react";
import { ShoppingCart, Star } from "react-feather";
import firebase from "firebase";

// ALREADY IN BALANCE!

const shopItems = [
  {
    id: "collapse-bottle",
    price: 50,
    name: "Складная бутылка",
    qty: 65,
  },
  {
    id: "keychain",
    price: 50,
    name: "Брелок для поиска ключей",
    qty: 65,
  },
  {
    id: "cap",
    price: 55,
    name: "Бейсболка",
    qty: 65,
  },
  {
    id: "mask",
    price: 60,
    name: "Футляр для маски",
    qty: 65,
  },
  {
    id: "bike-bag",
    price: 60,
    name: "Велосумка",
    qty: 65,
  },
  {
    id: "bag",
    price: 65,
    name: "Рюкзак",
    qty: 65,
  },
  {
    id: "cosmetic-bag",
    price: 65,
    name: "Косметичка",
    qty: 60,
  },
  {
    id: "cook-wood",
    price: 70,
    name: "Доска разделочная",
    qty: 65,
  },
  {
    id: "leg-led",
    price: 70,
    name: "Фонарик-маячок на обувь",
    qty: 50,
  },
  {
    id: "ritek-termocup",
    price: 70,
    name: "Термостакан",
    qty: 60,
  },
  {
    id: "road-pack",
    price: 70,
    name: "Набор для путешествий",
    qty: 60,
  },
  {
    id: "ritek-cup",
    price: 70,
    name: "Кружка",
    qty: 65,
  },
  {
    id: "gloves",
    price: 75,
    name: "Перчатки",
    qty: 65,
  },
  {
    id: "coffee-maker",
    price: 80,
    name: "Вспениватель молока",
    qty: 65,
  },
  {
    id: "whiskey-stones",
    price: 80,
    name: "Камни для виски",
    qty: 40,
  },
  {
    id: "banana",
    price: 80,
    name: "Поясная сумка",
    qty: 80,
  },
  {
    id: "cook-hand",
    price: 90,
    name: "Прихватка-рукавица",
    qty: 50,
  },
  {
    id: "board-game",
    price: 100,
    name: "Нарды дорожные",
    qty: 50,
  },
];

export default function Shop({ data, userInfo, cart }) {
  const [shopData, setShopData] = useState([]);
  const [gives, setGives] = useState(0);
  const [buys, setBuys] = useState(0);

  useEffect(() => {
    const db = firebase.firestore();
    const balanceSubscriber = db
      .collection("results")
      .doc(userInfo.uid)
      .onSnapshot((doc) => {
        if (!doc.exists) {
          return;
        }
        const data = doc.data();
        let balance = 0;
        Object.keys(data).forEach((key) => {
          if (key !== "room") {
            balance += parseInt(data[key]);
          }
        });
        setGives(balance);
      });

    const buysSubscriber = db
      .collection("shop")
      .doc(userInfo.uid)
      .onSnapshot((doc) => {
        if (!doc.exists) {
          setBuys(0);
          return;
        }
        const data = doc.data();
        let buysSum = 0;
        Object.keys(data).forEach((shopId) => {
          buysSum +=
            shopItems.find((s) => s.id === shopId).price * data[shopId];
        });

        setBuys(buysSum);
      });

    return () => {
      balanceSubscriber();
      buysSubscriber();
    };
  }, [userInfo.uid]);

  useEffect(() => {
    const db = firebase.firestore();
    const shopSubscriber = db.collection("shop").onSnapshot((snapshot) => {
      const newResults = [];
      snapshot.forEach((d) => {
        const data = d.data();
        newResults.push({
          items: data,
          uid: d.id,
        });
      });
      setShopData(newResults);
    });
    return () => {
      shopSubscriber();
    };
  }, [userInfo.uid]);

  const buy = (id, uid) => {
    const db = firebase.firestore();
    db.collection("results")
      .doc(uid)
      .get()
      .then((user) => {
        const userData = user.data();

        // Get balance
        let balance = 0;
        Object.keys(userData).forEach((key) => {
          if (key !== "room") {
            balance += parseInt(userData[key]);
          }
        });

        // Get buys
        db.collection("shop")
          .doc(uid)
          .get()
          .then((buys) => {
            let totalBuys = 0;
            let myBuys = {};
            if (buys.exists) {
              myBuys = buys.data();
              Object.keys(myBuys).forEach((buyId) => {
                const buyItem = shopItems.find(
                  (shopItem) => shopItem.id === buyId
                );
                totalBuys += buyItem.price * myBuys[buyId];
              });
            }

            // Buy denied
            balance -= totalBuys;
            if (shopItems.find((f) => f.id === id).price > balance) {
              alert("У вас недостаточно баллов для покупки этого товара");
              return;
            }

            if (
              window.confirm(
                `Вы уверены, что хотите купить этот товар? С баланса спишется ${
                  shopItems.find((f) => f.id === id).price
                }`
              )
            ) {
              // BUY
              if (myBuys[id]) {
                myBuys[id]++;
              } else {
                myBuys[id] = 1;
              }
              db.collection("shop").doc(uid).set(myBuys);
            }
          });
      });
  };

  const balance = gives - buys;

  if (cart) {
    const myBuys = shopData.find((u) => u.uid === userInfo.uid);
    if (data.shopOpen) {
      if (myBuys) {
        return (
          <div className="h-full flex flex-col items-center justify-start p-4">
            <h1 className="text-xl font-bold mx-4 text-center mb-4 text-indigo-600">
              Ваши покупки
            </h1>
            <div className="flex flex-col rounded-lg bg-gray-100 px-4 w-full divide-y pb-1">
              {Object.keys(myBuys.items).map((id) => {
                const item = shopItems.find((s) => s.id === id);
                return (
                  <div
                    key={id}
                    className="w-full text-sm flex justify-between py-2"
                  >
                    <p>{item.name}</p>
                    <p className="text-indigo-600">
                      &times; {myBuys.items[id]}
                    </p>
                  </div>
                );
              })}
            </div>
            <p className="mt-4 text-sm mx-4 text-center">
              Вы приобрели эти товары. В скором времени мы передадим их вам.
              Спасибо за участие!
            </p>
          </div>
        );
      }
      return (
        <div className="h-full flex flex-col items-center justify-center py-10 px-2">
          <div className="w-12 h-12 rounded-full bg-secondary flex items-center justify-center text-white mb-2 relative">
            <div className="w-8 h-8 absolute left-2 top-2 rounded-full bg-secondary animate-ping"></div>
            <ShoppingCart
              className="relative"
              style={{ marginLeft: -2 }}
              size={20}
            />
          </div>
          <h1 className="text-xl font-bold mx-4 text-center mb-2 text-indigo-600">
            Магазин открыт!
          </h1>
          <p className="text-sm mt-2 mx-4 text-center">
            Вы пока ничего не купили. Для покупки нажмите на красную кнопку
            с тележкой на товаре.
          </p>
        </div>
      );
    }
    return (
      <div className="h-full flex flex-col items-center justify-center py-10 px-2">
        <div className="w-12 h-12 rounded-full bg-secondary flex items-center justify-center text-white mb-2 relative">
          <div className="w-8 h-8 absolute left-2 top-2 rounded-full bg-secondary animate-ping"></div>
          <ShoppingCart
            className="relative"
            style={{ marginLeft: -2 }}
            size={20}
          />
        </div>
        <h1 className="text-2xl font-bold mx-4 text-center mb-2 text-indigo-600">
          Магазин подарков
        </h1>
        <p className="text-sm mt-2 mx-4 text-center">
          Это магазин подарков. В финале прямого эфира вы сможете купить себе
          подарки на заработанные баллы. Ваш текущий баланс отображается сверу
          около фото профиля.
        </p>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 p-4 gap-4">
      {shopItems.map((s) => {
        let buys = 0;
        shopData.forEach((u) => {
          buys += u.items[s.id] || 0;
        });
        const myBuys = shopData.find(
          (b) => b.uid === userInfo.uid && Object.keys(b.items).includes(s.id)
        )
          ? shopData.find(
              (b) =>
                b.uid === userInfo.uid && Object.keys(b.items).includes(s.id)
            ).items[s.id]
          : 0;
        return (
          <div
            key={s.id}
            className="bg-gray-100 rounded-lg p-4 flex flex-col relative"
          >
            <img
              src={`/images/shop/${s.id}.png`}
              alt={s.id}
              className="w-full rounded-md"
            />
            {myBuys > 0 && (
              <div className="w-10 h-10 flex items-center justify-center rounded-full bg-secondary absolute top-6 right-6 text-white font-bold">
                {myBuys}
              </div>
            )}
            <div className="flex mt-3">
              <div className="flex flex-col mr-2">
                <h3 className="w-full text-indigo-600 text-base font-bold">
                  {s.name}
                </h3>
                <p className="text-xs text-gray-400">
                  {s.qty - buys > 0
                    ? `Осталось ${s.qty - buys}`
                    : "Все разобрали"}
                </p>
              </div>
              <div className="flex flex-shrink-0 ml-auto items-center">
                <p className="text-indigo-600 font-bold text-base mr-2 flex items-center">
                  <Star size={14} className="mr-1" /> {s.price}
                </p>
                {data.shopOpen && balance >= s.price && s.qty - buys > 0 && (
                  <button
                    onClick={() => buy(s.id, userInfo.uid)}
                    className="w-10 h-10 bg-primary rounded-full ml-auto flex items-center justify-center text-white"
                  >
                    <ShoppingCart size={18} style={{ marginLeft: -2 }} />
                  </button>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
