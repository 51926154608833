import React, { useState, useEffect } from "react";
import { User, Eye, EyeOff, ThumbsUp, HelpCircle } from "react-feather";
import UserCard from "../../UserCard/UserCard";
import firebase from "firebase";
import "./SpeakerQuestion.scss";

export default function SpeakerQuestion({
  userInfo,
  data,
  questionsData,
  likesData,
  obs,
}) {
  const [height, setHeight] = useState(100);
  const [question, setQuestion] = useState("");
  const [questions, setQuestions] = useState([]);
  const [likes, setLikes] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [questionsWithLikes, setQuestionsWithLikes] = useState([]);
  const [openedUser, setOpenedUser] = useState(null);

  const [tmpLikes, setTmpLikes] = useState({});

  // Last question show
  const [lastQuestion, setLastQuestion] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [needToShowLastQuestion, setNeedToShowLastQuestion] = useState(false);

  const db = firebase.firestore();

  useEffect(() => {
    if (!obs) setHeight(document.getElementById("player").clientHeight);
  }, [obs]);

  useEffect(() => {
    setQuestions(questionsData);
  }, [questionsData]);

  useEffect(() => {
    setLikes(likesData);
    setTmpLikes({});
  }, [likesData]);

  useEffect(() => {
    setQuestionsWithLikes(
      questions
        .map((q) => {
          const additionalTmpLike = tmpLikes[q.id] === true ? 1 : 0;
          return {
            likes:
              likes.filter((l) => l.qid === q.id).length + additionalTmpLike,
            myLike: additionalTmpLike
              ? true
              : !!likes.filter((l) => l.qid === q.id && l.uid === userInfo.uid)
                  .length,
            ...q,
          };
        })
        .sort((a, b) => (a.likes > b.likes ? -1 : 1))
    );
  }, [likes, tmpLikes, questions, userInfo.uid]);

  useEffect(() => {
    const newLastQuestion = [...questionsWithLikes.filter((q) => !q.h)]
      .sort((a, b) => (a.cAt > b.cAt ? 1 : -1))
      .pop();

    if (!newLastQuestion) return;

    // First launch
    if (!lastQuestion) {
      clearTimeout(timeoutId);
      setLastQuestion(newLastQuestion);
      setNeedToShowLastQuestion(true);
      setTimeoutId(
        setTimeout(() => {
          setNeedToShowLastQuestion(false);
        }, 7000)
      );
      return;
    }

    // Likes changed
    if (lastQuestion.id === newLastQuestion.id) {
      if (
        lastQuestion.likes !== newLastQuestion.likes &&
        needToShowLastQuestion
      ) {
        setLastQuestion(newLastQuestion);
      }
      return;
    } else {
      // Question changed
      clearTimeout(timeoutId);
      if (needToShowLastQuestion) {
        setLastQuestion(newLastQuestion);
        setNeedToShowLastQuestion(true);
        setTimeoutId(
          setTimeout(() => {
            setNeedToShowLastQuestion(false);
          }, 7000)
        );
      } else {
        // Set new question immediately
        setLastQuestion(newLastQuestion);
        setNeedToShowLastQuestion(true);
        setTimeoutId(
          setTimeout(() => {
            setNeedToShowLastQuestion(false);
          }, 7000)
        );
      }
    }
  }, [questionsWithLikes, lastQuestion, timeoutId, needToShowLastQuestion]);

  const sendQuestion = () => {
    db.collection("questions")
      .add({
        message: question.trim(),
        gqid: data.id,
        uid: userInfo.uid,
        role: userInfo.role || 1,
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        name: userInfo.name,
        photo: userInfo.photo,
        external_link: userInfo.external_link,
      })
      .then((docRef) => {
        setQuestion("");
        setShowForm(false);
      })
      .catch((error) => {
        console.error("Error adding message: ", error);
      });
  };

  const hideQuestion = (questionId, hide = true) => {
    db.collection("questions")
      .doc(questionId)
      .set({ hide }, { merge: true })
      .then(() => {})
      .catch((error) => {
        console.error("Error hide message: ", error);
      });
  };

  const likeQuestion = (questionId, like = true) => {
    const newTmpLikes = { ...tmpLikes };
    newTmpLikes[questionId] = like;
    setTmpLikes(newTmpLikes);

    if (like) {
      db.collection("questions-likes")
        .add({
          qid: questionId,
          uid: userInfo.uid,
          gqid: data.id,
        })
        .then(() => {})
        .catch((error) => {
          console.error("Error hide message: ", error);
        });
    } else {
      const myLike = likes
        .filter((l) => l.uid === userInfo.uid && l.qid === questionId)
        .pop();
      if (myLike && myLike.id) {
        db.collection("questions-likes")
          .doc(myLike.id)
          .delete()
          .then(() => {})
          .catch((error) => {
            console.error("Error removing like", error);
          });
      }
    }
  };

  const getQuestions = questionsWithLikes.map((m) => {
    const classes = [
      "question",
      "p-2",
      "pr-7",
      "mt-2",
      "text-sm",
      "rounded-lg",
      "bg-gray-100",
      "rounded-tl-none",
      "ml-10",
      "mr-5",
      "relative",
    ];
    if (m.h && !userInfo.isAdmin) classes.push("hidden");
    if (m.h && userInfo.isAdmin) classes.push("opacity-50");
    const likeClasses = [
      "w-10 h-10 pt-1 flex flex-col text-center items-center rounded-full bg-white absolute text-xs absolute -right-5 top-1 border",
    ];
    if (m.myLike) {
      likeClasses.push("bg-indigo-600 text-black border-indigo-600");
    } else {
      likeClasses.push("border-indigo-400 text-indigo-600");
    }

    return (
      <div key={m.id} className={classes.join(" ")}>
        {userInfo.isAdmin && (
          <button
            className="absolute w-6 h-6 flex items-center justify-center text-indigo-600 -left-9 top-8"
            onClick={() => hideQuestion(m.id, !m.h)}
          >
            {m.h ? <Eye size={16} /> : <EyeOff size={16} />}
          </button>
        )}
        {
          <button
            onClick={() =>
              likeQuestion(m.id, userInfo.isAdmin ? true : !m.myLike)
            }
            className={likeClasses.join(" ")}
          >
            <ThumbsUp className="icon" />
            <small className="count">{m.likes}</small>
          </button>
        }
        <button
          onClick={() => setOpenedUser(m.a)}
          className="w-8 h-8 rounded-full bg-cover absolute -left-10 top-0"
          style={{ backgroundImage: `url(${m.p})` }}
        />
        <p className="text-blue-500 font-medium text-xs">{m.n}</p>
        <p className="text-sm mt-1">{m.m}</p>
      </div>
    );
  });

  const countQuestions = questions.filter((q) => !q.h).length;
  const hasQuestions = !!countQuestions;

  if (obs) {
    return (
      <div className="h-full flex flex-col items-center justify-center p-6">
        <h1 className="font-bold text-5xl w-full font-bold mb-5 border-b border-gray-800 pb-5">
          {data.text}
        </h1>
        <div className="questions pr-2 flex flex-col gap-10 mt-4 h-full w-full">
          {questionsWithLikes.map((m) => {
            if (m.h) return null;
            return (
              <div key={m.id} className="flex gap-4 w-full">
                <div
                  className="w-20 h-20 rounded-full bg-cover flex-shrink-0"
                  style={{ backgroundImage: `url(${m.p})` }}
                ></div>
                <div className="flex flex-col gap-2">
                  <p className="text-xl font-medium text-indigo-600">{m.n}</p>
                  <p className="text-3xl font-medium">{m.m}</p>
                </div>
                <div className="ml-auto flex gap-2 flex-shrink-0 text-3xl font-medium items-center text-indigo-600">
                  <ThumbsUp size={40} />
                  {m.likes}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div
      style={{ minHeight: "420px", maxHeight: `${height}px` }}
      className={`flex flex-col ${
        hasQuestions ? "justify-start" : "justify-center"
      } p-4 h-full`}
    >
      {openedUser && (
        <UserCard
          uid={openedUser}
          userInfo={userInfo}
          onClose={() => setOpenedUser(null)}
        />
      )}

      {!showForm ? (
        <>
          <div className="flex flex-col text-center items-center">
            {!hasQuestions && (
              <>
                <HelpCircle size={28} className="text-indigo-600 mt-3 mb-4" />
                <h5 className="text-prosto text-xl mb-4 font-bold w-full text-center">
                  {data.text || "Задайте свой вопрос"}
                </h5>
                <p className="text-sm mb-5">
                  Вы можете задать свой вопрос или проголосовать за вопросы
                  других.
                </p>
              </>
            )}
            <button
              onClick={() => setShowForm(true)}
              className="uppercase tracking-wider font-medium text-white w-full text-center p-3 text-sm rounded-lg bg-indigo-600"
            >
              Задать свой вопрос
            </button>
          </div>
          {lastQuestion && needToShowLastQuestion && countQuestions > 5 && (
            <div className="last-question bg-yellow-400 p-2 pt-4 mt-4 flex flex-col relative rounded-md">
              <div className="question p-2 pr-7 mt-2 mb-4 text-sm rounded-lg bg-gray-100 rounded-tl-none ml-10 mr-5 relative">
                <button
                  onClick={() =>
                    likeQuestion(
                      lastQuestion.id,
                      userInfo.isAdmin ? true : !lastQuestion.myLike
                    )
                  }
                  className={`w-10 h-10 pt-1 flex flex-col text-center items-center rounded-full bg-white absolute text-xs absolute -right-5 top-1 border ${
                    lastQuestion.myLike
                      ? "bg-indigo-600 text-white border-indigo-600"
                      : "border-indigo-400 text-indigo-600"
                  }`}
                >
                  <ThumbsUp className="icon" />
                  <small className="count">{lastQuestion.likes}</small>
                </button>
                {lastQuestion.p ? (
                  <div
                    className="w-8 h-8 rounded-full bg-cover absolute -left-10 top-0"
                    style={{ backgroundImage: `url(${lastQuestion.p})` }}
                  ></div>
                ) : (
                  <div className="w-8 h-8 rounded-full absolute bg-indigo-600 text-white flex items-center justify-center -left-10 top-0">
                    <User size={18} />
                  </div>
                )}
                <p className="text-indigo-600 font-medium text-xs">
                  {lastQuestion.n}
                </p>
                <p className="text-sm mt-1">{lastQuestion.m}</p>
              </div>
            </div>
          )}
          <div className="questions pr-2 flex flex-col gap-2 mt-4 overflow-auto">
            {getQuestions}
          </div>
        </>
      ) : (
        <div className="flex flex-col overflow-auto">
          <h5 className="text-prosto text-xl mb-4 font-bold text-center w-full">
            {data.text || "Задайте свой вопрос"}
          </h5>
          <hr />
          <div className="flex flex-col">
            <div className="flex items-center my-3">
              <div
                className="w-8 h-8 rounded-full mr-2 bg-cover"
                style={{ backgroundImage: `url(${userInfo.photo})` }}
              ></div>
              <small className="font-medium text-sm mb-0">
                {userInfo.name}
              </small>
            </div>
            <textarea
              className="border rounded-md w-full p-3 text-xs"
              rows="3"
              placeholder="Введите текст вашего вопроса"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            ></textarea>
            <button
              disabled={question.length < 5}
              onClick={() => sendQuestion()}
              className={`${
                question.length < 5 ? "pointer-events-none opacity-50" : ""
              } text-white w-full text-center p-3 text-sm uppercase tracking-wider font-medium rounded-lg bg-indigo-600 mt-5`}
            >
              Задать вопрос
            </button>
            <button
              onClick={() => setShowForm(false)}
              className="text-center p-3 text-sm text-indigo-600 w-full pb-0"
            >
              Отменить
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
