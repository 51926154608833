import React, { useState, useEffect } from "react";
import Whitelist from "./controls/Whitelist";
import Plan from "./controls/Plan";
import Users from "./controls/Users";
import Stream from "./controls/Stream";
import Timetable from "./controls/Timetable";
import Optimizer from "./controls/Optimizer";
import OptimizerV3 from "./controls/OptimizerV3";
import firebase from "firebase";

export default function Control({ room, userInfo }) {
  const [roomInfo, setRoomInfo] = useState(null);
  const [roomState, setRoomState] = useState(null);
  const [currentTab, setCurrentTab] = useState("Plan");
  const [roomFullState, setRoomFullState] = useState(null);

  useEffect(() => {
    const db = firebase.firestore();

    db.collection("rooms")
      .doc(room)
      .get()
      .then((doc) => {
        const data = doc.data();
        data.id = room;
        setRoomInfo(data);
      })
      .catch((error) => {
        console.log("Error getting documents", error);
      });

    const subscriber = firebase.database().ref(`/rooms/${room}`);
    const onValueChange = subscriber.on("value", (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        data.id = room;
        setRoomState(data.state);
        setRoomFullState(data);
      } else {
        alert("Empty realtime database for room " + room);
      }
    });

    // Unsubscrbe from room state
    return () => subscriber.off("value", onValueChange);
  }, [room]);

  const loadRoom = () => {
    const db = firebase.firestore();
    db.collection("rooms")
      .doc(room)
      .get()
      .then((doc) => {
        const data = doc.data();
        data.id = room;
        setRoomInfo(data);
      })
      .catch((error) => {
        console.log("Error getting documents", error);
      });
  };

  const prepareContent = () => {
    switch (currentTab) {
      case "Plan":
        return (
          <Plan
            roomInfo={roomInfo}
            now={roomState}
            onChange={() => loadRoom()}
          />
        );
      case "Stream":
        return (
          <Stream
            now={roomState}
            roomInfo={roomInfo}
            onChange={() => loadRoom()}
          />
        );
      case "Timetable":
        return (
          <Timetable
            now={roomState}
            roomInfo={roomInfo}
            onChange={() => loadRoom()}
          />
        );
      case "Optimizer":
        return <Optimizer roomInfo={roomInfo} now={roomFullState} />;
      case "OptimizerV3":
        return <OptimizerV3 roomInfo={roomInfo} now={roomFullState} />;
      case "Users":
        return (
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 lg:col-span-4 bg-white p-4 rounded-xl">
              <Whitelist userInfo={userInfo} />
            </div>
            <div className="col-span-12 lg:col-span-8 bg-white p-4 rounded-xl">
              <Users />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  if (!roomInfo || !roomState)
    return <h3 className="mt-3 text-center">Loading...</h3>;

  return (
    <div className="container mx-auto px-6 mt-5">
      <div className="grid grid-cols-12">
        <div className="col-span-12">
          <ul className="flex gap-3 mb-3 px-2">
            {[
              "Plan",
              "Timetable",
              "Stream",
              "Users",
              "Optimizer",
              "OptimizerV3",
            ].map((t) => {
              const btnClasses = ["text-md font-medium"];
              if (currentTab === t) btnClasses.push("text-indigo-600");
              return (
                <li key={t} className="nav-item">
                  <button
                    onClick={() => setCurrentTab(t)}
                    className={btnClasses.join(" ")}
                  >
                    {t}
                  </button>
                </li>
              );
            })}
          </ul>
          {prepareContent()}
        </div>
      </div>
    </div>
  );
}
