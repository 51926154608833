import React, { useState, useEffect } from "react";
import UserCard from "../../UserCard/UserCard";
import {
  MessageCircle,
  Eye,
  EyeOff,
  Calendar,
  Smile,
  Check,
  Heart,
} from "react-feather";
import firebase from "firebase";
import { getStickerByRole, stickers } from "../../stickers";
import "./Chat.scss";

import { dirtyWords } from "./dirtyTalk";

export default function Chat({ userInfo, messagesData, obs, data, room }) {
  const [height, setHeight] = useState(100);
  const [message, setMessage] = useState("");
  const [tmpMessages, setTmpMessages] = useState([]);
  const [tmpLikes, setTmpLikes] = useState([]);
  const [stickersShowed, showStickers] = useState(false);
  const [openedUser, setOpenedUser] = useState(null);
  const [waitToUpdateTime, setWaitToUpdateTime] = useState([]);

  const db = firebase.firestore();

  useEffect(() => {
    if (!obs) setHeight(document.getElementById("player").clientHeight);
  }, [userInfo, obs]);

  useEffect(() => {
    setTmpMessages([]);
    setTmpLikes([]);
  }, [messagesData]);

  const sendMessage = (m = null) => {
    setMessage("");
    showStickers(false);
    setTmpMessages([
      {
        id: tmpMessages.length + 1,
        cAt: firebase.firestore.Timestamp.fromDate(new Date()),
        a: userInfo.uid,
        r: userInfo.role || "basic",
        l: userInfo.external_link || "",
        m: m ? m : message.trim(),
        p: userInfo.photo,
        n: `${userInfo.name}`,
        h: false,
      },
      ...tmpMessages,
    ]);

    db.collection("chat")
      .add({
        room,
        message: m ? m : message.trim(),
        uid: userInfo.uid,
        r: userInfo.role || "basic",
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        name: `${userInfo.name}`,
        photo: userInfo.photo,
        link: userInfo.external_link,
      })
      .then((docRef) => {})
      .catch((error) => {
        console.error("Error adding message: ", error);
      });

    firebase.analytics().logEvent("chat-message", {
      sticker: m ? m.indexOf(":") === 0 : message.trim().indexOf(":"),
    });
  };

  const likeMessage = (messageId, like) => {
    setTmpLikes(
      like ? [...tmpLikes, messageId] : tmpLikes.filter((l) => l !== messageId)
    );
    firebase.functions().httpsCallable("setLike")({
      messageId,
      like,
    });
    firebase.analytics().logEvent("like-message");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !!message.trim().length) {
      sendMessage();
      setMessage("");
    }
  };

  const hideMessage = (messageId, hide = true) => {
    db.collection("chat")
      .doc(messageId)
      .set(
        {
          hide,
        },
        { merge: true }
      )
      .then(() => {
        // console.log("Hidden successfully")
      })
      .catch((error) => {
        console.error("Error hide message: ", error);
      });
  };

  const setCorrectDateToMessage = (messageId) => {
    db.collection("chat")
      .doc(messageId)
      .set(
        {
          createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        },
        { merge: true }
      )
      .then(() => {
        // console.log("Hidden successfully")
      })
      .catch((error) => {
        console.error("Error hide message: ", error);
      });
  };

  const openUser = (uid) => {
    setOpenedUser(uid);
  };

  const checkStringForDirty = (word) => {
    let findBad = false;
    dirtyWords.forEach((bad) => {
      if (word.indexOf(bad) > -1) {
        findBad = true;
        return;
      }
    });
    return findBad;
  };

  const getMessagesRows = (messages, tmp = false) => {
    return messages.map((m) => {
      if (checkStringForDirty(m.m) || m.m.includes("https://")) {
        m.h = true;
      }
      if (
        userInfo.isChatCleaner &&
        userInfo.isAdmin &&
        parseInt(m.cAt) > parseInt(new Date().getTime() / 1000)
      ) {
        if (!waitToUpdateTime.includes(m.id)) {
          setWaitToUpdateTime([...waitToUpdateTime, m.id]);
          setCorrectDateToMessage(m.id);
          setTimeout(() => {
            setWaitToUpdateTime(waitToUpdateTime.filter((i) => i !== m.id));
          }, 5000);
        }
      }
      const classes = ["chat-message", "text-sm", "p-2", "mt-2", "relative"];

      if (!stickers.includes(m.m)) classes.push("bg-gray-100");

      if (userInfo.uid === m.a) {
        classes.push("mine mr-10 ml-auto rounded-lg rounded-br-none");
      } else {
        classes.push("ml-10 mr-auto rounded-lg rounded-bl-none");
      }
      if (userInfo.isAdmin) {
        classes.push("admin");
      }
      if (userInfo.role === "moderator") {
        classes.push("moderator");
      }
      if (m.r === "moderator" && !stickers.includes(m.m))
        classes.push("bg-blue-500 text-white");
      if (m.h || m.m.includes("https://")) classes.push("hidden");
      return (
        <div key={m.id} className={classes.join(" ")}>
          {(userInfo.isAdmin || userInfo.role === "moderator") && (
            <button
              className="btn-hide rounded-lg w-6 h-6 absolute -right-8 border top-0 px-1 text-gray-500"
              onClick={() => hideMessage(m.id, !m.h)}
            >
              {m.h ? <Eye size={14} /> : <EyeOff size={14} />}
            </button>
          )}
          {userInfo.isAdmin && (
            <button
              className={`${
                parseInt(m.cAt) > parseInt(new Date().getTime() / 1000)
                  ? "text-indigo-600"
                  : "text-gray-700"
              } btn-hide rounded-lg w-6 h-6 absolute -right-8 border top-7 px-1`}
              onClick={() => setCorrectDateToMessage(m.id)}
            >
              <Calendar size={14} />
            </button>
          )}
          <div
            className={`author text-xs font-medium mb-1 ${
              m.r === "moderator" ? "text-white" : "text-blue-500"
            }`}
          >
            <button
              onClick={() => openUser(m.a)}
              className="photo w-8 h-8 absolute rounded-full cursor-pointer"
            >
              {m.l.length && (
                <div
                  className={`absolute w-10 h-10 -left-1 -top-1 rounded-full avatar-ring-${
                    m.r || "base"
                  }`}
                />
              )}
              <div
                className="absolute w-8 h-8 left-0 top-0 rounded-full bg-cover ring-2 ring-white"
                style={{ backgroundImage: `url(${m.p})` }}
              />
            </button>

            {!stickers.includes(m.m) && (
              <p className="m-0 flex items-center">
                {m.n}
                {m.r === "moderator" && (
                  <span className="w-3 h-3 flex items-center justify-center rounded-full bg-white ml-1 text-blue-500">
                    <Check size={8} />
                  </span>
                )}
              </p>
            )}
          </div>
          {stickers.includes(m.m) ? (
            <img
              className={`${m.h ? "opacity-50" : ""} w-24 h-24`}
              src={`/images/stickers_v2/${m.m.slice(1, m.m.length - 1)}.png`}
              alt={m.m}
            />
          ) : (
            <>
              <p className="text m-0">{m.m}</p>
              {!tmp && (
                <>
                  {m.lk?.includes(userInfo.uid) || tmpLikes.includes(m.id) ? (
                    <button
                      style={{ minWidth: 24 }}
                      onClick={() => likeMessage(m.id, false)}
                      className={`like-btn ${
                        m.a !== userInfo.uid
                          ? "left-full ml-2"
                          : "right-full mr-2"
                      } ${
                        userInfo.role === "moderator" ? "top-10" : "top-1/2"
                      } ml-2 -mt-3 text-xs px-2 absolute justify-center h-6 rounded-full flex items-center bg-red-500 text-white`}
                    >
                      <span style={{ marginRight: "3px" }}>
                        {(m.lk?.length || 0) +
                          tmpLikes.filter((i) => i === m.id).length}
                      </span>
                      <Heart size={10} />
                    </button>
                  ) : (
                    <button
                      style={{ minWidth: 24 }}
                      onClick={() => likeMessage(m.id, true)}
                      className={`like-btn ${
                        m.a !== userInfo.uid
                          ? "left-full ml-2"
                          : "right-full mr-2"
                      } ${
                        m.lk?.length > 0
                          ? "text-red-500 border-red-400"
                          : "text-blue-300 border-blue-200"
                      } ${
                        userInfo.role === "moderator" ? "top-10" : "top-1/2"
                      } text-xs px-2 absolute -mt-3 justify-center  h-6 rounded-full flex items-center border bg-white`}
                    >
                      {m.lk?.length > 0 && (
                        <span style={{ marginRight: "3px" }}>
                          {m.lk.length}
                        </span>
                      )}
                      <Heart size={10} />
                    </button>
                  )}
                </>
              )}
            </>
          )}
        </div>
      );
    });
  };

  if (obs) {
    return (
      <div className="w-full flex flex-col items-center justify-center p-6 text-black">
        <h1 className="font-bold text-5xl w-full flex-shrink-0 font-bold mb-5 border-b border-gray-800 pb-5">
          Чат
        </h1>
        <div className="flex w-full flex-col gap-6">
          {messagesData.map((m) => {
            if (checkStringForDirty(m.m) || m.m.includes("https://")) {
              m.h = true;
            }
            if (m.h) return null;
            return (
              <div key={m.id} className="flex gap-4 w-full">
                <div
                  className="w-20 h-20 rounded-full bg-cover flex-shrink-0"
                  style={{ backgroundImage: `url(${m.p})` }}
                ></div>
                <div className="flex flex-col gap-2 w-full">
                  <p className="text-xl font-medium text-indigo-600">{m.n}</p>
                  {stickers.includes(m.m) ? (
                    <img
                      className={`${m.h ? "opacity-50" : ""} w-24 h-24`}
                      src={`/images/stickers_v2/${m.m.slice(
                        1,
                        m.m.length - 1
                      )}.png`}
                      alt={m.m}
                    />
                  ) : (
                    <div className="flex w-full">
                      <p className="text-3xl font-medium mr-2">{m.m}</p>
                      {m.lk && (
                        <p className="flex-shrink-0 mb-auto bg-primary text-white p-1 px-4 rounded-full flex ml-auto text-xl items-center justify-center">
                          <Heart className="mr-1" size={18} />
                          {m.lk.length}
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  const needToShowButton = data && data.text && data.subtext;

  return (
    <div
      style={{ minHeight: "340px", maxHeight: `${height}px` }}
      className="flex flex-col justify-end h-full min-h-32 relative"
    >
      {openedUser && (
        <UserCard
          uid={openedUser}
          userInfo={userInfo}
          onClose={() => setOpenedUser(null)}
        />
      )}

      {needToShowButton && (
        <a
          href={data.subtext}
          target="_blank"
          rel="noreferrer"
          className="text-sm z-10 text-center px-3 py-1 left-1/2 top-2 shadow-lg transform transform-translate -translate-x-1/2 absolute z-10 rounded-full bg-indigo-600 text-white"
        >
          {data.text}
        </a>
      )}
      <div className="chat flex flex-col-reverse p-3 h-full overflow-auto">
        {getMessagesRows(tmpMessages, true)}
        {getMessagesRows(messagesData)}
        {!tmpMessages.length && !messagesData.length && (
          <div className="text-center px-6 flex flex-col items-center text-gray-400 mt-auto">
            <MessageCircle className="mb-3" size={32} />
            <p className="text-sm mb-4">
              Это чат. Напишите сообщение, и его увидят все!
            </p>
          </div>
        )}
      </div>

      <div
        className={`${
          stickersShowed ? "bottom-14 opacity-1" : "-bottom-24 opacity-0"
        } transition-all py-2 ease-in-out duration-300 absolute left-0 w-full bg-gray-100 h-20 bg-gray-100 overflow-x-auto overflow-y-hidden`}
      >
        <div className="flex gap-2 px-2">
          {getStickerByRole(userInfo.role).map((c) => {
            return (
              <button
                key={c}
                onClick={() => {
                  sendMessage(`:${c}:`);
                }}
                className="w-16 h-16 rounded-lg p-1 flex-shrink-0"
              >
                <img src={`/images/stickers_v2/${c}.png`} alt={c} />
              </button>
            );
          })}
        </div>
      </div>

      <div className="flex p-2 border-t items-center relative bg-white">
        <div
          className="photo rounded-full w-8 h-8 bg-cover mr-2 flex-shrink-0"
          style={{ backgroundImage: `url(${userInfo.photo})` }}
        ></div>
        <input
          type="text"
          className="border rounded-tl-lg rounded-bl-lg outline-none w-full pr-10 px-2 h-10 text-sm"
          value={message}
          onKeyDown={handleKeyDown}
          onChange={(e) => setMessage(e.target.value)}
        />
        <button
          onClick={() => showStickers(!stickersShowed)}
          className="w-10 h-10 flex text-indigo-600 items-center justify-center absolute right-12 top-2"
        >
          <Smile size={22} />
        </button>
        <button
          disabled={!message.length}
          onClick={() => sendMessage()}
          className="flex items-center justify-center flex-shrink-0 w-10 h-10 bg-indigo-600 rounded-tr-lg rounded-br-lg text-white"
        >
          <MessageCircle size={22} />
        </button>
      </div>
    </div>
  );
}
