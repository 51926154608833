import React from "react";
import "./Song.scss";
import Lottie from "react-lottie-player";
import music from "./animations/music.json";
import catGray from "./animations/cat.json";
import catOrange from "./animations/cat-orange.json";

export default function Song({ data }) {
  if (data.step === "start") {
    return (
      <div className="relative w-full h-80">
        <div className="absolute w-full h-40 overflow-hidden top-1/2 -mt-28">
          <div className="song absolute w-full text-base left-0 top-0 py-40 text-center">
            Кис-кис, кис-кис
            <br />
            Я котик, ты котик
            <br />
            А твои поцелуи
            <br />
            Почти как легкий наркотик
            <br />
            <br />
            Кис-кис, кис-кис
            <br />
            Сегодня не приеду
            <br />
            Я сильно обижусь
            <br />
            Но остыну к обеду
            <br />
            <br />
            Ты хейтер котиков
            <br />
            Но зовешь меня кошкой
            <br />
            Ты сильно против наркотиков
            <br />
            Я с подвернутой ножкой
            <br />
            <br />
            Вечер понедельника
            <br />
            И сладкое вино
            <br />
            Мы с тобой два бездельника
            <br />
            В твоих объятиях очень тепло
            <br />
            <br />
            Кис-кис, кис-кис
            <br />
            Я котик, ты котик
            <br />
            А твои поцелуи
            <br />
            Почти как легкий наркотик
            <br />
            <br />
            Кис-кис, кис-кис
            <br />
            Сегодня не приеду
            <br />
            Я сильно обижусь
            <br />
            Но остыну к обеду
            <br />
            <br />
            Я не понимаю
            <br />
            Чего ты вообще ждал
            <br />
            Чтобы не происходило такое
            <br />
            Нужно нормально
            <br />
            По-человечески себя вести
            <br />
            И все будет окей
            <br />
            Ты конечно очень классно
            <br />
            И удобно устроился
            <br />
            Но иди нахер
            <br />
            <br />
            Кис-кис, кис-кис
            <br />
            А знаешь что, милый
            <br />
            Удали мой номер
            <br />
            И забудь мое имя
            <br />
            <br />
            Кис-кис, кис-кис
            <br />
            Ты меня ненавидишь
            <br />
            Ты много теряешь
            <br />
            Неужели не видишь
            <br />
            <br />
            Назови меня дурой
            <br />
            Скажи, что я все испортила
            <br />
            А потом с засосами
            <br />
            Возвращайся на родину
            <br />
            <br />
            Там тебя ждет
            <br />
            Наивная дура
            <br />
            Я бы сказала кто ты
            <br />
            Но sorry цензура
            <br />
            <br />
            Скажи ей, что Жанна
            <br />
            Хочет нас рассорить
            <br />
            Что она все врет
            <br />
            Ведь я не буду спорить
            <br />
            <br />
            Удали все комменты
            <br />
            И все свои лайки
            <br />
            Соври ей, что я в блоке
            <br />
            Своей новенькой зайке
            <br />
            <br />
            Кис-кис, кис-кис
            <br />
            Кис-кис, кис-кис
            <br />
            <br />
            <br />
            <br />
            Кис-кис, кис-кис
            <br />
            Я котик, ты котик
            <br />
            А твои поцелуи
            <br />
            Почти как легкий наркотик
            <br />
            <br />
            Кис-кис, кис-кис
            <br />
            Кис-кис, кис-кис
            <br />
            <br />
            <br />
            <br />
            Кис-кис, кис-кис
            <br />
            А знаешь что, милый
            <br />
            Удали мой номер
            <br />
            И забудь мое имя
            <br />
            <br />
            Кис-кис, кис-кис
            <br />
            Ты меня ненавидишь
            <br />
            Ты много теряешь
            <br />
            Неужели не видишь?
            <br />
            <br />
            Кис-кис, кис-кис
            <br />
            Кис-кис, кис-кис
            <br />
            <br />
            <br />
            <br />
            Кис-кис, кис-кис
            <br />
            Кис-кис, кис-кис
            <br />
            <br />
            <br />
            <br />
            Кис-кис, кис-кис
            <br />
            Я котик, ты котик
            <br />
            А твои поцелуи
            <br />
            Почти как легкий наркотик
            <br />
            <br />
            ❤️❤️❤️
          </div>
          <div
            className="absolute w-full h-10 left-0 top-0"
            style={{
              background:
                "linear-gradient(to bottom, #ffffff, rgba(255,255,255,0))",
            }}
          ></div>
          <div
            className="absolute w-full h-10 left-0 bottom-0"
            style={{
              background:
                "linear-gradient(to top, #ffffff, rgba(255,255,255,0))",
            }}
          ></div>
        </div>
        <Lottie
          loop
          animationData={music}
          play
          className="w-full h-full absolute z-0"
        />
        <Lottie
          loop
          animationData={catGray}
          style={{ transform: "scale(-1, 1)" }}
          play
          className="w-40 h-32 absolute bottom-1 -left-4"
        />
        <Lottie
          loop
          animationData={catOrange}
          play
          className="w-40 h-32 absolute bottom-1 -right-4 transform"
        />
      </div>
    );
  }
  if (data.step === "stop") {
    return (
      <div className="relative w-full h-80 flex items-center flex-col justify-center">
        {/* <img src={love} alt="Ты котик :*" className="show-from-top" /> */}
        <h1 className="text-xl font-medium text-center mt-2 mb-1 show-from-top animation-delay-300">
          Ты котик
        </h1>
        <p className="text-sm text-center show-from-top animation-delay-600">
          Ты поёшь великолепно!
        </p>
      </div>
    );
  }
}
