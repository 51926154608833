import React, { useEffect, useState } from "react";
import firebase from "firebase";

import { ArrowDown } from "react-feather";
import Lottie from "react-lottie-player";
import oil from "../../../assets/oil.json";

import head from "../../../assets/oils.svg";

import "./Drill.scss";

const CLICK_COUNT = 100;

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default function Drill({ data, obs, userInfo }) {
  const [counters, setCounters] = useState([]);
  const [next, setNext] = useState(2);

  useEffect(() => {
    const db = firebase.firestore();
    db.collection("speed")
      .doc(`${userInfo.uid}-${data.id}`)
      .get()
      .then((doc) => {
        if (!doc.exists || data.step === "start") {
          db.collection("speed").doc(`${userInfo.uid}-${data.id}`).set({
            uid: userInfo.uid,
            question: data.id,
            photo: userInfo.photo,
            name: userInfo.name,
            count: 0,
            winAt: null,
          });
        }
      });
  }, [userInfo, data.id]);

  useEffect(() => {
    const db = firebase.firestore();
    // Listen speed
    const subscriber = db
      .collection("speed")
      .where("question", "==", data.id)
      .onSnapshot((querySnapshot) => {
        const newCounters = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          newCounters.push(data);
        });
        setCounters(newCounters);
      });

    // Unsubscrbe from subscribers
    return () => {
      subscriber();
    };
  }, [data.id]);

  const setAction = () => {
    const numbers = [0, 1, 2, 3].filter((c) => c !== next);
    setNext(numbers[getRandomInt(0, 2)]);

    const db = firebase.firestore();
    const mine = counters.find((c) => c.uid === userInfo.uid).count;
    if (mine >= CLICK_COUNT - 1) {
      db.collection("speed")
        .doc(`${userInfo.uid}-${data.id}`)
        .set(
          {
            count: CLICK_COUNT,
            winAt: firebase.firestore.Timestamp.fromDate(new Date()),
            question: data.id,
          },
          { merge: true }
        );
    } else {
      db.collection("speed")
        .doc(`${userInfo.uid}-${data.id}`)
        .set(
          {
            count: firebase.firestore.FieldValue.increment(1),
            question: data.id,
          },
          { merge: true }
        );
    }
  };

  if (data.step === "drill-results") {
    return (
      <div
        style={{
          minHeight: 300,
        }}
        className={`drill ${data.step} h-full flex flex-col items-center justify-start relative overflow-hidden px-4`}
      >
        <h2 className="text-xl text-indigo-600 font-bold text-center mt-6">
          Были быстрее всех
        </h2>
        <div className="w-full bg-gray-100 rounded-lg p-4 mt-4 flex flex-col">
          {counters
            .filter((c) => c.winAt)
            .sort((a, b) => a.winAt.seconds - b.winAt.seconds)
            .map((c, index) => {
              return (
                <div className="w-full flex pb-2 mb-2 border-b items-center">
                  <p className="w-6 text-xs text-indigo-600 font-bold text-center">
                    {index + 1}.
                  </p>
                  <div
                    style={{
                      backgroundImage: `url(${c.photo})`,
                    }}
                    className="ml-1 w-8 h-8 bg-cover rounded-full border border-white"
                  ></div>
                  <p className="text-sm ml-2 font-bold">{c.name}</p>
                </div>
              );
            })}
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        minHeight: 300,
      }}
      className={`drill ${data.step} h-full flex flex-col items-center justify-start relative overflow-hidden`}
    >
      <div className="absolute w-12 h-12 flex items-center bg-white rounded-full top-4 text-xl text-center justify-center font-bold text-indigo-600">
        {counters.filter((c) => c.winAt).length}
      </div>
      <img src={head} alt="" />
      <div
        style={{ minHeight: 250 }}
        className="earth w-full h-full flex justify-around pb-2 relative"
      >
        <Lottie
          loop
          animationData={oil}
          play
          className="w-full absolute -bottom-2 transform scale-y-50 origin-bottom"
        />
        {counters.map((c, index) => {
          return (
            <div key={c.uid} className="w-8 h-full relative">
              <div
                style={{ height: `${(c.count / CLICK_COUNT) * 100}%` }}
                className="w-2 bg-black left-1/2 absolute top-0 -ml-1"
              ></div>
              {c.uid === userInfo.uid ? (
                <div
                  style={{
                    backgroundImage: `url(${c.photo})`,
                    animationDuration: "0.3s",
                    top: `${(c.count / CLICK_COUNT) * 100}%`,
                  }}
                  className="absolute w-10 h-10 -m-1 bg-cover rounded-full border-4 border-indigo-600 z-10 relative animate-bounce"
                ></div>
              ) : (
                <div
                  style={{
                    backgroundImage: `url(${c.photo})`,
                    animationDelay: `${index * 0.12}s`,
                    animationDuration: "0.3s",
                    top: `${(c.count / CLICK_COUNT) * 100}%`,
                  }}
                  className="absolute w-8 h-8 bg-cover rounded-full border-2 border-white relative z-1 animate-bounce"
                ></div>
              )}
            </div>
          );
        })}
      </div>
      <div
        style={{ backgroundColor: "#000" }}
        className="flex w-full bg-black py-2 justify-around px-4 relative"
      >
        {counters.find((c) => c.uid === userInfo.uid) &&
        counters.find((c) => c.uid === userInfo.uid).count >= CLICK_COUNT ? (
          <h2 className="text-xl font-bold w-full text-center text-yellow-200 py-3">
            Добурились!
          </h2>
        ) : (
          <>
            {[0, 1, 2, 3].map((b) => {
              return (
                <button
                  key={b}
                  onClick={() => {
                    if (next === b) {
                      setAction();
                    }
                  }}
                  className={`${
                    next === b ? "" : "transform scale-75 opacity-30"
                  } transition duration-300 ease-in-out w-16 h-16 rounded-xl bg-white flex items-center justify-center text-secondary`}
                >
                  <ArrowDown size={24} />
                </button>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}
