import React, { useEffect, useState } from "react";
import firebase from "firebase";

import { ArrowLeft, ArrowRight } from "react-feather";

import head from "../../../assets/race/head.png";
import bottom from "../../../assets/race/bottom.png";
import you from "../../../assets/race/you.svg";
import snowman from "../../../assets/race/snowman.png";

import me from "../../../assets/race/me.png";
import enemy from "../../../assets/race/enemy.png";

import finish from "../../../assets/race/finish.png";

const CLICK_COUNT = 50;

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default function Race({ data, obs, userInfo }) {
  const [counters, setCounters] = useState([]);
  const [next, setNext] = useState(2);

  useEffect(() => {
    const db = firebase.firestore();
    db.collection("speed")
      .doc(`${userInfo.uid}-${data.id}`)
      .get()
      .then((doc) => {
        if (!doc.exists || data.step === "start") {
          db.collection("speed").doc(`${userInfo.uid}-${data.id}`).set({
            uid: userInfo.uid,
            question: data.id,
            photo: userInfo.photo,
            name: userInfo.name,
            count: 0,
            winAt: null,
          });
        }
      });
  }, [userInfo, data.id]);

  useEffect(() => {
    const db = firebase.firestore();
    // Listen speed
    const subscriber = db
      .collection("speed")
      .where("question", "==", data.id)
      .onSnapshot((querySnapshot) => {
        const newCounters = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          newCounters.push(data);
        });

        const filtered = newCounters.slice(0, 15);
        if (!filtered.find((c) => c.uid === userInfo.uid)) {
          const me = newCounters.find((c) => c.uid === userInfo.uid);
          if (me) {
            filtered.unshift(me);
          }
        }
        setCounters(filtered);
      });

    // Unsubscrbe from subscribers
    return () => {
      subscriber();
    };
  }, [data.id]);

  const setAction = () => {
    const numbers = [0, 1, 2, 3].filter((c) => c !== next);
    setNext(numbers[getRandomInt(0, 2)]);

    const db = firebase.firestore();
    const mine = counters.find((c) => c.uid === userInfo.uid).count;
    if (mine >= CLICK_COUNT - 1) {
      db.collection("speed")
        .doc(`${userInfo.uid}-${data.id}`)
        .set(
          {
            count: CLICK_COUNT,
            winAt: firebase.firestore.Timestamp.fromDate(new Date()),
            question: data.id,
          },
          { merge: true }
        );
    } else {
      db.collection("speed")
        .doc(`${userInfo.uid}-${data.id}`)
        .set(
          {
            count: firebase.firestore.FieldValue.increment(1),
            question: data.id,
          },
          { merge: true }
        );
    }
  };

  if (data.step === "race-results") {
    return (
      <div
        style={{
          minHeight: 300,
        }}
        className={`${data.step} h-full flex flex-col items-center justify-start relative overflow-hidden px-4`}
      >
        <h2 className="text-xl text-indigo-600 font-bold text-center mt-6">
          Были быстрее всех
        </h2>
        <div className="w-full bg-gray-100 rounded-lg p-4 mt-4 flex flex-col">
          {counters
            .filter((c) => c.winAt)
            .sort((a, b) => a.winAt.seconds - b.winAt.seconds)
            .map((c, index) => {
              return (
                <div className="w-full flex pb-2 mb-2 border-b items-center">
                  <p className="w-6 text-xs text-indigo-600 font-bold text-center">
                    {index + 1}.
                  </p>
                  <div
                    style={{
                      backgroundImage: `url(${c.photo})`,
                    }}
                    className="ml-1 w-8 h-8 bg-cover rounded-full border border-white"
                  ></div>
                  <p className="text-sm ml-2 font-bold">{c.name}</p>
                </div>
              );
            })}
        </div>
      </div>
    );
  }

  let myCounter = counters.find((c) => c.uid === userInfo.uid);
  myCounter = myCounter ? myCounter.count : 0;

  return (
    <div
      style={{
        minHeight: 300,
      }}
      className={`${data.step} h-full flex flex-col items-center justify-start relative overflow-hidden`}
    >
      <div className="absolute px-3 py-2 bg-white rounded-full text-indigo-600 text-sm z-10 font-bold top-4">
        {myCounter} / {CLICK_COUNT}
      </div>
      <div className="absolute px-3 py-2 flex items-center bg-white rounded-full top-4 right-4 text-sm text-center justify-center font-bold text-indigo-600 z-10">
        <img src={finish} alt="finish" className="mr-1 w-4" />
        {counters.filter((c) => c.winAt).length}
      </div>
      <img src={head} className="relative z-0" alt="header" />
      <div
        style={{ minHeight: 250, maxHeight: 300, backgroundColor: "#E8F4FE" }}
        className="w-full h-full flex flex-col justify-around pb-12 relative z-10"
      >
        <div
          style={{ backgroundColor: "#C04C47" }}
          className="absolute w-2 h-full right-12 bg-red-200"
        >
          <img
            style={{ maxWidth: "none" }}
            src={snowman}
            alt="snowman"
            className="absolute bottom-full w-10 left-1/2 -ml-8"
          />
        </div>
        {counters.map((c, index) => {
          return (
            <div
              key={c.uid}
              style={{ width: `calc(100% - 3rem)` }}
              className="h-px relative"
            >
              <div
                style={{
                  backgroundColor: "#CAE2F3",
                  width: `${(c.count / CLICK_COUNT) * 100}%`,
                  height: 2,
                }}
                className="left-0 absolute bottom-0 -mr-1"
              ></div>
              {c.uid === userInfo.uid ? (
                <>
                  <div
                    style={{
                      backgroundImage: `url(${c.photo})`,
                      left: `${(c.count / CLICK_COUNT) * 100}%`,
                    }}
                    className="absolute -mt-8 w-6 h-6 bg-cover rounded-full border-2 border-white z-10 relative transition-all duration-200"
                  >
                    <img
                      src={you}
                      alt="you"
                      style={{ maxWidth: "none" }}
                      className="absolute left-full top-0 ml-2 w-20"
                    />
                    <img
                      src={me}
                      alt="me"
                      style={{ maxWidth: "none" }}
                      className="absolute w-10 -ml-2 -bottom-3"
                    />
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      backgroundImage: `url(${c.photo})`,
                      animationDelay: `${index * 0.12}s`,
                      left: `${(c.count / CLICK_COUNT) * 100}%`,
                    }}
                    className="absolute -mt-8 w-6 h-6 bg-cover rounded-full border-2 border-white relative z-1"
                  >
                    <img
                      src={enemy}
                      alt="me"
                      style={{ maxWidth: "none" }}
                      className="absolute -bottom-3 w-10 -ml-2"
                    />
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
      <img src={bottom} className="absolute bottom-20 w-full z-10" alt="" />
      <div
        style={{ backgroundColor: "#24527A" }}
        className="flex w-full bg-black py-2 justify-around px-4 relative"
      >
        {counters.find((c) => c.uid === userInfo.uid) &&
        counters.find((c) => c.uid === userInfo.uid).count >= CLICK_COUNT ? (
          <h2 className="text-xl font-bold w-full text-center text-yellow-200 py-3">
            Вы финишировали!
          </h2>
        ) : (
          <>
            {[0, 1, 2, 3].map((b) => {
              return (
                <button
                  key={b}
                  onClick={() => {
                    if (next === b) {
                      setAction();
                    }
                  }}
                  className={`${
                    next === b ? "" : "transform scale-75 opacity-30"
                  } transition duration-300 ease-in-out w-16 h-16 rounded-xl bg-white flex items-center justify-center text-indigo-600`}
                >
                  {next === b ? (
                    <ArrowRight size={24} />
                  ) : (
                    <ArrowLeft size={24} />
                  )}
                </button>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}
