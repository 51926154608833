import { nanoid } from "nanoid";
import React, { useEffect, useRef, useState } from "react";
import { Heart } from "react-feather";
import "./Reaction.scss";

import firebase from "firebase";

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default function Reaction({ roomInfo }) {
  const [likes, setLikes] = useState(nanoid(6));
  const currentLikes = useRef([]);

  useEffect(() => {
    const db = firebase.firestore();
    const subscriber = db
      .collection("reactions")
      .doc(roomInfo)
      .onSnapshot((doc) => {
        const data = doc.data();
        currentLikes.current = data?.items || [];
        setLikes(nanoid(6));
      });

    return () => {
      if (subscriber) subscriber();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomInfo]);

  const setReaction = () => {
    // firebase.functions().useFunctionsEmulator("http://localhost:5001");

    const id = nanoid(6);

    firebase.functions().httpsCallable("setChatReaction")({
      id: `t-${getRandomInt(1, 30)}|${id}`,
      roomId: roomInfo,
    });
    firebase.analytics().logEvent("chat-reaction");
  };

  return (
    <div className="absolute z-10 w-10 h-10 flex right-3 top-2">
      <div className="animations absolute">
        {currentLikes.current.map((l) => {
          return <div key={l} className={`like ${l.split("|")[0]}`}></div>;
        })}
      </div>
      <button
        onClick={setReaction}
        className="like-press-btn text-white bg-red-500 border border-white w-full h-full absolute rounded-full flex items-center justify-center"
      >
        <Heart size={14} />
      </button>
    </div>
  );
}
