import React, { useEffect, useState, useRef } from "react";
import logo from "../../../assets/squid-run/logo.svg";
import rules from "../../../assets/squid-run/rules.svg";
import back from "../../../assets/squid-run/back.svg";
import win from "../../../assets/squid/win.svg";
import lose from "../../../assets/squid/lose.svg";

import "./SquidRun.scss";
import firebase from "firebase";
import { Frown, Heart } from "react-feather";

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
const CLICK_COUNT = 200;

export default function SquidRun({ data, obs, userInfo }) {
  const [counters, setCounters] = useState([]);
  const [step, setStep] = useState("");
  const [running, setRunning] = useState(false);
  const ref = useRef(null);
  const imgRef = useRef(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    const db = firebase.firestore();
    db.collection("speed")
      .doc(`${userInfo.uid}-${data.id}`)
      .get()
      .then((doc) => {
        if (!doc.exists || data.step === "start") {
          db.collection("speed").doc(`${userInfo.uid}-${data.id}`).set({
            uid: userInfo.uid,
            photo: userInfo.photo,
            name: userInfo.name,
            question: data.id,
            count: 0,
            live: true,
          });
        }
      });
  }, [userInfo, data.id]);

  useEffect(() => {
    const db = firebase.firestore();
    // Listen speed
    const subscriber = db
      .collection("speed")
      .where("question", "==", data.id)
      .onSnapshot((querySnapshot) => {
        let newCounters = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          newCounters.push(data);
        });
        setCounters(newCounters);
      });

    // Unsubscrbe from subscribers
    return () => {
      subscriber();
    };
  }, [data.id]);

  useEffect(() => {
    if (data.step !== "eyes-open") {
      setStep(data.step);
    } else {
      setStep("eyes-soon-open");
      setTimeout(() => {
        setStep("eyes-open");
      }, 1000);
    }
  }, [data.step]);

  const setAction = () => {
    const imLive = counters.find((c) => c.uid === userInfo.uid)
      ? counters.find((c) => c.uid === userInfo.uid).live
      : true;

    const myCount = counters.find((c) => c.uid === userInfo.uid)
      ? counters.find((c) => c.uid === userInfo.uid).count
      : 0;

    if (
      ["eyes-soon-open", "eyes-close"].includes(step) &&
      imLive &&
      myCount < CLICK_COUNT
    ) {
      const db = firebase.firestore();
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      setRunning(true);
      timeoutRef.current = setTimeout(() => {
        setRunning(false);
      }, 200);

      db.collection("speed")
        .doc(`${userInfo.uid}-${data.id}`)
        .set(
          {
            count: firebase.firestore.FieldValue.increment(1),
            question: data.id,
          },
          { merge: true }
        );
    }

    if (step === "eyes-open" && imLive && myCount < CLICK_COUNT) {
      const db = firebase.firestore();

      db.collection("speed").doc(`${userInfo.uid}-${data.id}`).set(
        {
          live: false,
          question: data.id,
        },
        { merge: true }
      );
    }
  };

  const myCount = counters.find((c) => c.uid === userInfo.uid)
    ? counters.find((c) => c.uid === userInfo.uid).count
    : 0;
  const imLive = counters.find((c) => c.uid === userInfo.uid)
    ? counters.find((c) => c.uid === userInfo.uid).live
    : true;

  const wrapperWidth = ref.current ? ref.current.offsetWidth : 0;
  const imgWidth = imgRef.current ? imgRef.current.offsetWidth : 0;
  const translateX = -(myCount / CLICK_COUNT) * (imgWidth - wrapperWidth);

  if (step === "squid-results") {
    const winners = counters.filter((c) => c.count >= CLICK_COUNT && c.live);
    return (
      <div
        style={{
          minHeight: 300,
          background: `#DB2377`,
        }}
        className={`h-full flex flex-col items-center justify-center py-6 relative overflow-hidden text-white`}
      >
        <div className="flex w-full flex-col">
          {winners.length ? (
            <>
              <p className="text-xl font-bold text-center">Счастливчики</p>
              {winners.map((w) => {
                return (
                  <div
                    key={w.uid}
                    className="w-full flex items-center mt-4 px-4"
                  >
                    <div
                      style={{ backgroundImage: `url(${w.photo})` }}
                      className="w-8 h-8 bg-cover rounded-full border-2 border-white flex-shrink-0 mr-2"
                    ></div>
                    <p className="text-sm font-bold">{w.name}</p>
                    <Heart size={16} className="ml-auto" />
                  </div>
                );
              })}
            </>
          ) : (
            <>
              <Frown size={40} className="mx-auto mb-4" />
              <p className="text-sm font-bold text-center">
                Никто не дошел до конца
              </p>
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        minHeight: 300,
        background: `#82D5E0`,
      }}
      className={`squid-run ${step} ${imLive ? "" : "dead"} ${
        myCount >= CLICK_COUNT ? "win" : ""
      } h-full flex flex-col items-center justify-center py-10 relative overflow-hidden`}
    >
      <img src={logo} alt="squid logo" className="logo absolute w-40 md:w-64" />

      <div
        ref={ref}
        className="field absolute left-0 w-full h-full overflow-hidden"
      >
        <img
          src={back}
          ref={imgRef}
          style={{
            maxWidth: "none",
            transform: `translate(${translateX}px, 0)`,
          }}
          className="h-full w-auto transition-all duration-200 ease-linear"
          alt=""
        />
        <div
          style={{
            height: "19%",
            width: imgWidth - wrapperWidth,
            left: wrapperWidth / 4,
            transform: `translate(${translateX}px, 0)`,
          }}
          className="running-track absolute bottom-20 flex flex-col justify-between transition-all duration-200 ease-linear"
        >
          {counters.map((c) => {
            const percentage = (c.count / CLICK_COUNT) * 100;
            let runningState = false;
            if (
              ["eyes-soon-open", "eyes-close"].includes(step) &&
              c.uid !== userInfo.uid &&
              c.live
            ) {
              runningState = true;
            }
            if (c.uid === userInfo.uid && c.live && running) {
              runningState = true;
            }
            return (
              <div
                key={c.uid}
                style={{ left: `${percentage}%` }}
                className="relative w-20 h-1 transition-all duration-200 ease-linear"
              >
                <div
                  className={`person ${c.live ? "" : "dead"} ${
                    runningState ? "running" : ""
                  } w-20 absolute bottom-0`}
                >
                  <div
                    style={{ backgroundImage: `url(${c.photo})` }}
                    className="photo"
                  ></div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="rules left-6 right-6 top-6 absolute">
        <img src={rules} alt="rules 2" className="absolute rule w-full" />
      </div>

      <div className="eyes absolute left-1/4 right-1/4 top-10 h-32 bg-contain"></div>
      <div className="stats w-full left-0 top-0 h-10 flex justify-between p-3 px-4 text-sm text-white absolute font-bold">
        <p className="flex items-center">
          <Heart className="mr-1" size={14} />
          {counters.filter((c) => c.live).length}
        </p>
        <p className="flex items-center">
          {myCount} / {CLICK_COUNT}
        </p>
      </div>

      {myCount < CLICK_COUNT && (
        <div className="actions absolute bottom-0 left-0 right-0 h-1/3">
          <div
            className={`pulse-btn w-16 h-16 rounded-full border-2 ${
              ["eyes-soon-open", "eyes-open"].includes(step)
                ? "border-red-600"
                : "border-white"
            } absolute left-1/2 -mx-8 bottom-4`}
          ></div>
          <button
            onClick={() => setAction()}
            className="w-12 h-12 rounded-full btn-squid absolute left-1/2 -mx-6 bottom-6"
          ></button>
        </div>
      )}
    </div>
  );
}
