import React, { useEffect, useState } from "react";
import firebase from "firebase";

import tree0 from "../../../assets/tree/0.png";
import tree1 from "../../../assets/tree/1.png";
import tree2 from "../../../assets/tree/2.png";
import tree3 from "../../../assets/tree/3.png";
import tree4 from "../../../assets/tree/4.png";
import { Power } from "react-feather";

import "./Tree.scss";

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default function Tree({ data, obs, userInfo }) {
  const [counters, setCounters] = useState(0);
  const [next, setNext] = useState(2);

  useEffect(() => {
    const db = firebase.firestore();
    db.collection("speed")
      .doc(`${userInfo.uid}-${data.id}`)
      .get()
      .then((doc) => {
        if (!doc.exists || data.step === "start") {
          db.collection("speed").doc(`${userInfo.uid}-${data.id}`).set({
            uid: userInfo.uid,
            question: data.id,
            count: 0,
          });
        }
      });
  }, [userInfo, data.id]);

  useEffect(() => {
    const db = firebase.firestore();
    // Listen speed
    const subscriber = db
      .collection("speed")
      .where("question", "==", data.id)
      .onSnapshot((querySnapshot) => {
        let newCounters = 0;
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          newCounters += data.count;
        });
        setCounters(newCounters);
      });

    // Unsubscrbe from subscribers
    return () => {
      subscriber();
    };
  }, [data.id]);

  const setAction = () => {
    const numbers = [0, 1, 2, 3].filter((c) => c !== next);
    setNext(numbers[getRandomInt(0, 2)]);
    const db = firebase.firestore();
    db.collection("speed")
      .doc(`${userInfo.uid}-${data.id}`)
      .set(
        {
          count: firebase.firestore.FieldValue.increment(1),
          question: data.id,
        },
        { merge: true }
      );
  };

  let title = "Нажимайте на большую кнопку!";
  switch (data.step) {
    case "speed-1":
      title = "Отлично получается!";
      break;
    case "speed-2":
      title = "Вот это скорость!";
      break;
    case "speed-3":
      title = "Осталось совсем немного!";
      break;
    case "speed-4":
      title = "Еще чуть-чуть!";
      break;
    case "speed-5":
      title = "Елочка, гори!";
      break;
    default:
      break;
  }

  return (
    <div
      style={{
        minHeight: 300,
        background: `radial-gradient(50% 50% at 50% 50%, #213049 0%, #0B1120 100%)`,
      }}
      className={`tree ${data.step} h-full flex flex-col items-center justify-start p-y4 relative overflow-hidden`}
    >
      <img src={tree0} className="w-full" alt="tree" />
      <img src={tree1} className="speed-1 absolute" alt="tree" />
      <img src={tree2} className="speed-2 absolute" alt="tree" />
      <img src={tree3} className="speed-3 absolute" alt="tree" />
      <img src={tree4} className="speed-4 absolute" alt="tree" />
      <h1
        style={{ textShadow: "0 0 20px #FFE600" }}
        className="text-2xl font-bold text-yellow-200 mt-4"
      >
        {counters}
      </h1>
      {data.step === "speed-5" ? (
        <h3
          style={{ textShadow: "0 0 20px #FFE600" }}
          className="text-xl font-bold text-yellow-200 mt-1"
        >
          {title}
        </h3>
      ) : (
        <h3 className="text-sm font-bold text-indigo-600 mt-1">{title}</h3>
      )}
      <div className="flex w-full justify-around mt-4 mb-6 px-4">
        {[0, 1, 2, 3].map((b) => {
          return (
            <button
              key={b}
              onClick={() => {
                if (next === b || data.step === "speed-5") {
                  setAction();
                }
              }}
              className={`${
                next === b || data.step === "speed-5"
                  ? ""
                  : "transform scale-75 opacity-30"
              } transition duration-300 ease-in-out w-16 h-16 rounded-xl bg-white flex items-center justify-center text-indigo-600`}
            >
              <Power size={24} />
            </button>
          );
        })}
      </div>
    </div>
  );
}
