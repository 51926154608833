import React, { useEffect } from "react";
import { EventClass } from "./externalPlayer/externalPlayer";

export default function VideoPlayer({ url }) {
  useEffect(() => {
    if (url === "prosto") {
      new EventClass({
        serverUrl: "https://platform.dataforum.pro/",
        eventSlug: "prosto_meditation",
        room_id: 301,
        language: "ru",
        block: "eventblock1",
      });
    }
  }, [url]);

  if (url === "prosto") {
    return <div class="eventblock eventblock1 prosto-player"></div>;
  }
  if (url === "blank") {
    return (
      <div
        style={{ background: `#333` }}
        className="absolute w-full h-full left-0 top-0 bg-cover border-2 rounded-lg border-white"
      ></div>
    );
  }
  return (
    <iframe
      title="video player"
      src={url}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  );
}
