import React, { useState } from "react";
import firebase from "firebase";
import notie from "notie";
import { Check } from "react-feather";

export default function Stream({ now, roomInfo, onChange }) {
  const [mainStream, setMainStream] = useState(roomInfo.mainStream || "");
  const [reserveStream, setReserveStream] = useState(
    roomInfo.reserveStream || ""
  );
  const [secondReserveStream, setSecondReserveStream] = useState(
    roomInfo.secondReserveStream || ""
  );

  const saveStreams = () => {
    const db = firebase.firestore();
    db.collection("rooms")
      .doc(roomInfo.id)
      .set(
        {
          mainStream,
          reserveStream,
          secondReserveStream,
        },
        { merge: true }
      )
      .then((docRef) => {
        notie.alert({
          type: "success",
          text: "Succesfully saved",
          position: "bottom",
        });
        onChange();
      })
      .catch((error) => {
        console.error("Error updating room: ", error);
      });
  };

  const setStream = (url) => {
    const db = firebase.database();
    db.ref(`/rooms/${roomInfo.id}/state/stream`)
      .set(url)
      .then((docRef) => {
        notie.alert({
          type: "success",
          text: "Event is set",
          position: "bottom",
        });
        onChange();
      })
      .catch((error) => {
        console.error("Error set stream: ", error);
      });
  };

  const getStreamSwitch = (url) => {
    return (
      <button
        onClick={() => setStream(url)}
        className={`${
          now && now.stream && now.stream === url && url !== ""
            ? "bg-green-500 border-green-500"
            : "border-gray-300"
        } w-6 h-6 p-1 border rounded mt-1 flex-shrink-0 flex items-center justify-center text-white`}
      >
        <Check />
      </button>
    );
  };

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 lg:col-span-8 mb-3 bg-white rounded-xl p-4">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-3">
            {getStreamSwitch(mainStream)}
            <div className="whitespace-nowrap text-md text-indigo-600 font-semibold w-40">
              main
            </div>
            <input
              type="text"
              className="w-full border rounded-md p-2 text-md"
              value={mainStream}
              onChange={(e) => setMainStream(e.target.value)}
            />
          </div>
          <div className="flex items-center gap-3">
            {getStreamSwitch(reserveStream)}
            <div className="whitespace-nowrap text-md text-indigo-600 font-semibold w-40">
              reserve #1
            </div>
            <input
              type="text"
              className="w-full border rounded-md p-2 text-md"
              value={reserveStream}
              onChange={(e) => setReserveStream(e.target.value)}
            />
          </div>
          <div className="flex items-center gap-3">
            {getStreamSwitch(secondReserveStream)}
            <div className="whitespace-nowrap text-md text-indigo-600 font-semibold w-40">
              reserve #1
            </div>
            <input
              type="text"
              className="w-full border rounded-md p-2 text-md"
              value={secondReserveStream}
              onChange={(e) => setSecondReserveStream(e.target.value)}
            />
          </div>
        </div>
        <div className="flex items-center gap-2 mt-5">
          <button
            className="px-3 py-2 bg-indigo-600 text-white flex rounded-lg text-sm items-center"
            onClick={saveStreams}
          >
            Save changes
          </button>
          <div className="text-xs">
            Change current stream saving immediately
          </div>
        </div>
      </div>
      <div className="col-span-12 lg:col-span-4 rounded-xl">
        <div
          id="player"
          className="ratio-16x9 bg-black rounded-xl overflow-hidden mb-3"
        >
          {now && now.stream && (
            <iframe
              title="video player"
              src={now.stream}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          )}
        </div>
        <div className="text-xs ml-2 text-gray-500">
          {now && now.stream ? now.stream : "Empty stream URL"}
        </div>
      </div>
    </div>
  );
}
